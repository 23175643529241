import { WebAppRoutes } from "app/routes";
import { mediaQuerys, secondsToHms } from "app/utils";
import Container from "components/Container/Container";
import { CourseTypeTile } from "components/CourseTypeTile";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useMediaQuery } from "hooks/useMediaQuery";
import { selectCourseCoins, selectMulticourseContent } from "pages/Course/redux/courseSelectors";
import {
  getCourseCoins,
  setActiveContent,
  showAsideNav,
  showBottomNav,
} from "pages/Course/redux/courseSlice";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import defaultImageEsSvg from "../../../../assets/img/study-default.svg";
import defaultMyAcademyImageSvg from "../../../../assets/img/myacademy_course-card-head.svg";
import { CourseCoinList } from "../../../../components/CourseCoinList";
import { selectShopSettings } from "../../../Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { CourseFormat } from "../../redux/interfaces/course.interface";
import { Button } from "components/Buttons";
import styles from "./MulticourseContent.module.scss";

export const MulticourseContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { content_id } = useParams();

  const multicourseContent = useAppSelector(selectMulticourseContent);
  const shopSettings = useAppSelector(selectShopSettings);
  const coins = useAppSelector(selectCourseCoins);
  const isLaptop = useMediaQuery(`(max-width:${mediaQuerys.smMin}px)`);

  const currentContent = multicourseContent?.find((item) => item.id.toString() === content_id);
  const defaultImageSvg =
    process.env.REACT_APP_THEME === "myAcademy" ? defaultMyAcademyImageSvg : defaultImageEsSvg;
  const contentPreviewImage = currentContent?.preview || defaultImageSvg;

  useEffect(() => {
    dispatch(setActiveContent(content_id));
    dispatch(showBottomNav());
    dispatch(showAsideNav());

    // Запрашиваем баллы связанного курса траектории
    if (content_id) dispatch(getCourseCoins(content_id));
  }, [dispatch, content_id]);

  return (
    <Container className={styles["main-container"]}>
      <>
        {currentContent && (
          <div className={styles["container"]}>
            {isLaptop && (
              <img src={contentPreviewImage} alt="content-preview" className={styles["image"]} />
            )}

            <div className={styles["content"]}>
              <div className={styles["title"]}>{currentContent.name}</div>
              <CourseTypeTile type={currentContent?.format} className={styles["type"]} />
              <div className={styles["description"]}>{currentContent?.description}</div>
              <div className={styles["dates"]}>
                {!!currentContent.start_ts && (
                  <div className={styles["date"]}>
                    <div className={styles["date-text"]}>Старт обучения:</div>
                    <div className={styles["date-time"]}>
                      {format(new Date(currentContent.start_ts * 1000), "dd.MM.yyy")}
                    </div>
                  </div>
                )}

                {currentContent.duration && (
                  <div className={styles["date"]}>
                    <div className={styles["date-text"]}>Длительность:</div>
                    <div className={styles["date-time"]}>
                      {secondsToHms(currentContent.duration)}
                    </div>
                  </div>
                )}

                {shopSettings?.enable && coins && !!coins.lessons?.amount && (
                  <div className={styles["date"]}>
                    <div className={styles["date-text"]}>Баллы за курс:</div>
                    <CourseCoinList
                      coins={coins}
                      isMulticourse={currentContent?.format === CourseFormat.multicourse}
                    />
                  </div>
                )}
              </div>

              <Button
                color="primary"
                className={styles["button"]}
                onClick={() => {
                  navigate(`${WebAppRoutes.COURSE}/${currentContent.id}`);
                }}
              >
                {currentContent.status !== "completed" ? "Начать" : "Перейти"}
              </Button>
            </div>
            {!isLaptop && (
              <img src={contentPreviewImage} alt="content-preview" className={styles["image"]} />
            )}
          </div>
        )}
      </>
    </Container>
  );
};
