import CourseMenuAsideTab from "./CourseMenuAsideTab";
import { coursesSelectors, sectionsSelectors } from "../../../pages/Course/redux/courseSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { WebAppRoutes } from "../../../app/routes";
import {
  EducationMenuCloseIcon,
  EducationMenuOpenIcon,
  CertificateSvgIcon,
  TableSvgIcon,
} from "../../../components/Svg/Icons";
import { useParams } from "react-router-dom";
import { Scrollbar } from "components/Scrollbar";
import {
  selectMulticourseContent,
  selectСourseIsLoaded,
} from "../../../pages/Course/redux/courseSelectors";
import { configResponsive, useResponsive } from "ahooks";
import { useAppSelector } from "../../../hooks/redux";
import { Skeleton } from "components/Skeleton";
import { TextIcon, ThumbsUpIcon } from "../../../components/Icons";
import { EntityId } from "@reduxjs/toolkit";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import { MulticourseMenuAsideTab } from "./MulticourseMenuAsideTab";
import { generateUid, mediaQuerys } from "app/utils";
import { Capacitor } from "@capacitor/core";
import { useMediaQuery } from "hooks";
import { useRef } from "react";
import { OverlayScrollbarsComponentRef } from "overlayscrollbars-react";

configResponsive({
  tablet: 768,
});

const CourseMenu = ({
  isOpen,
  toggleCallback,
  scrollType,
  shared = false,
  canPutFeedback = false,
}: {
  isOpen: boolean;
  toggleCallback: () => void;
  scrollType: string;
  shared?: boolean;
  canPutFeedback?: boolean;
}) => {
  const { tablet } = useResponsive();

  const { course_id } = useParams();

  const location = useLocation();

  const navigate = useNavigate();

  const osRef = useRef<OverlayScrollbarsComponentRef>(null);

  const isMobile =
    useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`) || Capacitor.isNativePlatform();

  const sections = useAppSelector(sectionsSelectors.selectAll);
  const isCourseLoaded = useAppSelector(selectСourseIsLoaded);
  const multicourseContent = useAppSelector(selectMulticourseContent);
  const currentCourse = useAppSelector((state) =>
    coursesSelectors.selectById(state, course_id as EntityId)
  );

  const COURSE_RESULT_LINK = `${WebAppRoutes.COURSE}/${course_id}/result`;
  const COURSE_REPORT_LINK = `${WebAppRoutes.COURSE}/${course_id}/report`;
  const COURSE_FEEDBACK_LINK = `${WebAppRoutes.COURSE}/${course_id}/feedback`;
  const COURSE_PREVIEW_LINK = `${WebAppRoutes.COURSE_PREVIEW}/${course_id}`;

  const isMulticourse = currentCourse?.format === CourseFormat.multicourse;

  const handleGradeLink = (itemLink: string) => {
    if (!tablet) {
      toggleCallback();
    }
    navigate(itemLink);
  };

  return (
    <>
      <div
        className={isOpen ? "education-menu education-menu--active" : "education-menu"}
        data-scroll-type={scrollType}
      >
        <Scrollbar
          ref={osRef}
          className="course-menu-scrollbar"
          upButtonClassName="course-menu-scrollbar-btn"
        >
          <div className="course-menu-tabs">
            {!shared && (
              <div
                className="course-menu-back"
                onClick={() => {
                  handleGradeLink(COURSE_PREVIEW_LINK);
                }}
              >
                <TextIcon className="svg" />
                <span className="course-tab-content-list__item-text">Перейти в описание курса</span>
              </div>
            )}
            {isCourseLoaded ? (
              <>
                {isMulticourse ? (
                  <>
                    {multicourseContent?.map((item) => (
                      <MulticourseMenuAsideTab
                        isOpen={location.pathname.indexOf("/content/" + item.id) !== -1}
                        content={item}
                        courseId={course_id}
                        toogleMenu={toggleCallback}
                        key={generateUid()}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {sections.map((item) => (
                      <CourseMenuAsideTab
                        section={item}
                        courseId={course_id}
                        toogleMenu={toggleCallback}
                        key={generateUid()}
                        osRef={osRef}
                      />
                    ))}
                  </>
                )}
                <div className="course-menu-grade">
                  <ul className="course-menu-grade-list">
                    {!isMulticourse && (
                      <li
                        className={
                          location.pathname.indexOf(COURSE_REPORT_LINK) !== -1
                            ? "course-menu-grade-list__item course-menu-grade-list__item--active"
                            : "course-menu-grade-list__item"
                        }
                        onClick={() => {
                          handleGradeLink(COURSE_REPORT_LINK);
                        }}
                      >
                        <TableSvgIcon />
                        <span
                          className="course-tab-content-list__item-text"
                          style={{ ...(isMobile ? { fontWeight: 700 } : {}) }}
                        >
                          Оценки
                        </span>
                      </li>
                    )}
                    {canPutFeedback && !isMulticourse && (
                      <li
                        className={
                          location.pathname.indexOf(COURSE_FEEDBACK_LINK) !== -1
                            ? "course-menu-grade-list__item course-menu-grade-list__item--active"
                            : "course-menu-grade-list__item"
                        }
                        onClick={() => {
                          handleGradeLink(COURSE_FEEDBACK_LINK);
                        }}
                      >
                        <div className="svg" style={{ maxHeight: 24, maxWidth: 24 }}>
                          <ThumbsUpIcon />
                        </div>
                        <span
                          className="course-tab-content-list__item-text"
                          style={{ ...(isMobile ? { fontWeight: 700 } : {}) }}
                        >
                          Оценить курс
                        </span>
                      </li>
                    )}
                    <li
                      className={
                        location.pathname.indexOf(COURSE_RESULT_LINK) !== -1
                          ? "course-menu-grade-list__item course-menu-grade-list__item--active"
                          : "course-menu-grade-list__item"
                      }
                      onClick={() => {
                        handleGradeLink(COURSE_RESULT_LINK);
                      }}
                    >
                      <CertificateSvgIcon />
                      <span
                        className="course-tab-content-list__item-text"
                        style={{ ...(isMobile ? { fontWeight: 700 } : {}) }}
                      >
                        Мой результат
                      </span>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <>
                <Skeleton variant="text" width="80%" height={32} />
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="80%" height={32} />
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="60%" height={24} />
              </>
            )}
          </div>
        </Scrollbar>
      </div>

      <div
        className={
          isOpen
            ? "education-menu-toggler education-menu-toggler--active"
            : "education-menu-toggler"
        }
        data-scroll-type={scrollType}
        onClick={toggleCallback}
      >
        {isOpen ? (
          <EducationMenuCloseIcon className="education-menu-toggler-close" />
        ) : (
          <EducationMenuOpenIcon className="education-menu-toggler-open" />
        )}
      </div>
    </>
  );
};

export default CourseMenu;
