import { mediaQuerys } from "app/utils";
import PaperBox from "components/Paper/PaperBox";
import { useMediaQuery } from "hooks/useMediaQuery";
import { setCourseFeedback } from "pages/Course/redux/courseSlice";
import { useState } from "react";
import { Button } from "../../../../components/Buttons";
import { CatWithThumbUp, СapybaraThumbUp } from "../../../../components/Illustrations";
import { CourseFeedbackModal } from "../../../../components/Modals/CourseFeedbackModal";
import { useModal } from "../../../../components/ModalsContainer";
import { StarRating } from "../../../../components/StarRating";
import { TextAreaField } from "../../../../components/TextAreaField";
import { useAppDispatch } from "../../../../hooks/redux";
import styles from "./LandingRating.module.scss";

export const LandingRating = ({ courseId }: { courseId?: string }) => {
  const [score, setScore] = useState<number>(0);
  const [text, setText] = useState<string>("");

  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModal();
  const openModalCourseFeedback = () => {
    openModal({
      content: <CourseFeedbackModal hasText={!!text} onConfirm={closeModal} />,
    });
  };

  const handleClick = () => {
    if (!courseId) return;
    dispatch(setCourseFeedback({ course_id: courseId, score, text }))
      .unwrap()
      .then(openModalCourseFeedback);
  };

  return (
    <PaperBox className={styles["landing-rating"]}>
      <div className={styles["block"]}>
        <div>
          <div className={styles["title"]}>Оцените курс</div>

          <p className={styles["description"]}>
            Вы успешно закончили курс. Оставьте свою оценку и впечатление. Они помогут нам
            усовершенствовать курс, а другим пользователям - понять, насколько он им подойдет.
          </p>
        </div>

        <StarRating score={score} onClick={setScore} />

        <TextAreaField
          className={styles["text-field"]}
          label="Ваш комментарий о курсе"
          value={text}
          onChange={setText}
          errorMessage={!!text && text.length > 300 ? "Максимальная длина 300 символов" : ""}
          invalid={!!text && text.length > 300}
        />

        <Button
          className={styles["button"]}
          color="primary"
          disabled={!score}
          onClick={handleClick}
        >
          Отправить
        </Button>
      </div>

      {!isMobile && process.env.REACT_APP_THEME !== "myAcademy" && <CatWithThumbUp />}
      {!isMobile && process.env.REACT_APP_THEME === "myAcademy" && <СapybaraThumbUp />}
    </PaperBox>
  );
};
