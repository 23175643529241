import { MyAcademyLogo2 } from "components/Logos/MyAcademyLogo/MyAcademyLogo2";
import { Link } from "react-router-dom";
import Container from "../../components/Container/Container";
import { EducationStudioLogo } from "../Logos";

const Footer = () => {
  const currentDate = new Date();
  const currentFullYear = currentDate.getFullYear();

  return (
    <footer className="footer">
      <Container>
        <div className="footer-block">
          <div className="footer-logo">
            <Link
              to={process.env.REACT_APP_THEME === "myAcademy" ? "https://mylanit.ru/" : "/"}
              target="_blanc"
              className="footer-logo__wrapper"
            >
              {process.env.REACT_APP_THEME === "myAcademy" ? (
                <MyAcademyLogo2 width={205} height={53} />
              ) : (
                <EducationStudioLogo width={130} height={47} />
              )}
            </Link>
            {process.env.REACT_APP_THEME !== "myAcademy" && (
              <span className="footer__copyright">© Education Studio, {currentFullYear}</span>
            )}
          </div>

          <div className="footer-contact">
            <div className="footer-contact__email">
              {process.env.REACT_APP_THEME === "myAcademy" ? (
                <a href="mailto:edu_support@lanit.ru">edu_support@lanit.ru</a>
              ) : (
                <a href="mailto:help@educationstudio.ru">help@educationstudio.ru</a>
              )}
              <span>Служба поддержки</span>
            </div>
          </div>

          {process.env.REACT_APP_THEME === "base" && (
            <ul className="footer-list">
              <li className="footer-list__item">
                <a href="https://educationstudio.ru" download target="_blank" rel="noreferrer">
                  Официальный сайт Education Studio
                </a>
              </li>
            </ul>
          )}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
