import { avatarsApi } from "../avatars.api";

const getAllAvatarsApi = avatarsApi.injectEndpoints({
  endpoints: (build) => ({
    getAllAvatars: build.query<{ images: { id: number; path: string; type: "ad" | null }[] }, void>(
      {
        query: () => ({
          url: "/profile/images",
          method: "GET",
        }),
        providesTags: (result) =>
          result ? result.images.map(({ id }) => ({ type: "updateAvatars", id })) : [],
      }
    ),
  }),
  overrideExisting: false,
});

export const { useGetAllAvatarsQuery } = getAllAvatarsApi;
