import classNames from "classnames";
import { WebAppRoutes } from "app/routes";
import { useNavigate } from "react-router";
import { format as formatDate } from "date-fns";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { CourseTypeTile } from "components/CourseTypeTile";
import { H3 } from "components/Typography/Titles";
import { CourseStatus } from "components/CourseStatus";
import { MulticourseContent } from "../../pages/Course/redux/interfaces/course.interface";
import { setCourseEnrolled } from "pages/Course/redux/courseSlice";
import { useEffect } from "react";
import { getCourseStatuses } from "pages/Courses/redux/coursesListSlice";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import { CourseCoinAmount } from "../CourseCoinAmount";
import { selectShopSettings } from "../../pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { Button } from "components/Buttons";
import styles from "./MulticourseContentCard.module.scss";

interface TrajectoryIncludesCardProps {
  course: MulticourseContent;
  className?: string;
}

export const MulticourseContentCard = ({
  course: { id, start_ts, status, format, name, description, enrolled, coins },
  className,
}: TrajectoryIncludesCardProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shopSettings = useAppSelector(selectShopSettings);

  const start = start_ts ? formatDate(new Date(start_ts * 1000), "dd.MM.yyy") : "";

  const multiCourseContentStatus = (status || enrolled) && (status || EnrolledStatus.enrolled);

  const corseIsCompletedEnrolled =
    multiCourseContentStatus &&
    (multiCourseContentStatus === EnrolledStatus.completed ||
      multiCourseContentStatus === EnrolledStatus.enrolled);

  // Записаться на курс
  const handleCourseEnroll = (id: number | string) =>
    //@ts-ignore
    dispatch(setCourseEnrolled(id)).then(() => {
      navigate(WebAppRoutes.COURSE + "/" + id, { replace: true });
    });

  // Перейти на курс
  const handleCourseEnter = (id: number | string) =>
    navigate(WebAppRoutes.COURSE_PREVIEW + "/" + id);

  // В зависимости от статуса курса записаться или перейти на курс
  const handleClick = (id: number | string) =>
    corseIsCompletedEnrolled ? handleCourseEnter(id) : handleCourseEnroll(id);

  useEffect(() => {
    dispatch(getCourseStatuses());
  }, [dispatch]);

  return (
    <div className={classNames(styles["container"], className)}>
      <div className={styles["left-content"]}>
        <div className={styles["left-content_inner"]}>
          {start && (
            <div className={styles["date-container"]}>
              <div className={styles["date-text"]}>Старт:</div>
              <div className={styles["date"]}>{start}</div>
            </div>
          )}
          {shopSettings?.enable && coins?.amount && (
            <div className={styles["coins-container"]}>
              <div className={styles["coins-text"]}>За завершение:</div>
              <CourseCoinAmount coin={coins} hasText={false} small />
            </div>
          )}
        </div>

        {multiCourseContentStatus && (
          <CourseStatus status={multiCourseContentStatus} className={styles["status"]} />
        )}
      </div>

      <div className={styles["middle-content"]}>
        <CourseTypeTile type={format} className={styles["format"]} />
        <H3 onClick={() => handleCourseEnter(id)} className={styles["name"]}>
          {name}
        </H3>
        <div className={styles["description"]}>{description}</div>
      </div>
      <div className={styles["right-content"]}>
        <Button
          color="primary"
          type="button"
          className={styles["button"]}
          onClick={() => handleClick(id)}
        >
          {corseIsCompletedEnrolled ? "Перейти" : "Записаться"}
        </Button>
      </div>
    </div>
  );
};
