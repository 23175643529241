import { Mentor } from "components/Mentor/Mentor";
import { MentorTaskComment } from "./components/MentorTaskComment";
import { CourseLessonTaskComment } from "pages/Course/redux/interfaces/course.interface";
import styles from "./MentorTaskComments.module.scss";

interface MentorTaskCommentsPops {
  comments: CourseLessonTaskComment[];
}

export const MentorTaskComments = ({ comments }: MentorTaskCommentsPops): JSX.Element => (
  <div className={styles["wrapper"]}>
    <div className={styles["header"]}>Комментарии наставника</div>
    <div className={styles["list"]}>
      {comments.map((comment) => (
        <MentorTaskComment comment={comment} />
      ))}
    </div>
    <Mentor date={comments[comments.length - 1]?.date} />
  </div>
);
