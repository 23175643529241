import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  allAnswersSelector,
  selectCourseNavigation,
  selectCoursePosition,
  selectCurrentOpenPageInQuiz,
} from "../../redux/Quze/courseQuizeSelectors";
import {
  clearQuestAnswer,
  questionsSelectors,
  setCurrentOpenPageInQuiz,
} from "../../redux/Quze/courseQuizeQuestionsSlice";
import { generateUid } from "app/utils";
import { useEffect } from "react";
import classNames from "classnames";

export const QuestionsProgress = () => {
  const dispatch = useAppDispatch();
  const position = useAppSelector(selectCoursePosition);
  const questions = useAppSelector(questionsSelectors.selectAll);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);
  const allAnswers = useAppSelector(allAnswersSelector);

  const navigation = useAppSelector(selectCourseNavigation);

  const handleClick = (index: number) => () => {
    if (navigation === "free") {
      dispatch(setCurrentOpenPageInQuiz(index + 1));
    }
  };

  const countPages = position === 0 ? 0 : Math.ceil(questions.length / position);

  const pageNumbers = new Array(countPages < 1 ? 1 : countPages).fill(undefined);

  useEffect(
    () => () => {
      dispatch(clearQuestAnswer());
    },
    [dispatch]
  );

  return (
    <ul className="course-questions-list">
      {pageNumbers.map((_, index) => {
        const startSlice = index * position;
        const endSlice = startSlice + position;
        const questionsCurrentStep = questions.slice(startSlice, endSlice);

        return (
          <li
            key={generateUid()}
            className={classNames("course-questions-list__item", {
              "course-questions-list__item--completed": questionsCurrentStep.some((question) => {
                //@ts-ignore
                const answer = allAnswers.find((answer) => answer.id === question.id);

                if (!answer) {
                  return false;
                }

                //@ts-ignore
                const value = answer.answer;

                //@ts-ignore
                const answerId = answer?.id;

                if (typeof value === "string") {
                  return value.length > 0;
                } else if (Array.isArray(value)) {
                  return value.length > 0;
                } else if (value && typeof value === "object" && !Array.isArray(value)) {
                  const findedQuestion = questions.find((question) => question.id === answerId);

                  const countOptions =
                    findedQuestion?.options?.filter((option) => option?.column === 1)?.length || 0;

                  return Object.keys(value).length === countOptions;
                }
                return false;
              }),
              "course-questions-list__item--current": currentOpenPageInQuiz === index + 1,
              "course-questions-list__item--disabled": navigation === "consistent",
            })}
            onClick={handleClick(index)}
          >
            <span>{index + 1}</span>{" "}
          </li>
        );
      })}
    </ul>
  );
};
