import { CloseIcon, ExpandIcon } from "components/Icons";
import { useEffect, useRef, useState } from "react";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import styles from "./CourseTrainingIframe.module.scss";
import { CircleIconButton } from "components/Buttons";
import { useDebounceEffect, useEventListener } from "ahooks";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  clearTrainerCmiValue,
  setScormTrackedData,
  setTrainerCmiValue,
} from "pages/Course/redux/course-trainer.slice";
import { selectTrainerCmiValue } from "pages/Course/redux/courseSelectors";
import { isLoadingTrackedDataSelector } from "pages/Course/redux/selectors";
import { hideBottomNav, showBottomNav } from "../../../../redux/courseSlice";

export const CourseTrainingIframe = ({
  trainerlink,
  trainerId,
}: {
  trainerlink?: string;
  trainerId: number;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const dispatch = useAppDispatch();
  const isLoadingTrackedData = useAppSelector(isLoadingTrackedDataSelector);
  const currentTrainerCmiValue = useAppSelector(selectTrainerCmiValue);
  const [isShowSkeleton, setIsShowSkeleton] = useState<boolean>(true);
  const [showMobileFullScreen, setShowMobileFullScreen] = useState<boolean>(false);
  const [scormUrl, setScormUrl] = useState<string>("");

  const handleFullScreenIframe = async () => {
    if (Capacitor.isNativePlatform()) {
      setShowMobileFullScreen(true);
      const header = document.querySelector(".course-test-header");
      header?.classList.add(styles["hide-element"]);
      const educationMenu = document.querySelector(".education-menu");
      educationMenu?.classList.add(styles["hide-element"]);
      const educationMenuToggler = document.querySelector(".education-menu-toggler");
      educationMenuToggler?.classList.add(styles["hide-element"]);
      dispatch(hideBottomNav());

      await ScreenOrientation.removeAllListeners();
      await ScreenOrientation.lock({ orientation: "landscape-primary" });

      await ScreenOrientation.addListener("screenOrientationChange", async (orientation) => {
        if (orientation.type === "landscape-primary") {
          await ScreenOrientation.lock({ orientation: "landscape-primary" });
        }

        if (orientation.type === "landscape-secondary") {
          await ScreenOrientation.lock({ orientation: "landscape" });
        }
      });
      return;
    }
    if (iframeRef && iframeRef.current) {
      const iframe = iframeRef.current;
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
        return;
      }

      //Fix for Safari
      //@ts-ignore
      if (iframe.webkitRequestFullscreen()) {
        //@ts-ignore
        iframe.webkitRequestFullscreen();
        return;
      }
    }
  };

  const closeMobileFullScreen = async () => {
    await ScreenOrientation.removeAllListeners();
    const header = document.querySelector(".course-test-header");
    header?.classList.remove(styles["hide-element"]);
    const educationMenu = document.querySelector(".education-menu");
    educationMenu?.classList.remove(styles["hide-element"]);
    const educationMenuToggler = document.querySelector(".education-menu-toggler");
    educationMenuToggler?.classList.remove(styles["hide-element"]);
    dispatch(showBottomNav());
    await ScreenOrientation.lock({ orientation: "portrait" });
    setShowMobileFullScreen(false);
  };

  useEventListener(
    "message",
    (
      event: MessageEvent<{
        eventName: string;
        isScorm: boolean;
        scormData: { [index: string]: string };
      }>
    ) => {
      const { data } = event;
      if (data.isScorm) {
        const { eventName, scormData } = data;

        if (eventName === "LMSSetValue" && scormData) {
          Object.keys(scormData).forEach((key: string) => {
            //@ts-ignore
            dispatch(setTrainerCmiValue({ element: key, value: `${scormData[key]}` }));
          });
        }
      }
    }
  );

  useEffect(() => {
    if (trainerlink) {
      const originTrainer = new URL(trainerlink).origin;
      setScormUrl(`${originTrainer}/proxy-scorm.html?scorm_url=${trainerlink}`);
    }
  }, [trainerlink]);

  useEffect(
    () => () => {
      dispatch(clearTrainerCmiValue());
    },
    [dispatch]
  );

  useDebounceEffect(
    () => {
      if (currentTrainerCmiValue.length > 0) {
        dispatch(setScormTrackedData({ trainerId, data: currentTrainerCmiValue }));
      }
    },
    [currentTrainerCmiValue],
    { wait: 1500 }
  );

  const changeShowSkeleton = () => setIsShowSkeleton(false);

  const handleOnLoadScorm = () => {
    if (iframeRef.current) {
      const iframeWindow = iframeRef.current.contentWindow;
      if (iframeWindow) {
        const scormCmi = currentTrainerCmiValue.reduce(
          (acc, { element, value }) => ({
            ...acc,
            [element]: `${value}`,
          }),
          {}
        );

        const data = {
          eventName: "setScormData",
          scormCmi,
        };

        iframeWindow.postMessage(data, "*");
        changeShowSkeleton();
      }
    }
  };

  return (
    <>
      <div
        className={classNames("course-training-player", {
          "course-loading": isShowSkeleton,
          [styles["iframe-mobile"]]: showMobileFullScreen && Capacitor.isNativePlatform(),
        })}
      >
        {showMobileFullScreen && Capacitor.isNativePlatform() && (
          <div className={styles["mobile-header"]}>
            <CircleIconButton
              onClick={closeMobileFullScreen}
              size="small"
              className={styles["close-button"]}
            >
              <CloseIcon />
            </CircleIconButton>
          </div>
        )}

        {!isLoadingTrackedData && (
          <iframe
            allow="fullscreen"
            title="scrom-frame"
            src={scormUrl}
            id="iframe"
            className={styles["scorm-iframe"]}
            allowFullScreen
            frameBorder="0"
            onLoad={handleOnLoadScorm}
            ref={iframeRef}
          ></iframe>
        )}
        {!showMobileFullScreen && (
          <button
            className="course-training__button"
            style={{ cursor: "pointer" }}
            onClick={handleFullScreenIframe}
          >
            <ExpandIcon />
          </button>
        )}
      </div>
    </>
  );
};
