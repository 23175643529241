import classNames from "classnames";
import { CertificateSvgBg, CertificateSvgImage } from "components/svg-images";
import { CertificateLineSvg } from "./CertificateLineSvg";
import { TestResultRightLeafPlugUnfilled } from "components/Svg/Pics";
import { mediaQuerys } from "app/utils";
import { useMediaQuery } from "hooks";
import styles from "./CertificateBlock.module.scss";

export const CertificateBlock = (): JSX.Element => {
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  return (
    <div
      className={classNames(styles["container"], {
        [styles["container_myAcademy"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
    >
      <div className={styles["inner"]}>
        <div className={styles["info"]}>
          <span className={styles["title"]}>Доступен сертификат</span>
          <span className={styles["description"]}>
            Закончив курс, вы получите Сертификат
            {!isMobile && <br />} о завершении электронного курса, который
            {!isMobile && <br />} сможете распечатать или отправить коллегам.
          </span>
        </div>
        <div className={styles["certificate"]}>
          {isMobile && (
            <TestResultRightLeafPlugUnfilled
              className={classNames(styles["leaf"], styles["left"])}
            />
          )}
          <CertificateSvgImage style={{ width: 256 }} className={styles["certificate-card"]} />
          <CertificateSvgBg className={styles["certificate-bg"]} />
          {isMobile && (
            <TestResultRightLeafPlugUnfilled
              className={classNames(styles["leaf"], styles["right"])}
            />
          )}
        </div>
      </div>
      {!isMobile && (
        <>
          <TestResultRightLeafPlugUnfilled className={classNames(styles["leaf"], styles["left"])} />
          <TestResultRightLeafPlugUnfilled
            className={classNames(styles["leaf"], styles["right"])}
          />
        </>
      )}
      <CertificateLineSvg className={styles["line"]} />
    </div>
  );
};
