import { avatarsApi } from "../avatars.api";

const uploadAvatarApi = avatarsApi.injectEndpoints({
  endpoints: (build) => ({
    uploadAvatar: build.mutation<{ id: number; path: string }, { file: FormData }>({
      //@ts-ignore
      query: ({ file }) => {
        return {
          url: "/profile/images",
          method: "POST",
          body: file,
          headers: {
            "Content-Type": undefined,
            Accept: "*/*",
          },
        };
      },
      invalidatesTags: ["updateAvatars"],
    }),
  }),
  overrideExisting: false,
});

export const { useUploadAvatarMutation } = uploadAvatarApi;
