import { useState, useEffect } from "react";
import { secondsToHmsFormat } from "../../../../../app/utils";
import { useCountDown } from "ahooks";

interface QuizeCounterProps {
  totalTime: number;
  timeSpent: number;
  lesson: any;
  onCounterEnd: () => void;
}

const QuizeCounter = ({ totalTime, timeSpent, lesson, onCounterEnd }: QuizeCounterProps) => {
  const [targetDate, setTargetDate] = useState(0);
  const [countdown, formattedRes] = useCountDown({
    targetDate,
    onEnd: () => {
      onCounterEnd();
    },
  });
  const { minutes, seconds } = formattedRes;

  useEffect(() => {
    setTargetDate(Date.now() + (timeSpent ? totalTime - timeSpent : totalTime - 1) * 1000);
  }, [timeSpent, totalTime]);

  return (
    <span className="course-test-top-time">
      {countdown > 0 ? (
        <>
          <span
            className={
              minutes < 1 ? "course-test-top__time--deadline" : "course-test-top__time--start"
            }
          >
            {seconds + 1 === 60 ? minutes + 1 : minutes} :{" "}
            {seconds + 1 < 10 ? "0" + (seconds + 1) : minutes + 1 === 60 ? "00" : seconds + 1}
          </span>
          <small className="course-test-top-slash">/</small>
        </>
      ) : null}
      <span className="course-test-top__time--default">{secondsToHmsFormat(totalTime)}</span>
    </span>
  );
};

export default QuizeCounter;
