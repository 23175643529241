import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { СompetenciesTypeData } from "components/CompetenciesCard/competancies.types";
import { EndPoints } from "../../../app/endpoints";
import { WebAppRoutes } from "../../../app/routes";
import { RootState } from "../../../app/store";
import api from "../../../services/api/api";
import {
  IBadgesRequest,
  IBadgesResponse,
  ICertificateAvalabilityDownloadResponse,
  ICertificateAvalabilityResponse,
} from "../../Achievements/redux/interfaces";
import { CourseLesson, CoursePagination, CourseSecton, CourseState, ICourse } from "./interfaces";
import {
  CourseCoinsData,
  CourseContentResponse,
  CourseFeedbackRequest,
  CourseFeedbacksResponse,
  CourseFormat,
  CourseSummaryResponse,
  MulticourseContentResponse,
  ViewedInfoRequest,
  ViewedInfoResponse,
} from "./interfaces/course.interface";
import { postMeetingViewed } from "../components/Meeting";

const coursesAdapter = createEntityAdapter<ICourse>({
  selectId: (course) => course.id,
});

const sectionsAdapter = createEntityAdapter<CourseSecton>({
  selectId: (section) => section.id,
});

const lessonsAdapter = createEntityAdapter<CourseLesson>({
  selectId: (lesson) => lesson.id,
});

const paginationsAdapter = createEntityAdapter<CoursePagination>({
  selectId: (page) => page.id,
});

const initialState: CourseState = {
  isLoading: false,
  options: {
    isBottomNav: false,
    isAsideNav: false,
    courseMenuIsOpen: true,
  },
  courseResult: {
    isFinish: false,
    start_ts: 0,
    end_ts: 0,
    grade: 0,
    days: 0,
  },
  isLoadingCourseLesson: false,
  pageBeforeCourse: WebAppRoutes.HOME,
  activeLessonId: 0,
  activeContentId: 0,
  scores: null,
  achievements: [],
  competence: [],
  content: [],
  feedbacks: { paging: { offset: 0, total: 0, limit: 10 }, data: [] },
  feedbacksIsLoading: false,
  summary: {},
  multicourseContent: { courses: [], courses_count: null },
  keywordsCoursesList: [],
  certificateAvailability: false,
  certificateDownloadAvailability: false,
};

const getCourseInfo = createAsyncThunk(
  "course/getCourseInfo",
  async (course_id: string | undefined, thunkAPI) => {
    try {
      const [course, courseContent, feedbacks, competence, summary] = await Promise.all([
        api
          .get(EndPoints.COURSE + "/" + course_id, {
            signal: thunkAPI.signal,
          })
          .then((response) => response.data?.data),
        api
          .get(EndPoints.COURSE_CONTENT(course_id), {
            signal: thunkAPI.signal,
          })
          .then((response) => response.data?.data)
          .catch(() => undefined),
        api
          .get(EndPoints.СOURSE_FEEDBACKS(course_id), {
            signal: thunkAPI.signal,
          })
          .then((response) => response.data)
          .catch(() => undefined),
        api
          .get(EndPoints.СOURSE_COMPETENCE(course_id), {
            signal: thunkAPI.signal,
          })
          .then((response) => response.data)
          .catch(() => undefined),
        api
          .get(EndPoints.COURSE_SUMMARY(course_id), {
            signal: thunkAPI.signal,
          })
          .then((response) => response.data?.data)
          .catch(() => undefined),
      ]);

      let multicourseContent = undefined;
      if (course?.format === CourseFormat.multicourse) {
        multicourseContent = await api
          .get(EndPoints.MULTICOURSE_PAGE_CONTENT(course_id), {
            signal: thunkAPI.signal,
          })
          .then((response) => response.data?.data)
          .catch(() => undefined);
      }

      return { course, courseContent, multicourseContent, feedbacks, competence, summary };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getCourseLesson = createAsyncThunk(
  "course/getCourseLesson",
  async (lesson_id: number | string | undefined, thunkAPI) => {
    const response = await api.get(EndPoints.COURSE_LESSONS(lesson_id), {
      signal: thunkAPI.signal,
    });
    return response.data;
  }
);

const setCourseLessonStart = createAsyncThunk(
  "course/setCourseLessonStart",
  async (lesson_id: number, thunkAPI) => {
    const response = await api.post(EndPoints.COURSE_LESSONS_START(lesson_id), {
      signal: thunkAPI.signal,
    });
    return response.data;
  }
);

const setCourseLessonFinish = createAsyncThunk(
  "course/setCourseLessonFinish",
  async (lesson_id: number | string | undefined, thunkAPI) => {
    try {
      const response = await api.post(EndPoints.COURSE_LESSONS_FINISH(lesson_id), {
        signal: thunkAPI.signal,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const setCourseTaskEnd = createAsyncThunk(
  "course/setCourseTaskEnd",
  async (lesson_id: number | string | undefined, thunkAPI) => {
    try {
      const response = await api.post(EndPoints.COURSE_TASKS_FINISH(lesson_id), {
        signal: thunkAPI.signal,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const setCourseEnrolled = createAsyncThunk("course/setCourseEnrolled", async (arg, thunkAPI) => {
  try {
    const response = await api.post(EndPoints.COURSE_ENROLL(arg));
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      code: error.response.data.code,
      details: error.response.data.details,
    });
  }
});

const getCourseResults = createAsyncThunk(
  "course/getCourseResults",
  async (arg: string | undefined, { rejectWithValue }) => {
    try {
      const response = await api.get(EndPoints.COURSE_RESULT(arg));
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const setCourseFinished = createAsyncThunk("course/setCourseFinished", async (arg, thunkAPI) => {
  const response = await api.post(EndPoints.COURSE_FINISH(arg));
  return response.data;
});

const getCoursePage = createAsyncThunk("course/getCoursePage", async (pageId, thunkAPI) => {
  const response = await api.get(EndPoints.COURSE_PAGE(pageId), {
    signal: thunkAPI.signal,
  });
  return response.data;
});

const getCoursePageContent = createAsyncThunk(
  "course/getCoursePageContent",
  async (pageId: number, thunkAPI) => {
    const response = await api.get(EndPoints.COURSE_PAGE_CONTENT(pageId), {
      signal: thunkAPI.signal,
    });
    return response.data;
  }
);

const getCoursePageViewedInfo = createAsyncThunk<ViewedInfoResponse, number>(
  "course/getCoursePageViewedInfo",
  async (pageId: number) => {
    const response = await api.get<ViewedInfoResponse>(EndPoints.COURSE_PAGE_VIEW_INFO(pageId));
    return response.data;
  }
);

const postCoursePageViewedInfo = createAsyncThunk(
  "course/postCoursePageViewedInfo",
  async (info: ViewedInfoRequest & { pageId: number }, thunkAPI) => {
    const { pageId, ...request } = info;
    const response = await api.post(EndPoints.COURSE_PAGE_VIEW_INFO(pageId), request);
    return response.data;
  }
);

const postCoursePageViewed = createAsyncThunk(
  "course/postCoursePageViewed",
  async (pageId: number, thunkAPI) => {
    const response = await api.post(EndPoints.COURSE_PAGE_VIEWED(pageId));
    return response.data;
  }
);

const getCourseReportsScores = createAsyncThunk(
  "course/getCourseReportsScores",
  async (courseId) => {
    const response = await api.get(EndPoints.REPORTS_SCORES, {
      params: {
        course_id: courseId,
      },
    });
    return response?.data?.data;
  }
);

const getLessonCompetence = createAsyncThunk(
  "course/getLessonCompetence",
  async ({ id }: { id: number | string }) => {
    const response = await api.get(EndPoints.LESSON_COMPETENCE(id));
    return response?.data;
  }
);

const getCourseContent = createAsyncThunk("course/getCourseContent", async (courseId: string) => {
  const response = await api.get(EndPoints.COURSE_CONTENT(courseId));
  return response?.data;
});

const getMulticourseContent = createAsyncThunk(
  "course/getMulticourseContent",
  async ({ id }: { id: number | string }) => {
    const response = await api.get(EndPoints.MULTICOURSE_PAGE_CONTENT(id));
    return response?.data;
  }
);

const getCourseCompetence = createAsyncThunk(
  "course/getCourseCompetence",
  async ({ id }: { id: number | string }) => {
    const response = await api.get(EndPoints.СOURSE_COMPETENCE(id));
    return response?.data;
  }
);

const getBadges = createAsyncThunk("course/getBadges", async (params: IBadgesRequest) => {
  const response = await api.get(EndPoints.BADGES, { params });
  return response.data;
});

const setCourseFeedback = createAsyncThunk(
  "course/setCourseFeedback",
  async (data: CourseFeedbackRequest, thunkAPI) => {
    try {
      await api.post(EndPoints.COURSE_FEEDBACK(data.course_id), data);
      return data.course_id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getResultCompetence = createAsyncThunk(
  "course/getResultCompetence",
  async ({ id }: { id: string }) => {
    const response = await api.get(EndPoints.RESULT_COMPETENCE(id));
    return response?.data;
  }
);

const getCourseFeedbacks = createAsyncThunk(
  "course/getCourseFeedbacks",
  async ({
    id,
    offset = 0,
    limit = 10,
    add = false,
  }: {
    id: string | number;
    offset?: number;
    limit?: number;
    add?: boolean;
  }) => {
    const response = await api.get(EndPoints.СOURSE_FEEDBACKS(id), {
      params: {
        offset,
        limit,
      },
    });

    return { data: response?.data, add };
  }
);

const getCourseSummary = createAsyncThunk("course/getCourseSummary", async (courseId: string) => {
  const response = await api.get(EndPoints.COURSE_SUMMARY(courseId));
  return response.data;
});

const getCourseCoins = createAsyncThunk("course/getCourseCoins", async (courseId: string) => {
  const response = await api.get(EndPoints.COURSE_COINS(courseId));
  return response.data;
});

const getPollUrl = createAsyncThunk("course/getPollUrl", async (courseId: string, thunkAPI) => {
  try {
    const response = await api.get(EndPoints.COURSE_POLL(courseId));
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      code: error.response.data.code,
      details: error.response.data.details,
    });
  }
});

const getKeywordsCoursesList = createAsyncThunk(
  "rewards-shop/getKeywordsCoursesList",
  async (_args, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.KEYWORDS_COURSES_LIST);

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error?.response?.status,
        data: error?.response?.data,
      });
    }
  }
);

const updateVisite = createAsyncThunk(
  "course/updateVisite",
  async (id: number | string, thunkAPI) => {
    try {
      const response = await api.patch(EndPoints.LESSON_VISITED(id));

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ status: error?.response?.status });
    }
  }
);

const getCertificateAvailability = createAsyncThunk(
  "course/getCertificateAvailability",
  async (courseId: number | string, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.COURSE_CERTIFICATE_AVALABILITY(courseId));

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error?.response?.status,
        data: error?.response?.data,
      });
    }
  }
);

const getCertificateDownloadAvailability = createAsyncThunk(
  "course/getCertificateDownloadAvailability",
  async (courseId: number | string, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.COURSE_CERTIFICATE_DOWNLOAD_AVALABILITY(courseId));

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error?.response?.status,
        data: error?.response?.data,
      });
    }
  }
);

const courseSlice = createSlice({
  name: "course",
  initialState: {
    ...initialState,
    pagination: paginationsAdapter.getInitialState(),
    courses: coursesAdapter.getInitialState({ loading: false }),
    sections: sectionsAdapter.getInitialState({ loaded: false }),
    lessons: lessonsAdapter.getInitialState({ loaded: false }),
  },
  reducers: {
    setActiveLesson(state, { payload }) {
      // @ts-ignore
      state.activeLessonId = payload ? parseInt(payload) : state.lessons.ids[0];
    },
    setActiveContent(state, action: PayloadAction<string | undefined>) {
      state.activeContentId = action.payload
        ? parseInt(action.payload)
        : state.multicourseContent?.courses?.[0]?.id;
    },
    // Определяем страницу, с которой пользователь перешел в курс
    setPageBeforeCourse(state, action: PayloadAction<string>) {
      state.pageBeforeCourse = action.payload;
    },
    setLessonQuizCompleted(state, action: PayloadAction<string | undefined>) {
      if (!action.payload) return;

      lessonsAdapter.updateOne(state.lessons, {
        id: action.payload,
        changes: {
          status: "completed",
        },
      });
    },

    showBottomNav(state) {
      state.options.isBottomNav = true;
    },
    hideBottomNav(state) {
      state.options.isBottomNav = false;
    },
    showAsideNav(state) {
      state.options.isAsideNav = true;
    },
    hideAsideNav(state) {
      state.options.isAsideNav = false;
    },
    setIsOpenCourseMenu(state, action: { payload: boolean }) {
      state.options.courseMenuIsOpen = action.payload;
    },
    clearCourseData(state) {
      coursesAdapter.removeAll(state.courses);
      lessonsAdapter.removeAll(state.lessons);
      sectionsAdapter.removeAll(state.sections);
      // TODO: WTF
      state.isLoading = false;
      state.options = {
        isBottomNav: false,
        isAsideNav: false,
        courseMenuIsOpen: true,
      };
      state.courseResult = {
        isFinish: false,
        grade: 0,
        start_ts: 0,
        end_ts: 0,
        days: 0,
      };
      state.activeLessonId = 0;
      state.scores = null;
      state.achievements = [];
      state.coins = undefined;
    },
    clearScores(state) {
      state.scores = null;
    },
    clearAchievements(state) {
      state.achievements = [];
    },
    setLessonStatus(state, action: PayloadAction<number>) {
      const lesson = state.lessons.entities[action.payload];
      if (!lesson) return;
      lesson.status = "completed";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseInfo.pending, (state, action) => {
        state.isLoading = true;
        state.courses.loading = false;
      })
      .addCase(getCourseInfo.fulfilled, (state, action) => {
        if (action.payload?.course) {
          coursesAdapter.addOne(
            state.courses,
            Object.assign({ sectionsId: [], lessonsId: [] }, action.payload.course)
          );
        }

        if (action.payload.courseContent) {
          state.sections.loaded = true;

          const mappedSectionData = action.payload.courseContent
            .filter((item: any) => item.lessons.length)
            .map((section: any) => ({
              ...section,
              lessons: section.lessons.map((lesson: any) => lesson.id),
            }));
          const mappedLessonsData = action.payload.courseContent
            .filter((item: any) => item.lessons.length)
            .map((section: any) => section.lessons)
            .flat();
          const mappedPagiantionData = action.payload.courseContent
            .filter((item: any) => item.lessons.length)
            .map((section: any) =>
              section.lessons.map((lesson: any) => ({
                id: lesson.id,
                lessonId: lesson.id,
                sectionId: section.id,
                sectionTitle: section.title,
                unitTitle: lesson.title,
                link: "/lesson/" + lesson.id,
              }))
            )
            .flat();
          state.multicourseContent = action.payload.multicourseContent;
          state.content = action.payload.courseContent;

          sectionsAdapter.setAll(state.sections, mappedSectionData);
          lessonsAdapter.setAll(state.lessons, mappedLessonsData);
          paginationsAdapter.setAll(state.pagination, mappedPagiantionData);
          // @ts-ignore

          coursesAdapter.updateOne(state.courses, {
            // @ts-ignore
            id: parseInt(action.payload?.courseId),
            changes: {
              // @ts-ignore
              lessonsId: mappedLessonsData.map((lesson) => lesson.id),
              // @ts-ignore
              sectionsId: mappedSectionData.map((section) => section.id),
            },
          });
        }

        action.payload.courseContent && (state.content = action.payload.courseContent);
        action.payload.competence && (state.competence = action.payload.competence);
        action.payload.feedbacks && (state.feedbacks = action.payload.feedbacks);
        action.payload.multicourseContent &&
          (state.multicourseContent = action.payload.multicourseContent);
        action.payload.summary && (state.summary = action.payload.summary);

        state.isLoading = false;
        state.courses.loading = true;
        state.sections.loaded = false;
      })
      .addCase(getCourseInfo.rejected, (state, action) => {
        state.isLoading = true;
        state.lessons.loaded = false;
        state.sections.loaded = false;
        state.courses.entities = {};
        state.courses.ids = [];
      })
      .addCase(postMeetingViewed.fulfilled, (state, action) => {
        lessonsAdapter.updateOne(state.lessons, {
          id: action.payload,
          changes: {
            status: "completed",
          },
        });
      })
      .addCase(setCourseLessonFinish.fulfilled, (state, action) => {
        const { id, status } = action.payload?.data ?? {};
        lessonsAdapter.updateOne(state.lessons, {
          id: parseInt(id),
          changes: {
            status,
          },
        });
      })
      .addCase(getCourseResults.pending, (state, action) => {
        state.courseResult = {
          isFinish: false,
          grade: 0,
          start_ts: 0,
          end_ts: 0,
          days: 0,
        };
      })
      .addCase(getCourseResults.fulfilled, (state, action) => {
        state.courseResult.isFinish = true;
        state.courseResult.grade = action.payload?.data.grade;
        state.courseResult.start_ts = action.payload?.data.start_ts;
        state.courseResult.end_ts = action.payload?.data.end_ts;
        state.courseResult.days = action.payload?.data.days;
      })
      .addCase(getCourseResults.rejected, (state, action) => {
        state.courseResult = {
          isFinish: false,
          grade: 0,
          start_ts: 0,
          end_ts: 0,
          days: 0,
        };
      })
      .addCase(getCourseLesson.pending, (state) => {
        state.isLoadingCourseLesson = true;
      })
      .addCase(getCourseLesson.fulfilled, (state, action) => {
        state.isLoadingCourseLesson = false;
        // state.activeLesson = action.payload.data;
        const id = parseInt(action.payload.data?.id);
        const lesson = state.lessons.entities[id];
        lessonsAdapter.updateOne(state.lessons, {
          id: parseInt(action.payload.data?.id),
          changes:
            lesson?.type !== "page"
              ? action.payload.data
              : { ...action.payload.data, status: lesson?.status || action.payload.data?.status },
        });
        state.isLoadingCourseLesson = false;
      })
      .addCase(getCourseLesson.rejected, (state) => {
        state.isLoadingCourseLesson = false;
      })
      .addCase(getCourseReportsScores.fulfilled, (state, action) => {
        state.scores = action.payload[0] ?? null;
      })
      .addCase(getBadges.pending, (state) => {
        state.achievements = [];
      })
      .addCase(
        getCertificateAvailability.fulfilled,
        (state, action: PayloadAction<ICertificateAvalabilityResponse>) => {
          state.certificateAvailability = action.payload.data.data.enable;
        }
      )
      .addCase(
        getCertificateDownloadAvailability.fulfilled,
        (state, action: PayloadAction<ICertificateAvalabilityDownloadResponse>) => {
          state.certificateDownloadAvailability = action.payload.data.data.status;
        }
      )
      .addCase(getBadges.fulfilled, (state, action: PayloadAction<IBadgesResponse>) => {
        state.achievements = action.payload.data || [];
      })
      .addCase(
        getCourseCoins.fulfilled,
        (state, action: PayloadAction<{ data: CourseCoinsData }>) => {
          state.coins = action.payload?.data;
        }
      )
      .addCase(getCourseCoins.rejected, (state) => {
        state.coins = undefined;
      })
      .addCase(
        getLessonCompetence.fulfilled,
        (state, action: PayloadAction<СompetenciesTypeData>) => {
          state.competence = action.payload || [];
        }
      )
      .addCase(
        getCourseContent.fulfilled,
        (state, action: PayloadAction<CourseContentResponse>) => {
          state.content = action.payload.data || [];
        }
      )
      .addCase(
        getCourseCompetence.fulfilled,
        (state, action: PayloadAction<СompetenciesTypeData>) => {
          state.competence = action.payload || [];
        }
      )
      .addCase(
        getResultCompetence.fulfilled,
        (state, action: PayloadAction<СompetenciesTypeData>) => {
          state.competence = action.payload || [];
        }
      )
      .addCase(
        getCourseFeedbacks.fulfilled,
        (state, action: PayloadAction<{ data: CourseFeedbacksResponse; add?: boolean }>) => {
          if (action.payload.add) {
            state.feedbacks.data = [...state.feedbacks.data, ...action.payload.data.data];
            state.feedbacks.paging = action.payload.data.paging;
          } else {
            state.feedbacks = action.payload.data || {};
          }
          state.feedbacksIsLoading = false;
        }
      )
      .addCase(getCourseFeedbacks.pending, (state, action) => {
        state.feedbacksIsLoading = true;
      })
      .addCase(
        getPollUrl.fulfilled,
        (state, action: PayloadAction<{ data: { link?: string } }>) => {
          state.pollUrl = action.payload?.data?.link || "";
        }
      )
      .addCase(getCourseFeedbacks.rejected, (state, action) => {
        state.feedbacksIsLoading = false;
      })
      .addCase(getCourseSummary.pending, (state) => {
        state.summary = undefined;
      })
      .addCase(
        getCourseSummary.fulfilled,
        (state, action: PayloadAction<CourseSummaryResponse>) => {
          state.summary = action.payload?.data;
        }
      )
      .addCase(
        getMulticourseContent.fulfilled,
        (state, action: PayloadAction<MulticourseContentResponse>) => {
          state.multicourseContent = action.payload?.data;
        }
      )
      .addCase(getMulticourseContent.rejected, (state) => {})
      .addCase(getCourseSummary.rejected, (state) => {})
      .addCase(setCourseFeedback.fulfilled, (state, action: PayloadAction<string>) => {
        coursesAdapter.updateOne(state.courses, {
          id: parseInt(action.payload),
          changes: { commenting: false },
        });
      })
      .addCase(getKeywordsCoursesList.fulfilled, (state, action) => {
        state.keywordsCoursesList = action.payload.data?.data;
      })
      .addCase(postCoursePageViewed.fulfilled, (state, action) => {
        const lesson = state.lessons.entities[action.meta.arg];
        if (!lesson) return;
        lesson.status = "completed";
      });
  },
});

export const coursesSelectors = coursesAdapter.getSelectors(
  (state: RootState) => state.course.courses
);
export const sectionsSelectors = sectionsAdapter.getSelectors(
  (state: RootState) => state.course.sections
);
export const lessonsSelectors = lessonsAdapter.getSelectors(
  (state: RootState) => state.course.lessons
);

export const selectMulticourseContent = (state: RootState) => state.course.multicourseContent;

export const {
  setActiveLesson,
  setActiveContent,
  setLessonStatus,
  setPageBeforeCourse,
  setLessonQuizCompleted,
  clearCourseData,
  showBottomNav,
  showAsideNav,
  hideAsideNav,
  setIsOpenCourseMenu,
  hideBottomNav,
  clearScores,
  clearAchievements,
} = courseSlice.actions;

export {
  getBadges,
  getCourseCoins,
  getCourseCompetence,
  getCourseContent,
  getCourseFeedbacks,
  getCourseInfo,
  getCourseLesson,
  getCoursePage,
  getCoursePageContent,
  getCoursePageViewedInfo,
  getCourseReportsScores,
  getCourseResults,
  getCourseSummary,
  getKeywordsCoursesList,
  getLessonCompetence,
  getMulticourseContent,
  getPollUrl,
  getResultCompetence,
  postCoursePageViewed,
  postCoursePageViewedInfo,
  setCourseEnrolled,
  setCourseFeedback,
  setCourseFinished,
  setCourseLessonFinish,
  setCourseLessonStart,
  updateVisite,
  setCourseTaskEnd,
  getCertificateAvailability,
  getCertificateDownloadAvailability,
};

export default courseSlice.reducer;
