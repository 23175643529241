import { SVGProps } from "react";
import styles from "./LandingAchievements.module.scss";

export const SquiggleIcon1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className={styles["squiggle-1"]}
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    {...props}
  >
    <path
      d="M2.6005 17.6155C49.7233 -12.516 35.1845 10.3362 23.7259 25.2567C9.17151 44.2083 18.7821 43.9463 33.6486 32.9307C48.5152 21.9151 48.8149 33.1845 38.343 46.9229"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);
