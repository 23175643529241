import Alert from "components/Alert/Alert";
import { IQuizQuestion } from "../../../redux/Quze/interface";
import { QuestCalculate, QuestCorrelate, QuestRadio, QuestText } from "../components";

interface getQuizControlProps {
  quest: IQuizQuestion;
  questIndex: number;
  lessonId: string;
  handleChange?: (option: {
    quizeId: string | number;
    questionId: string | number;
    attemptId: number;
    answer: any;
  }) => void;
}

export const getQuizControl = ({
  quest,
  questIndex,
  lessonId,
  handleChange,
}: getQuizControlProps) => {
  const questTitle = quest.title ? quest.title : "Вопрос " + questIndex;
  const questText = Array.isArray(quest.text) ? quest.text[0] : quest.text;
  const questTextFormated = questText.replace(/\n/g, "<br>");

  switch (quest.question_type) {
    case "text":
      return (
        <QuestText
          key={"quest_" + quest.id}
          title={questTitle}
          description={questTextFormated}
          questionsId={quest.id}
          lesson_id={lessonId}
          handleChange={handleChange}
        />
      );
    case "bool":
      return (
        <QuestRadio
          key={"quest_" + quest.id}
          multy={false}
          title={questTitle}
          questionsId={quest.id}
          questions={quest.options}
          description={questTextFormated}
          lesson_id={lessonId}
          handleChange={handleChange}
          withHint={false}
        />
      );
    case "select":
      return (
        <QuestRadio
          key={"quest_" + quest.id}
          multy={quest?.config?.multiselect ? quest?.config?.multiselect : false}
          questionsId={quest.id}
          title={questTitle}
          questions={quest.options}
          description={questTextFormated}
          lesson_id={lessonId}
          handleChange={handleChange}
        />
      );
    case "correlate":
      return (
        <QuestCorrelate
          key={"quest_" + quest.id}
          multy={false}
          questionsId={quest.id}
          title={questTitle}
          questions={quest.options}
          description={questTextFormated}
          lesson_id={lessonId}
          handleChange={handleChange}
        />
      );
    case "numeric":
      return (
        <QuestCalculate
          key={"quest_" + quest.id}
          title={questTitle}
          questionsId={quest.id}
          description={questTextFormated}
          handleChange={handleChange}
        />
      );
    default:
      return <Alert>Тип вопроса не поддерживается</Alert>;
  }
};
