import { useResponsive } from "ahooks";
import _ from "lodash";
import { RefObject, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { WebAppRoutes } from "../../../app/routes";
import {
  CourseMenuCompleteIcon,
  CourseMenuUncompleteIcon,
  TabArrowIcon,
} from "../../../components/Svg/Icons";
import { lessonsSelectors } from "../../../pages/Course/redux/courseSlice";
import { CourseSecton } from "../../../pages/Course/redux/interfaces";
import { OverlayScrollbarsComponentRef } from "overlayscrollbars-react";

interface CourseMenuAsideTabProps {
  section: CourseSecton;
  courseId?: string;
  toogleMenu: () => void;
  osRef?: RefObject<OverlayScrollbarsComponentRef>;
}

const CourseMenuAsideTab = ({ section, courseId, toogleMenu, osRef }: CourseMenuAsideTabProps) => {
  const { tablet } = useResponsive();
  const [isActive, setIsActive] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const lessonsEntities = useSelector(lessonsSelectors.selectEntities);

  const currentLessonRef = useRef<HTMLLIElement>(null);

  const toggleActiveFirst = () => {
    setIsActive(!isActive);
  };
  const handlerItemClick = (id?: number) => {
    navigate(WebAppRoutes.COURSE + "/" + courseId + "/lesson/" + id);
    if (!tablet) {
      toogleMenu();
    }
  };
  const courseTabItemStatus = (status?: string | null) => {
    switch (status) {
      case "in_progress":
        return <CourseMenuUncompleteIcon />;
      case "completed":
        return <CourseMenuCompleteIcon />;
      default:
        return null;
    }
  };

  const isActiveSection = (lessonId: number) =>
    location.pathname.indexOf("/lesson/" + lessonsEntities?.[lessonId]?.id) !== -1;

  const scrollbarElements = osRef?.current?.osInstance()?.elements();

  useEffect(() => {
    scrollbarElements &&
      currentLessonRef?.current?.offsetTop &&
      scrollbarElements.viewport.scrollTo({
        top: currentLessonRef?.current?.offsetTop,
        behavior: "smooth",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLessonRef]);

  return (
    <div className="course-tab">
      {!_.isEmpty(section.title) && (
        <div
          className={isActive ? "course-tab-header course-tab-header--active" : "course-tab-header"}
          onClick={toggleActiveFirst}
        >
          <TabArrowIcon />
          <span className="course-tab-header__title">{section.title}</span>
        </div>
      )}
      <div
        className={
          isActive ? "course-tab-content course-tab-content--active" : "course-tab-content"
        }
      >
        <ul className="course-tab-content-list" data-e2e={"course-nav"}>
          {section.lessons.map((lessonId, index) => {
            return (
              <li
                ref={isActiveSection(lessonId) ? currentLessonRef : null}
                key={index}
                onClick={() => {
                  handlerItemClick(lessonId);
                }}
                className={
                  isActiveSection(lessonId)
                    ? "course-tab-content-list__item course-tab-content-list__item--active"
                    : "course-tab-content-list__item"
                }
                data-e2e={"course-nav-item-id-" + lessonsEntities?.[lessonId]?.id}
              >
                <span className="course-tab-content-list__item-text">
                  {lessonsEntities?.[lessonId]?.title}
                </span>
                {courseTabItemStatus(lessonsEntities?.[lessonId]?.status)}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CourseMenuAsideTab;
