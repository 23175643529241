import { TextMain } from "../../../../../../../components/Typography/Texts";
import { H3 } from "../../../../../../../components/Typography/Titles";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect, useState } from "react";
import { setQuestAnswer } from "../../../redux/Quze/courseQuizeQuestionsSlice";
import {
  answersByPageSelector,
  selectCourseAnswersOfAttempt,
  selectCourseQuizeDetail,
  selectCurrentOpenPageInQuiz,
} from "../../../redux/Quze/courseQuizeSelectors";
import { IQuizeQuestionProp } from "../../../redux/Quze/interface/quiz.interface";
import { QuestDescription } from "./QuestDescription/QuestDescription";
import { useMemoizedFn } from "ahooks";

export const QuestRadio = ({
  title,
  multy,
  questions = [],
  questionsId,
  description,
  withHint = true,
  handleChange,
}: IQuizeQuestionProp) => {
  const dispatch = useAppDispatch();
  const answersOfAttempt = useAppSelector(selectCourseAnswersOfAttempt);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);
  const answers: any = useAppSelector(answersByPageSelector);
  const quizeDetail = useAppSelector(selectCourseQuizeDetail);

  const defaultAnswers = answers[`${currentOpenPageInQuiz}`]?.find(
    (answer: any) => answer.id === questionsId
  );

  const [currentMultyValue, setCurrentMultyValue] = useState<string[]>([]);

  const getMultiAnswer = (itemCode: string): string | string[] => {
    const currentAnswers = defaultAnswers?.answer ?? [];
    const newAnswer = currentAnswers.includes(itemCode)
      ? currentAnswers.filter((answer: string) => answer !== itemCode)
      : [...currentAnswers, itemCode];

    return newAnswer;
  };

  const handleOnChange = (itemCode: string) => () => {
    const answer = multy ? getMultiAnswer(itemCode) : itemCode;

    dispatch(
      setQuestAnswer({
        page: currentOpenPageInQuiz,
        answer: { id: questionsId, answer },
      })
    );

    const sortedAnswer =
      typeof answer === "string"
        ? answer
        : [...answer]?.sort((a, b) => {
            const getLastDigit = (str: string) => {
              const match = str.match(/\d(?!.*\d)/);
              return match ? parseInt(match[0]) : 0;
            };

            return getLastDigit(a) - getLastDigit(b);
          });

    const option = {
      quizeId: quizeDetail.id as string | number,
      questionId: questionsId as string | number,
      attemptId: quizeDetail?.attempt_id ?? 1,
      answer: sortedAnswer,
    };

    handleChange?.(option);
  };

  useEffect(() => {
    if (defaultAnswers?.answer) {
      setCurrentMultyValue(defaultAnswers.answer);
    }
  }, [defaultAnswers?.answer]);

  const setMultyValue = useMemoizedFn((answer) => {
    const answers = questions.reduce((acc: string[], value) => {
      const hasAnswer = answer?.answer?.includes(value.code);
      if (hasAnswer) {
        return [...acc, value.code];
      }
      return acc;
    }, []);

    dispatch(
      setQuestAnswer({
        page: currentOpenPageInQuiz,
        answer: { id: questionsId, answer: answers },
      })
    );
  });

  const setSingleValue = useMemoizedFn((answer) => {
    const findAnswer = questions.find((value) =>
      answer?.answer?.includes(value.code?.replace(/[a-z]./gm, "").trim())
    );

    findAnswer &&
      dispatch(
        setQuestAnswer({
          page: currentOpenPageInQuiz,
          answer: { id: questionsId, answer: findAnswer?.code },
        })
      );
  });

  useEffect(() => {
    const answer: any = answersOfAttempt.find((answer: any) => answer.question_id === questionsId);

    if (multy) {
      setMultyValue(answer);
      return;
    }

    if (!multy) {
      setSingleValue(answer);
    }
  }, [answersOfAttempt, multy, questionsId, setMultyValue, setSingleValue]);

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <QuestDescription data={description} />
      {withHint && (
        <>
          {multy ? (
            <TextMain className="course-questions-block-wrapper__title">
              Выберите правильные ответы:
            </TextMain>
          ) : (
            <TextMain className="course-questions-block-wrapper__title">
              Выберите один из ответов:
            </TextMain>
          )}
        </>
      )}

      <div className="course-questions-block-wrapper">
        <ul className="course-questions-block-list">
          {questions.map((item) => {
            const checked = multy
              ? currentMultyValue.includes(item.code)
              : +defaultAnswers?.answer === +item.code;

            return (
              <li key={item.code} className={"course-questions-block-list__item"}>
                <label className={"course-questions-bubble"}>
                  <input
                    className={"course-questions-bubble__check"}
                    value={item.code}
                    checked={checked}
                    type={multy ? "checkbox" : "radio"}
                    onChange={handleOnChange(item.code)}
                  />
                  <span className={"course-questions-bubble__item"}>{item.text}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
