import { useDebounceEffect } from "ahooks";
import { EventEmitter } from "ahooks/lib/useEventEmitter";
import { Switch } from "components/Switch/Switch";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getShopProducts,
  setSearchAccessibility,
  setSearchInput,
  setSearchMaxPrice,
  setSearchMinPrice,
  setSearchType,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import {
  selectShopProducts,
  selectShopSearch,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useRef } from "react";
import styles from "./RewardsSearch.module.scss";
import { GeneralSearch } from "./components/GeneralSearch";
import { PriceSearch } from "./components/PriceSearch";
import { TypeSearch } from "./components/TypeSearch";

interface RewardsSearchProps {
  clearFilters$?: EventEmitter<void>;
}

export const RewardsSearch = ({ clearFilters$ }: RewardsSearchProps) => {
  const dispatch = useAppDispatch();
  const search = useAppSelector(selectShopSearch);
  const prevSearchInput = useRef<string>("");
  const products = useAppSelector(selectShopProducts);

  useDebounceEffect(
    () => {
      if (search.min_price && search.max_price && search.min_price > search.max_price) {
        return;
      }

      const input = search.input?.trim() || "";
      dispatch(
        getShopProducts({
          search_payload: input.length >= 3 ? input : undefined,
          min_price: search.min_price ?? undefined,
          max_price: search.max_price ?? undefined,
          type: search.type,
        })
      );
    },
    [search.input, search.max_price, search.min_price, search.type, products.limit],
    { wait: 500 }
  );

  return (
    <div className={styles["container"]}>
      <div className={styles["row"]}>
        <GeneralSearch
          searchInputValue={search.input || ""}
          onChange={(value) => {
            if (
              !value ||
              !prevSearchInput.current ||
              value.length >= 3 ||
              prevSearchInput.current.length >= 3
            ) {
              dispatch(setSearchInput(value));
            }

            prevSearchInput.current = value || "";
          }}
        />
        <div className={styles["price-container"]}>
          <div className={styles["price-wrapper"]}>
            <PriceSearch
              title="Цена от"
              selectedPrice={search.min_price?.toString()}
              onChange={(value) =>
                dispatch(setSearchMinPrice(value === "" ? undefined : Number(value)))
              }
            />
            <PriceSearch
              title="Цена до"
              selectedPrice={search.max_price?.toString()}
              onChange={(value) =>
                dispatch(setSearchMaxPrice(value === "" ? undefined : Number(value)))
              }
            />
          </div>

          {search.min_price !== undefined &&
            search.max_price !== undefined &&
            search.min_price > search.max_price && (
              <p className={styles["price-error"]}>Некорректный диапазон</p>
            )}
        </div>

        {process.env.REACT_APP_THEME !== "myAcademy" && (
          <TypeSearch
            selectedType={search.type || ""}
            onChange={(value) => dispatch(setSearchType(value))}
          />
        )}

        <Switch
          label="Только доступные"
          isChecked={search.accessibility}
          onChange={(value) => dispatch(setSearchAccessibility(value))}
        />
      </div>
    </div>
  );
};
