import { CourseAuthorProps } from "pages/Course/redux/interfaces/course.interface";
import { CapybaraAvatarSvg } from "./CapybaraAvatarSvg";
import { CatAvatarSvg } from "./CatAvatarSvg";
import { ImagesAvatar } from "components/Avatar/Avatar";
import styles from "./CourseAuthor.module.scss";

export const CourseAuthor = ({ author }: { author: CourseAuthorProps }) => (
  <div className={styles["container"]}>
    <div className={styles["autor-photo"]}>
      <div className={styles["avatar-container"]}>
        {author.photo ? (
          <ImagesAvatar src={author.photo} />
        ) : process.env.REACT_APP_THEME === "myAcademy" ? (
          <CapybaraAvatarSvg />
        ) : (
          <CatAvatarSvg />
        )}
      </div>
      <div
        className={styles["author-tile"]}
        style={{
          backgroundColor: process.env.REACT_APP_THEME === "myAcademy" ? "#CEEFFF" : undefined,
          color: process.env.REACT_APP_THEME === "myAcademy" ? "#0094D9" : undefined,
        }}
      >
        Преподаватель
      </div>
    </div>
    <div className={styles["info"]}>
      <div className={styles["name"]}>{author.name}</div>
      <div className={styles["position"]}>
        {author.title} {author.title && author.company ? "в" : ""} {author.company}
      </div>
      <div className={styles["text"]}>{author.intro}</div>
    </div>
  </div>
);
