import { SVGProps } from "react";

export const LandingCompetenciesListLineSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={105}
    height={56}
    viewBox="0 0 105 56"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M2.235 13.44C15.5 2.998 35-2.429 49.5 6.5 64 15.427 52.465 41.194 44 39.993 31.998 38.291 69 8 87 20c12.208 8.139.736 25.927-6.765 30.735-15.281 9.796-11.5-8.808 22.501 2.692"
    />
  </svg>
);
