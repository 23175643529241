import { useEventEmitter } from "ahooks";
import { getKeywordsCoursesList } from "pages/Course/redux/courseSlice";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import styles from "./Courses.module.scss";
import { CoursesList, CoursesSearch } from "./components";
import {
  coursesListSelectors,
  getCourseStatuses,
  getCoursesCategories,
  getCoursesCompetences,
  getCoursesFormats,
  getSearchCourses,
} from "./redux/coursesListSlice";
import {
  selectCorsesListisLoaded,
  selectCoursesSearch,
  selectPaging,
} from "./redux/coursesListSliceSelectors";
import { SearchParams } from "./redux/interface";

const Courses = () => {
  const dispatch = useAppDispatch();
  const coursesList = useAppSelector(coursesListSelectors.selectAll);
  const coursesListIsLoaded = useAppSelector(selectCorsesListisLoaded);
  const { offset, limit, total } = useAppSelector(selectPaging);
  const clearFilters$ = useEventEmitter();

  const {
    categories: searchCategories,
    competences: searchCompetences,
    duration: searchDurations,
    format: searchFormats,
    enrolled_status: searchStatuses,
    input: searchInputValue,
    keywords: searchKeywords,
  } = useAppSelector(selectCoursesSearch);

  const { ref: observerTarget, inView } = useInView({
    threshold: 0.2,
    delay: 200,
  });

  useEffect(() => {
    if (!inView || !coursesList.length || coursesList.length >= total) return;

    // Капец :(
    const searchParams: SearchParams = {
      categories: searchCategories?.length > 0 ? searchCategories?.join() : undefined,
      duration: searchDurations?.length > 0 ? searchDurations?.join() : undefined,
      format: searchFormats?.length > 0 ? searchFormats?.join() : undefined,
      enrolled_status: searchStatuses?.length > 0 ? searchStatuses?.join() : undefined,
      competences: searchCompetences?.length > 0 ? searchCompetences?.join() : undefined,
      query: searchInputValue,
      keywords: searchKeywords?.length > 0 ? searchKeywords?.join() : undefined,
      limit,
      offset: offset + limit,
    };

    dispatch(getSearchCourses(searchParams));

    // eslint-disable-next-line
  }, [dispatch, inView]);

  useEffect(() => {
    dispatch(getSearchCourses());
    dispatch(getCoursesCategories());
    dispatch(getCourseStatuses());
    dispatch(getCoursesCompetences());
    dispatch(getCoursesFormats());
    dispatch(getKeywordsCoursesList());

    return () => {
      clearFilters$.emit();
    };
  }, [clearFilters$, dispatch]);

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["container"]}>
        <CoursesSearch clearFilters$={clearFilters$} />

        <div className={styles["courses-container"]}>
          <div className={styles["title-container"]}>
            {(coursesList.length > 0 || !coursesListIsLoaded) && (
              <h1 className={styles["title"]}>Каталог</h1>
            )}
          </div>

          <CoursesList
            courses={coursesList}
            isSearch={
              searchCategories?.length > 0 ||
              searchDurations?.length > 0 ||
              searchFormats?.length > 0 ||
              searchStatuses?.length > 0 ||
              searchCompetences?.length > 0 ||
              !!searchInputValue ||
              !!searchKeywords?.length
            }
            clearFilters$={clearFilters$}
            isLoading={!coursesListIsLoaded}
          />

          {coursesListIsLoaded && <div ref={observerTarget} />}
        </div>
      </div>
    </div>
  );
};

export default Courses;
