import { IQuizQuestion } from "../../redux/Quze/interface";
import { getQuizControl } from "./functions";

interface QuestionProps {
  lessonId: string;
  quest: IQuizQuestion;
  questIndex: number;
  handleChange?: (option: {
    quizeId: string | number;
    questionId: string | number;
    attemptId: number;
    answer: any;
  }) => void;
}

export const Question = ({ lessonId, quest, questIndex, handleChange }: QuestionProps) => {
  return <>{getQuizControl({ quest, questIndex, lessonId, handleChange })}</>;
};
