import { useEffect, useState } from "react";
import CourseTestQuestions from "./components/CourseTestQuestions/CourseTestQuestions";
import CourseTestStart from "./CourseTestStart";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { showAsideNav, showBottomNav } from "../../redux/courseSlice";
import { CourseLessonQuiz } from "../../redux/interfaces";
import CourseLoadSkeleton from "../CourseLoadSkeleton";
import CourseTestResult from "./CourseTestResult";
import {
  clearAllAnswers,
  clearQuestAnswer,
  clearQuestions,
} from "./redux/Quze/courseQuizeQuestionsSlice";
import { selectCourseQuizeStatus } from "./redux/Quze/courseQuizeSelectors";
import { clearScore, getQuize, getQuizeResult, setQuizeIdle } from "./redux/Quze/courseQuizeSlice";

const CourseTestMain = ({ lesson }: { lesson: CourseLessonQuiz }) => {
  const quizeStatus = useAppSelector(selectCourseQuizeStatus);
  const dispatch = useAppDispatch();
  const [isLoadQuizeData, setIsLoadQuizeData] = useState(false);

  useEffect(() => {
    dispatch(showBottomNav());
    dispatch(showAsideNav());
    dispatch(getQuize(lesson?.id)).then(() => {
      setIsLoadQuizeData(true);
      dispatch(clearQuestAnswer());
    });
    dispatch(getQuizeResult(lesson.id));

    return () => {
      dispatch(setQuizeIdle());
      dispatch(clearQuestAnswer());
      dispatch(clearQuestions());
      dispatch(clearAllAnswers());
      dispatch(clearScore());
      setIsLoadQuizeData(false);
    };
  }, [dispatch, lesson?.id, setIsLoadQuizeData]);

  const renderQuize = (status: string) => {
    switch (status) {
      case "idle":
        return <CourseTestStart lesson={lesson} />;
      case "started":
        return <CourseTestQuestions />;
      case "complete":
        return <CourseTestResult lesson={lesson} />;
      default:
        return <CourseLoadSkeleton />;
    }
  };

  return <>{isLoadQuizeData ? renderQuize(quizeStatus) : renderQuize("")}</>;
};

export default CourseTestMain;
