import classNames from "classnames";
import { useMediaQuery, useRipple } from "hooks";
import {
  CSSProperties,
  ForwardedRef,
  MouseEvent,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
} from "react";
import colorsStyles from "./Button-colors.module.scss";
import sizesStyles from "./Button-sizes.module.scss";
import { ButtonLoader, SmallButtonLoader } from "components/Svg/Icons";
import { mediaQuerys } from "app/utils";
import styles from "./Button.module.scss";
import { useDebounceEffect } from "ahooks";

interface ButtonProps {
  children?: ReactNode;
  color?: "base" | "primary" | "accent" | "base-lignt";
  size?: "big" | "small";
  className?: string;
  disabled?: boolean;
  hasRipple?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
  type?: "button" | "submit";
  isLoading?: boolean;
}

const Button = forwardRef(
  (
    {
      children,
      className = "",
      size = "big",
      color = "base",
      onClick = () => {},
      disabled = false,
      hasRipple = true,
      style = {},
      type = "button",
      isLoading = false,
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const [debouncedLoading, setDebouncedLoading] = useState<boolean>(false);

    const addRipple = useRipple();

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      if (!isLoading) {
        if (hasRipple) {
          addRipple(event);
        }
        onClick(event);
      }
    };

    const buttonClassNames = classNames(
      styles["button"],
      sizesStyles[`${size}-size`],
      colorsStyles[color],
      {
        [styles["button-custom_text_color"]]: process.env.REACT_APP_THEME === "myAcademy",
      },
      className
    );

    const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

    useDebounceEffect(
      () => {
        !isLoading && setDebouncedLoading(false);
      },
      [isLoading],
      { wait: 500 }
    );

    useEffect(() => {
      isLoading && setDebouncedLoading(true);
    }, [isLoading]);

    return (
      <button
        className={buttonClassNames}
        onClick={handleClick}
        disabled={disabled || debouncedLoading}
        style={style}
        type={debouncedLoading ? "button" : type}
        ref={ref}
      >
        {debouncedLoading ? (
          size === "big" ? (
            <ButtonLoader width={isMobile ? 45 : 110} height={isMobile ? 18 : 24} />
          ) : (
            <SmallButtonLoader width={isMobile ? 30 : 90} height={isMobile ? 14 : 20} />
          )
        ) : (
          children
        )}
      </button>
    );
  }
);

export { Button };
