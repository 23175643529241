import { EducationStudioLogo } from "components/Logos";
import { LanitLogo } from "components/Logos/LanitLogo";
import UrlContext from "contexts/urlcontext";
import { useMediaQuery } from "hooks";
import { AuthTypes } from "pages/Authorization/authRoutes.congig";
import { useContext } from "react";
import { AuthorizationIllustration } from "../AuthorizationIllustration";
import { SupportRow } from "../SupportRow/SupportRow";
import classNames from "classnames";
import styles from "./AuthorizationLeftContent.module.scss";

export const AuthorizationLeftContent = (): JSX.Element => {
  const authContext = useContext(UrlContext);

  const isMob = useMediaQuery(`(max-width:840px)`);

  const handleContent = (context: any) => {
    switch (context) {
      case AuthTypes.REGISTRATION_SECOND_STEP:
      default:
        return <AuthorizationIllustration />;
    }
  };

  return (
    <div
      className={classNames(styles["left-content"], {
        [styles["myacademy"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
    >
      {process.env.REACT_APP_THEME !== "myAcademy" ? (
        <EducationStudioLogo className={styles["logo"]} />
      ) : (
        <LanitLogo className={styles["my-academy-logo"]} />
      )}
      <div className={styles["text"]}>
        <div className={styles["title"]}>
          {process.env.REACT_APP_THEME !== "myAcademy" ? "Education Studio" : "MyAcademy"}
        </div>
        <div className={styles["subtitle"]}>
          {process.env.REACT_APP_THEME !== "myAcademy"
            ? "Заходи скорее и начинай обучение!"
            : "Добро пожаловать в Корпоративный онлайн-университет ЛАНИТ!"}{" "}
        </div>
        <div className={styles["svg-container"]}>{handleContent(authContext)}</div>
      </div>
      {!isMob && <SupportRow />}
    </div>
  );
};
