import "./App.css";

import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { WebAppRoutes } from "./app/routes";
import { P403Pic, P404Pic, P500Pic, P503Pic } from "./components/Svg/Pics";
import View from "./components/View/View";
import UrlContext from "./contexts/urlcontext";
import NeedAuth from "./hoc/NeedAuth";
import { Authorization } from "./pages/Authorization/Authorization";
import ErrorPage from "./pages/Errors/ErrorPage";

import { Capacitor } from "@capacitor/core";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { useEventListener, useMemoizedFn } from "ahooks";
import { generateUid } from "app/utils";
import { selectAuthOIDC } from "features/auth/selectors";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { authRoutesConfig } from "pages/Authorization/authRoutes.congig";
import { TechWorks } from "pages/Errors/TechWorks/TechWorks";
import { OperationHistory } from "pages/OperationHistory";
import { Orders } from "pages/Orders/Orders";
import {
  getShopAvailable,
  getShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { RewardsShop } from "pages/RewardsShop/RewardsShop";
import { useSelector } from "react-redux";
import { ErrorPageMessages } from "./app/messages";
import NeedAuthAnonymos from "./hoc/NeedAuthAnonymos";
import NeedAuthShared from "./hoc/NeedAuthShared";
import { Main } from "./pages";
import Course from "./pages/Course/Course";
import { CourseLanding } from "./pages/CourseLanding/CourseLanding";
import Courses from "./pages/Courses/Courses";
import MyCourses from "./pages/My/MyCourses";
import { getInitProfileFields } from "./pages/Profile/redux/profile-slice/profile.slice";
import { Settings } from "./pages/Settings";
import { getMobileSettings } from "components/UpdateMobileModal/redux/mobile.slice";
import { timeLastUpdateMobile } from "components/UpdateMobileModal/redux/mobile.select";

const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ "./pages/Profile/Profile"));
const CourseHistory = lazy(() =>
  import(/* webpackChunkName: "History" */ "./pages/CourseHistory/CourseHistory")
);
const Achievements = lazy(() =>
  import(/* webpackChunkName: "Achievements" */ "./pages/Achievements/Achievements")
);
const Rating = lazy(() => import(/* webpackChunkName: "Rating" */ "./pages/Rating/Rating"));

const App = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuthOIDC);
  const timeLastUpdate = useAppSelector(timeLastUpdateMobile);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }, []);

  useEventListener("resize", () => {
    if (!Capacitor.isNativePlatform()) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  });

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      ScreenOrientation.lock({ orientation: "portrait" });
    }
  }, []);

  const hanleGetMobileSettings = useMemoizedFn(() => {
    const date1 = new Date().getTime();
    const date2 = (timeLastUpdate || new Date(0)).getTime();

    const diffInMilliseconds = Math.abs(date1 - date2);

    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    diffInHours > 1 && dispatch(getMobileSettings());
  });

  useEffect(() => {
    authState.idToken && dispatch(getShopSettings());
    authState.idToken && dispatch(getShopAvailable());
    Capacitor.isNativePlatform() && hanleGetMobileSettings();
  }, [dispatch, authState, hanleGetMobileSettings]);

  useEffect(() => {
    dispatch(getInitProfileFields());
  }, [dispatch]);

  return (
    <View>
      <Routes>
        {authRoutesConfig.map((route) => (
          <Route
            exact
            path={route.path}
            element={
              <UrlContext.Provider value={route.contextInitial}>
                <Authorization />
              </UrlContext.Provider>
            }
            key={generateUid()}
          />
        ))}

        <Route
          exact
          path={WebAppRoutes.PROFILE + "/*"}
          element={
            <NeedAuth>
              <Main>
                <Suspense fallback={<div></div>}>
                  <Profile />
                </Suspense>
              </Main>
            </NeedAuth>
          }
        />
        <Route
          exact
          path={WebAppRoutes.COURSE_SHARED + "/*"}
          element={<Navigate to="/" replace />}
        />
        <Route
          exact
          path={WebAppRoutes.COURSE_SHARED + "/:course_share_id/*"}
          element={
            <NeedAuthAnonymos>
              <div></div>
            </NeedAuthAnonymos>
          }
        />

        <Route exact path={WebAppRoutes.COURSE + "/*"} element={<Navigate to="/" replace />} />
        <Route
          exact
          path={WebAppRoutes.COURSE + "/:course_id/*"}
          element={
            <NeedAuthShared>
              <Course />
            </NeedAuthShared>
          }
        />

        <Route
          exact
          path={WebAppRoutes.COURSE_PREVIEW + "/:course_id"}
          element={
            <NeedAuth>
              <Main>
                <CourseLanding />
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.COURSES}
          element={
            <NeedAuth>
              <Main>
                <Courses />
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.REWARDS_SHOP}
          element={
            <NeedAuth>
              <Main>
                <RewardsShop />
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.OPERATION_HISTORY}
          element={
            <NeedAuth>
              <Main>
                <OperationHistory />
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.HOME}
          element={
            <NeedAuth>
              <Main>
                <Suspense fallback={<div></div>}>
                  <MyCourses />
                </Suspense>
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.HISTORY}
          element={
            <NeedAuth>
              <Main>
                <Suspense fallback={<div></div>}>
                  <CourseHistory />
                </Suspense>
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.SETTINGS}
          element={
            <NeedAuth>
              <Main>
                <Settings />
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.ACHIEVEMENTS}
          element={
            <NeedAuth>
              <Main>
                <Suspense fallback={<div></div>}>
                  <Achievements />
                </Suspense>
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.RATING}
          element={
            <NeedAuth>
              <Main>
                <Suspense fallback={<div></div>}>
                  <Rating />
                </Suspense>
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path={WebAppRoutes.ORDERS}
          element={
            <NeedAuth>
              <Main>
                <Suspense fallback={<div></div>}>
                  <Orders />
                </Suspense>
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path="/500"
          element={
            <NeedAuth>
              <Main>
                <ErrorPage
                  pic={<P500Pic />}
                  title={ErrorPageMessages.PAGE_500_TITLE}
                  text={ErrorPageMessages.PAGE_500_DETAIL}
                />
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path="/503"
          element={
            <NeedAuth>
              <Main>
                <ErrorPage
                  pic={<P503Pic />}
                  title={ErrorPageMessages.PAGE_503_TITLE}
                  text={ErrorPageMessages.PAGE_503_DETAIL}
                />
              </Main>
            </NeedAuth>
          }
        />

        <Route
          exact
          path="/403"
          element={
            <NeedAuth>
              <Main>
                <ErrorPage
                  pic={<P403Pic />}
                  title={ErrorPageMessages.PAGE_403_TITLE}
                  text={ErrorPageMessages.PAGE_403_DETAIL}
                />
              </Main>
            </NeedAuth>
          }
        />
        <Route
          exact
          path="/404"
          element={
            <NeedAuth>
              <Main>
                <ErrorPage
                  pic={<P404Pic />}
                  title={ErrorPageMessages.PAGE_404_TITLE}
                  text={ErrorPageMessages.PAGE_404_DETAIL}
                />
              </Main>
            </NeedAuth>
          }
        />
        <Route
          exact
          path="works"
          element={
            <Main>
              <TechWorks />
            </Main>
          }
        />
        <Route exact path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </View>
  );
};

export default App;
