import Container from "../../../../components/Container/Container";
import { numWord } from "../../../../app/utils";
import { TextMain } from "../../../../components/Typography/Texts";
import { selectCourseQuestionnaire } from "./redux/Questionary/courseQuestionarySelectors";
import { H1, H3 } from "../../../../components/Typography/Titles";
import { CourseSheetStartImg, HappyCatAuthPick } from "../../../../components/Svg/Pics";
import CourseTestContentHeader from "../CourseTestContentHeader";
import { CourseLessonQuestionary } from "../../redux/interfaces";
import { useAppSelector } from "hooks/redux";
import { AchievementsListLesson } from "../AchievementsListLesson";
import {
  HappyCapybaraMyAcademyIllustration,
  QuestionaryMyAcademyIllustration,
} from "components/Illustrations";
import classNames from "classnames";

const CourseSheetThank = ({ lesson }: { lesson: CourseLessonQuestionary }) => {
  const questionnaire = useAppSelector(selectCourseQuestionnaire);
  const questionsLength = lesson.questions_length || questionnaire.questions_length;

  return (
    <div className="course-wrap">
      <main className="course-test-main" style={{ minHeight: "unset" }}>
        <Container>
          <div className="course-test-block">
            <div className="course-test-content course-sheet-content">
              <CourseTestContentHeader status={"completed"} coins={lesson.coins}>
                <H1 className="course-test-top__title">
                  {lesson.title}
                  <span className="course-test-top__title-addition">
                    <span className="course-test-top-time">
                      <span className="course-test-top__time--default">
                        {questionsLength}{" "}
                        {numWord(questionsLength, ["вопрос", "вопроса", "вопросов"])}
                      </span>
                    </span>
                  </span>
                </H1>
              </CourseTestContentHeader>

              <AchievementsListLesson />

              <div
                className={classNames("course-sheet-content-img", {
                  "course-sheet-content-img-myAcademy": process.env.REACT_APP_THEME === "myAcademy",
                })}
              >
                {process.env.REACT_APP_THEME !== "myAcademy" && <HappyCatAuthPick />}
                {process.env.REACT_APP_THEME === "myAcademy" && (
                  <HappyCapybaraMyAcademyIllustration />
                )}
              </div>
              <H3 className="course-sheet-content__title">Спасибо, что ответили на все вопросы!</H3>
              <TextMain className="course-sheet-content__descr">
                Ваши ответы помогут нам сделать курс еще лучше.
              </TextMain>
            </div>
            <div className="course-test-img course-sheet-img course-sheet-content-tableimg">
              {process.env.REACT_APP_THEME === "myAcademy" && (
                <div className="course-pic" data-pic="sheet-start">
                  <QuestionaryMyAcademyIllustration />
                </div>
              )}
              {process.env.REACT_APP_THEME !== "myAcademy" && <CourseSheetStartImg />}
            </div>
          </div>
        </Container>
      </main>
    </div>
  );
};

export default CourseSheetThank;
