import { TextMessages } from "app/messages";
import { AuthTypes } from "../authRoutes.congig";
import { AuthData } from "./data.types";
import { PointerEvent } from "react";
import { adminEmail } from "app/constants";
import { WebAppRoutes } from "app/routes";

export const registrationEmailBlocked = (nav: any): AuthData => {
  return {
    type: AuthTypes.REGISTRATION_EMAIL_BLOCKED,
    toggles: {
      top: {
        title: TextMessages.AUTHORIZE_TO_ANOTHER,
        action: () => nav(WebAppRoutes.AUTH),
      },
    },
    titles: {
      main: TextMessages.EMAIL_IS_BLOCKED,
    },
    form: {
      info: TextMessages.ERROR_EMAIL_IS_BLOCKED,
      actions: {
        buttons: {
          primary: {
            title: TextMessages.SEND_TO_ADMIN,
            action: (event: PointerEvent<HTMLButtonElement>) => {
              event.preventDefault();
              // @ts-ignore
              window.location = `mailto:${adminEmail}` as string;
            },
          },
        },
      },
    },
  };
};
