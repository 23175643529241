import classNames from "classnames";
import { useModal } from "components/ModalsContainer";
import { PriceParagraph } from "components/PriceParagraph";
import { ClubSkeleton } from "components/Svg/Icons";
import { Tooltip } from "components/Tooltip";
import { Subtitle } from "components/Typography/Titles";
import { useAppDispatch } from "hooks/redux";
import { useClub } from "pages/OperationHistory/hooks/useClub";
import { IShopProduct } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import {
  getShopProduct,
  putProductViews,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { ShopProductModal } from "pages/RewardsShop/components/ShopProductModal";
import { useMemo, useState } from "react";
import { BuyAgreementModal } from "../BuyAgreementModal/BuyAgreementModal";
import { BuyButton } from "../BuyButton/BuyButton";
import { BuyErrorModal } from "../BuyErrorModal/BuyErrorModal";
import { ProductSlider } from "../ProductSlider/ProductSlider";
import "./ShopCard.scss";
import styles from "./ShopCard.module.scss";

var sliderSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 0,
  arrows: false,
  appendDots: (dots: JSX.Element[]) => {
    return (
      <div>
        <ul className="slick-dots-list">{dots}</ul>
      </div>
    );
  },
  customPaging: () => {
    return <div className="slick-dots-dot"></div>;
  },
};

interface ShopCardProps {
  item: IShopProduct;
}

export const ShopCard = ({ item }: ShopCardProps) => {
  const { openModal } = useModal();
  const dispatch = useAppDispatch();
  const club = useClub(item.club_id);
  const sortedPictures = useMemo(
    () => [...item.pictures].sort((x, y) => Number(y.is_main) - Number(x.is_main)),
    [item.pictures]
  );

  const openShopItem = (e: React.MouseEvent<HTMLDivElement> | undefined) => {
    /// Если нажали на слайдер
    if ((e?.target as HTMLDivElement).className === "slick-dots-dot") {
      return;
    }

    dispatch(getShopProduct(item.id))
      .unwrap()
      .then((product) => {
        if (
          !product ||
          !product.visible ||
          (product.available_after && Date.now() < Date.parse(product.available_after)) ||
          (product.available_until && Date.now() > Date.parse(product.available_until))
        ) {
          openModal({
            content: <BuyErrorModal />,
            clickOutsideClose: false,
          });
          return;
        }

        openModal({
          content: <ShopProductModal product={product} />,
          clickOutsideClose: false,
        });

        dispatch(putProductViews(product.id));
      });
  };

  const onBuy = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    openModal({
      content: (
        <BuyAgreementModal id={item.id} price={item.price} name={item.name} clubId={item.club_id} />
      ),
      clickOutsideClose: false,
    });
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
  };

  return (
    <div
      className={classNames(styles["card"], "shop-card", {
        [styles["myacademy_theme"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
      onClick={openShopItem}
    >
      <div className={styles["card__head"]}>
        <ProductSlider
          settings={sliderSettings}
          pictures={sortedPictures}
          className={classNames(styles["card__slider"], "shop-card__slider")}
        />

        {club.id && (
          <Tooltip
            content={`Доступно для: ${club.name}`}
            wrapperClassName={styles["card__badge"]}
            className={styles["card__badge-text"]}
          >
            <a
              onClick={(e) => e.stopPropagation()}
              href={club.club_link}
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <img
                  src={club?.club_icon}
                  className={styles["card__badge-img"]}
                  alt=""
                  loading="lazy"
                  onLoad={handleLoad}
                  onError={handleError}
                  style={{ opacity: isLoading ? "0" : "1" }}
                />
                {isLoading && <ClubSkeleton className={styles["card__badge-img"]} />}
              </div>
            </a>
          </Tooltip>
        )}
      </div>

      <div className={styles["card__body"]}>
        <Subtitle className={styles["card__title"]} title={item.name}>
          {item.name}
        </Subtitle>
        <div className={styles["card__buy_container"]}>
          <div className={styles["card__buy_container__info"]}>
            {item.price && <PriceParagraph price={item.price} />}
          </div>

          <BuyButton onClick={onBuy} itemPrice={item.price} clubId={item.club_id} />
        </div>
      </div>
    </div>
  );
};
