import classNames from "classnames";
import {
  CoursePic,
  FormPic,
  InteractPic,
  LongridPic,
  ScormPic,
  TaskPic,
  TestPic,
  WebinarPic,
} from "components/Svg/CoursesTypes";
import { useEffect, useState } from "react";
import { generateUid, numWord } from "../../../app/utils";
import Container from "../../../components/Container/Container";
import { CourseTestImg, LockPic, SadCatPic } from "../../../components/Svg/Pics";
import { TextMain } from "../../../components/Typography/Texts";
import { H1 } from "../../../components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { lessonsSelectors, showAsideNav, showBottomNav } from "../redux/courseSlice";
import CourseLoadSkeleton from "./CourseLoadSkeleton";

const TypedMockImage = ({ type }: { type: string | undefined }) => {
  // https://wiki.lanit.ru/pages/viewpage.action?pageId=355346924
  switch (type) {
    // 1 Страница (Лонгрид)
    case "page":
      return <LongridPic />;
    // 2 Интерактивная книга (АТБ)
    case "book":
      return <InteractPic />;
    case "atb":
      return <CoursePic />;
    // 3 Вебинар
    case "meeting":
      return <WebinarPic />;
    // 4 Тест
    case "quiz":
      return <TestPic />;
    // 5 Анкета (Опрос, обратная связь)
    case "questionnaire":
      return <FormPic />;
    // 6 Тренажер (SCORM)
    case "scorm":
      return <ScormPic />;
    // 7 Задание
    case "task":
      return <TaskPic />;
    default:
      return <ScormPic />;
  }
};

const CourseLock = ({
  title,
  type,
  preconditions = [],
}: {
  title?: string;
  type?: string;
  preconditions: number[];
}) => {
  const dispatch = useAppDispatch();
  const allLesson = useAppSelector((state) => lessonsSelectors.selectAll(state));
  const [preconditionsTitle, setPreconditionsTitle] = useState<string[]>([]);
  const [isLoadTextData, setIsLoadTextData] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoadTextData(false);
    };
  }, []);

  useEffect(() => {
    if (preconditions.length) {
      const titles = allLesson
        .filter((item) => preconditions.indexOf(item.id) !== -1 && item.status !== "completed")
        .map((item) => item.title);
      setPreconditionsTitle(titles);
      setIsLoadTextData(true);
    }
  }, [allLesson, preconditions]);

  useEffect(() => {
    dispatch(showBottomNav());
    dispatch(showAsideNav());
  }, [dispatch]);

  return (
    <>
      {!isLoadTextData ? (
        <CourseLoadSkeleton />
      ) : (
        <Container>
          <div className="course-test-block">
            <div className="course-available-content">
              <div className="course-test-content-img">
                {process.env.REACT_APP_THEME !== "myAcademy" ? (
                  <SadCatPic />
                ) : (
                  <LockPic width="318" height="306" />
                )}
              </div>
              <H1 className="course-available__title">{title}</H1>
              <div className="course-test__descr">
                <TextMain className="course-available__descr">Недоступно для прохождения.</TextMain>
                <TextMain className="course-available__descr">
                  Для доступа к теме "{title}" необходимо пройти
                  {numWord(preconditionsTitle.length, [" тему", " темы", " темы"])}:
                  {preconditionsTitle.length === 1 && ` "${preconditionsTitle[0]}"`}
                </TextMain>
                {preconditionsTitle.length > 1 && (
                  <div style={{ backgroundColor: "red" }}>
                    <ul style={{ listStyle: "none", marginTop: "10px" }}>
                      {preconditionsTitle.map((item, index) => (
                        <li key={generateUid()} style={{ marginBottom: "5px" }}>
                          {index + 1}. "{item}"
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(
                "course-test-img",
                "course-available-img",
                "course-test-img__" + type,
                {
                  // eslint-disable-next-line
                  ["course-test__myacademy-illustration"]:
                    process.env.REACT_APP_THEME === "myAcademy",
                }
              )}
            >
              {process.env.REACT_APP_THEME !== "myAcademy" ? (
                <CourseTestImg />
              ) : (
                <TypedMockImage type={type} />
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default CourseLock;
