import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../services/api/api";
import { EndPoints } from "../../../app/endpoints";
import { Trainer } from "./interfaces";

const initialState: {
  visibleTrainer: boolean;
  trainer: Trainer | null;
  trainerCmiValue: { element: string; value: string }[];
  isLoadingTrackedData: boolean;
  results:
    | {
        progress: number;
        date: string;
      }[]
    | null;
} = {
  visibleTrainer: false,
  trainer: null,
  results: null,
  trainerCmiValue: [],
  isLoadingTrackedData: false,
};

const getCourseTraining = createAsyncThunk<Trainer, number>(
  "trainers/getCourseTraining",
  async (trainerId: number) => {
    const response = await api.get(EndPoints.COURSE_TRAINERS(trainerId));
    return response.data?.data;
  }
);

const setCourseTrainingStart = createAsyncThunk<void, number>(
  "trainers/setCourseTrainingStart",
  async (trainerId: number) => {
    const response = await api.post(EndPoints.COURSE_TRAINERS_START(trainerId));
    return response.data;
  }
);

const setCourseTrainingEnd = createAsyncThunk<void, number>(
  "trainers/setCourseTrainingEnd",
  async (trainerId: number) => {
    const response = await api.post(EndPoints.COURSE_TRAINERS_END(trainerId));
    return response.data;
  }
);

const setScormTrackedData = createAsyncThunk<
  void,
  { trainerId: number; data: { element: string; value: string }[] }
>("trainers/setScormTrackedData", async ({ trainerId, data }) => {
  const response = await api.post(EndPoints.COURSE_TRAINERS_TRAKED_DATA(trainerId), data);
  return response.data;
});

const getScormTrackedData = createAsyncThunk<{ element: string; value: string }[], number>(
  "trainers/getScormTrackedData",
  async (trainerId) => {
    const response = await api.get(EndPoints.COURSE_TRAINERS_TRAKED_DATA(trainerId));
    return response.data;
  }
);

// TODO: Remove Any
const getCourseTrainingResults = createAsyncThunk<any, number>(
  "trainers/getCourseTrainingResults",
  async (arg, thunkAPI) => {
    const response = await api.get(EndPoints.COURSE_TRAINERS_RESULTS(arg));
    return response.data;
  }
);

const courseTrainersSlice = createSlice({
  name: "trainers",
  initialState,
  reducers: {
    setTrainerCmiValue(state, action) {
      const { payload } = action;
      const currentValue = state.trainerCmiValue;
      state.trainerCmiValue = [
        ...currentValue.filter(({ element }) => element !== payload.element),
        payload,
      ];
    },
    clearTrainerCmiValue(state) {
      state.trainerCmiValue = [];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCourseTraining.fulfilled, (state, action) => {
        const trainer = action.payload;
        return { ...state, trainer, visibleTrainer: false };
      })
      .addCase(getScormTrackedData.pending, (state) => {
        state.isLoadingTrackedData = true;
        state.trainerCmiValue = [];
      })
      .addCase(getScormTrackedData.fulfilled, (state, action) => {
        state.isLoadingTrackedData = false;
        state.trainerCmiValue = [
          ...action.payload.map(({ element, value }) => {
            if (typeof value === "number") {
              return { element, value: `${value}` };
            }
            return { element, value };
          }),
        ];
      })
      .addCase(getScormTrackedData.rejected, (state) => {
        state.isLoadingTrackedData = false;
        state.trainerCmiValue = [];
      })
      .addCase(getCourseTraining.pending, () => {})
      .addCase(getCourseTraining.rejected, () => {})
      .addCase(setCourseTrainingStart.fulfilled, (state) => {
        return { ...state, visibleTrainer: true };
      })
      .addCase(setCourseTrainingStart.rejected, (state) => {
        if (state.trainer?.attempt_status === "started") {
          state.visibleTrainer = true;
        }
        return state;
      })
      .addCase(setCourseTrainingEnd.fulfilled, (state) => {
        return { ...state, visibleTrainer: false };
      })
      .addCase(getCourseTrainingResults.fulfilled, (state, action) => {
        const { data: results } = action.payload;

        return {
          ...state,
          results: results?.attempts?.every((item: any) => item.score === 0)
            ? results
            : results?.attempts?.sort(
                (a: { score: string }, b: { score: string }) => +b.score - +a.score
              ),
        };
      }),
});

export {
  getCourseTraining,
  setCourseTrainingStart,
  setCourseTrainingEnd,
  getCourseTrainingResults,
  setScormTrackedData,
  getScormTrackedData,
};

export const { setTrainerCmiValue, clearTrainerCmiValue } = courseTrainersSlice.actions;
export default courseTrainersSlice.reducer;
