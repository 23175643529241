import { useAppDispatch } from "../../../../hooks/redux";
import { setPageBeforeCourse } from "../../../Course/redux/courseSlice";
import { useNavigate } from "react-router-dom";
import { WebAppRoutes } from "app/routes";
import { useMediaQuery } from "hooks";
import { parseISO } from "date-fns";
import { mediaQuerys } from "app/utils";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import { Capacitor } from "@capacitor/core";
import { CourseList } from "pages/Courses/redux/interface";

export const useCoursesCard = ({ course }: { course: CourseList }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const openLinkInNewTab = (path: string) =>
    window.open(`${origin}${process.env.REACT_APP_BASE_PATH}${path}`, `tab_${Date.now()}`);

  const handlerClickCourseCard = (id: number) => {
    dispatch(setPageBeforeCourse(WebAppRoutes.COURSES));
    if (
      ((course?.start_dt && parseISO(course.start_dt) < new Date()) || !course.start_dt) &&
      ((course?.end_dt && parseISO(course.end_dt) > new Date()) || !course?.end_dt) &&
      course.enrolled_status === EnrolledStatus.enrolled &&
      (course.time_limit_actual === null || !!course.time_limit_actual)
    ) {
      if (Capacitor.isNativePlatform() || isMobile) {
        navigate(WebAppRoutes.COURSE + "/" + id);
        return;
      }
      openLinkInNewTab(`${WebAppRoutes.COURSE}/${id}`);
    } else {
      if (Capacitor.isNativePlatform() || isMobile) {
        navigate(WebAppRoutes.COURSE_PREVIEW + "/" + id);
      }
      openLinkInNewTab(`${WebAppRoutes.COURSE_PREVIEW}/${id}`);
      return;
    }
  };

  return { handlerClickCourseCard, isMobile };
};
