import { EntityId } from "@reduxjs/toolkit";
import { CloseIcon, LeftArrowShortIcon, ListChecksIcon } from "components/Icons";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { WebAppRoutes } from "../../../app/routes";
import { mediaQuerys, numWord } from "../../../app/utils";
import { Skeleton } from "../../../components/Skeleton";
import { useMediaQuery } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  selectMulticourseContent,
  selectPageBeforeCourse,
} from "../../../pages/Course/redux/courseSelectors";
import {
  coursesSelectors,
  lessonsSelectors,
  setPageBeforeCourse,
} from "../../../pages/Course/redux/courseSlice";
import UserWidget from "../../PublicLayout/components/UserWidget/UserWidget";

const CourseHeader = ({
  courseId,
  courseTitle,
  isOpen,
  toggleMobileMenuCallback,
}: {
  courseId?: string;
  courseTitle?: string;
  isOpen: boolean;
  toggleMobileMenuCallback: () => void;
}) => {
  const dispatch = useAppDispatch();
  const smMin = useMediaQuery(`(min-width:${mediaQuerys.smMin}px)`);
  const xsMax = useMediaQuery(`(max-width:${mediaQuerys.xsMax}px)`);
  const allLesson = useSelector(lessonsSelectors.selectAll);
  const multicourseContent = useSelector(selectMulticourseContent);
  const pageBeforeCourse = useAppSelector(selectPageBeforeCourse);
  const currentCourse = useAppSelector((state) =>
    coursesSelectors.selectById(state, courseId as EntityId)
  );

  const [progress, setProgress] = useState({
    percent: 0,
    complete: 0,
    total: 0,
  });

  useEffect(() => {
    const isMulticourse = currentCourse?.format === CourseFormat.multicourse;

    const complete = !isMulticourse
      ? allLesson.filter((item) => item.status === "completed").length
      : multicourseContent?.filter((item) => item.status === "completed").length || 0;

    const total = !isMulticourse ? allLesson.length : multicourseContent?.length || 0;
    const percent = (complete / total) * 100;

    setProgress({
      percent: percent,
      complete: complete,
      total: total,
    });
  }, [allLesson, multicourseContent, currentCourse]);

  useEffect(() => {
    return () => {
      dispatch(setPageBeforeCourse(WebAppRoutes.HOME));
    };
  }, [dispatch]);

  // @ts-ignore
  return (
    <header className="course-test-header">
      <div className="course-test-header-block">
        <div className="course-test-header-navigation">
          <Link to={pageBeforeCourse}>
            <div className="course-test-header-iconprev-container">
              <LeftArrowShortIcon className="course-test-header-iconprev" />
            </div>
          </Link>
          <p className="course-test-header__descr">
            {courseTitle ? courseTitle : <Skeleton variant="text" width={200} height={24} />}
          </p>
        </div>
        <div className="course-test-header-info">
          {(
            currentCourse?.format !== CourseFormat.multicourse
              ? allLesson.length
              : multicourseContent?.length
          ) ? (
            <>
              <div className="course-test-prograss">
                <i
                  className={"course-test-prograss-bar"}
                  style={{ width: Math.trunc(progress.percent) + "%" }}
                ></i>
              </div>
              <span className={"course-test-prograss-detail"}>
                {Math.round(progress.percent)}% пройдено / {progress.complete}{" "}
                {currentCourse?.format !== CourseFormat.multicourse
                  ? `${numWord(progress.complete, ["задание", "задания", "заданий"])} из ${
                      progress.total
                    }`
                  : `из ${progress.total}`}
              </span>
            </>
          ) : (
            <>
              <Skeleton variant="text" height={18} />
              <Skeleton variant="text" height={18} />
            </>
          )}
        </div>
        {smMin && <UserWidget />}
        {xsMax && (
          <div
            onClick={() => {
              toggleMobileMenuCallback();
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            {isOpen ? (
              <CloseIcon color="rgb(var(--text-color-02))" />
            ) : (
              <ListChecksIcon color="rgb(var(--text-color-02))" />
            )}{" "}
          </div>
        )}
      </div>
    </header>
  );
};

export default CourseHeader;
