import { adminEmail } from "app/constants";
import { mediaQuerys } from "app/utils";
import { CodeRecieveCounter } from "components/Forms/CodeRecieveCounter";
import { Link } from "components/Link";
import { WithAnimation, WithSwitchAnimation } from "components/utils/utils";
import { useMediaQuery } from "hooks";
import { AuthTypes } from "pages/Authorization/authRoutes.congig";
import { getReferencebookWorkplace } from "pages/Profile/redux/profile-slice/profile.slice";
import { useEffect } from "react";
import { AgreementInfoCheckboxInAuth } from "../AgreementInfoCheckboxInAuth";
import { AuthorizationFields } from "../AuthorizationFields";
import { SupportRow } from "../SupportRow/SupportRow";
import styles from "./AuthorizationForm.module.scss";
import { FormButton } from "./components/FormButton";
import { RegistrationPolicyAgreement } from "./components/RegistrationPolicyAgreement";
import { TimerButton } from "./components/TimerButton";
import { Toggles } from "./components/Toggles";
import { useAuthorizationForm } from "./useAuthorizationForm";

export const AuthorizationForm = () => {
  const {
    authContext,
    showcaseExpanded,
    form,
    formState,
    handleOnChangeForm,
    handleSubmit,
    authObj,
    toggles,
    titles,
    formSubmit,
    showError,
    control,
    showTimer,
    timerInterval,
    hideTimerTimeout,
    showTimerTimeout,
    dispatch,
    policyCheckReg,
    registrationIsAllowed,
  } = useAuthorizationForm();

  useEffect(() => {
    // @ts-ignore
    authContext === AuthTypes.REGISTRATION_SECOND_STEP &&
      dispatch(getReferencebookWorkplace({ only_visible: true }));
  }, [dispatch, authContext]);

  const isLaptop = useMediaQuery(`(max-width:${mediaQuerys.smMin}px)`);
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const isPasswordRecoveryStep = authContext === AuthTypes.PASSWORD_RECOVERY;

  return (
    <div className={styles["container"]}>
      {!isMobile && <Toggles toggles={toggles} registrationIsAllowed={registrationIsAllowed} />}
      <form
        className={styles["form"]}
        data-form={authContext}
        data-shifted={showcaseExpanded}
        data-valid={form?.infoIsValid || formState.isValid}
        autoComplete="off"
        onChange={handleOnChangeForm}
        onSubmit={handleSubmit(formSubmit)}
      >
        {authObj && (
          <div>
            <WithSwitchAnimation keyword={titles?.main}>
              <div className={styles["title"]}>{titles?.main}</div>
            </WithSwitchAnimation>
            <WithAnimation inCondition={!!titles?.sub}>
              <h3 className={styles["subtitle"]}>{titles?.sub}</h3>
            </WithAnimation>
            {isPasswordRecoveryStep && (
              <WithAnimation inCondition={isPasswordRecoveryStep}>
                <h3 className={styles["subtitle"]}>
                  Если вы хотите поменять пароль, свяжитесь
                  <br />с администратором по электронной почте
                  <br />
                  <Link
                    onClick={(event) => {
                      event.preventDefault();
                      // @ts-ignore
                      window.location = `mailto:${adminEmail}`;
                    }}
                  >
                    {adminEmail}
                  </Link>
                  . Запрос можно оставить с помощью
                  <br />
                  кнопки ниже.
                </h3>
              </WithAnimation>
            )}
            <div className={styles["fields"]}>
              <WithAnimation inCondition={!!form?.info}>
                <WithSwitchAnimation keyword={form?.info}>
                  <p className={styles["subtitle"]}>{form?.info}</p>
                </WithSwitchAnimation>
              </WithAnimation>
              {showError && (
                <WithAnimation inCondition={!!form?.errorMsg}>
                  <WithSwitchAnimation keyword={form?.errorMsg}>
                    <p className={styles["error"]}>{form?.errorMsg}</p>
                  </WithSwitchAnimation>
                </WithAnimation>
              )}
              <WithAnimation inCondition={!!(form && form.fields && form.fields.length > 0)}>
                <AuthorizationFields
                  control={control}
                  form={form}
                  isSubmitted={formState.isSubmitted}
                />
              </WithAnimation>
              <WithAnimation inCondition={showTimer !== false}>
                <CodeRecieveCounter
                  starts={true}
                  intervals={timerInterval}
                  onChange={hideTimerTimeout}
                />
              </WithAnimation>
            </div>
            <TimerButton form={form} showTimer={showTimer} showTimerTimeout={showTimerTimeout} />
            <WithAnimation inCondition={authContext === AuthTypes.AUTH_AGREEMENT}>
              <AgreementInfoCheckboxInAuth
                control={control}
                // @ts-ignore
                error={formState.errors?.policy_agreed}
              />
            </WithAnimation>
            <FormButton form={form} />
          </div>
        )}
      </form>
      <RegistrationPolicyAgreement policyCheckReg={policyCheckReg} />
      {isLaptop && <SupportRow />}
    </div>
  );
};
