import { CourseFormat } from "../../../../Course/redux/interfaces/course.interface";
import courseBgES from "./course-bg.education-studio.svg";
import multicourseBgES from "./multicourse-bg.education-studio.svg";
import taskBgES from "./task-bg.education-studio.svg";
import atbBgES from "./atb-bg.education-studio.svg";
import meetingBgES from "./meeting-bg.education-studio.svg";
import questionnaireBgES from "./questionnaire-bg.education-studio.svg";
import pageBgES from "./page-bg.education-studio.svg";
import quizBgES from "./quiz-bg.education-studio.svg";
import trainerBgES from "./trainer-bg.education-studio.svg";
import courseBgMA from "./course-bg.myacademy.svg";
import multicourseBgMA from "./multicourse-bg.myacademy.svg";
import taskBgMA from "./task-bg.myacademy.svg";
import atbBgMA from "./atb-bg.myacademy.svg";
import meetingBgMA from "./meeting-bg.myacademy.svg";
import questionnaireBgMA from "./questionnaire-bg.myacademy.svg";
import pageBgMA from "./page-bg.myacademy.svg";
import quizBgMA from "./quiz-bg.myacademy.svg";
import trainerBgMA from "./trainer-bg.myacademy.svg";

export const getBackgroundImage = (type?: CourseFormat) => {
  switch (type) {
    case CourseFormat.course:
      return process.env.REACT_APP_THEME === "myAcademy" ? courseBgMA : courseBgES;
    case CourseFormat.multicourse:
      return process.env.REACT_APP_THEME === "myAcademy" ? multicourseBgMA : multicourseBgES;
    case CourseFormat.task:
      return process.env.REACT_APP_THEME === "myAcademy" ? taskBgMA : taskBgES;
    case CourseFormat.atb:
      return process.env.REACT_APP_THEME === "myAcademy" ? atbBgMA : atbBgES;
    case CourseFormat.meeting:
      return process.env.REACT_APP_THEME === "myAcademy" ? meetingBgMA : meetingBgES;
    case CourseFormat.questionnaire:
      return process.env.REACT_APP_THEME === "myAcademy" ? questionnaireBgMA : questionnaireBgES;
    case CourseFormat.page:
      return process.env.REACT_APP_THEME === "myAcademy" ? pageBgMA : pageBgES;
    case CourseFormat.quiz:
      return process.env.REACT_APP_THEME === "myAcademy" ? quizBgMA : quizBgES;
    case CourseFormat.trainer:
      return process.env.REACT_APP_THEME === "myAcademy" ? trainerBgMA : trainerBgES;
    default:
      return "";
  }
};
