import { SleepyCatIllustration } from "components/Illustrations/SleepyCatIllustration";
import { CapybaraSearchPic } from "../../../components/Svg/Pics";
import { TextMain } from "../../../components/Typography/Texts";
import { H3 } from "../../../components/Typography/Titles";

const CoursesSearchEmpty = ({ onClear }: { onClear: () => void }) => {
  return (
    <section className="course-empty">
      <div className="course-empty-img">
        {process.env.REACT_APP_THEME !== "myAcademy" ? (
          <SleepyCatIllustration />
        ) : (
          <CapybaraSearchPic />
        )}
      </div>
      <div className="course-empty-text">
        <H3 className="course-empty-text__title">Мы не нашли ничего похожего</H3>
        <TextMain className="course-empty-text__descr">
          Давайте{" "}
          <span className="course-empty-text__reset" onClick={onClear}>
            очистим поиск
          </span>{" "}
          и попробуем найти с другими параметрами
        </TextMain>
      </div>
    </section>
  );
};
export default CoursesSearchEmpty;
