import { KeyboardEvent, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { useSelector } from "react-redux";
import ProfileAvatar from "../../../../../Profile/components/ProfileAvatar";
import { TextAreaField } from "../../../../../../components/TextAreaField";
import { Button } from "../../../../../../components/Buttons";
import { CloseIcon, SendIcon } from "../../../../../../components/Icons";
import { selectProfileDetails } from "../../../../../Profile/redux/profile-slice/selectors/profile.selectors";
import { CircleIconButton } from "../../../../../../components/Buttons/CircleIconButton";
import styles from "./QuestionForm.module.scss";
import { useMediaQuery } from "hooks";
import { useGetAvatarQuery } from "app/store";

interface QuestionFormProps {
  label: string;
  text?: string;
  withAvatar?: boolean;
  withHeader?: boolean;
  className?: string;
  onCancel?: () => void;
  onSubmit?: (text: string) => void;
}

export const QuestionForm = ({
  label,
  text = "",
  withAvatar = true,
  withHeader = true,
  className,
  onCancel,
  onSubmit,
}: QuestionFormProps) => {
  const { lastname, firstname } = useSelector(selectProfileDetails);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isMobile = useMediaQuery("(max-width: 767px)");
  const { data, isLoading } = useGetAvatarQuery();

  console.log(data);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm({ defaultValues: { text } });

  const handleSubmitButton = (formData: { text: string }) => {
    onSubmit?.(formData.text);
    reset();
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>): string => {
    const curValue = textareaRef?.current?.value || "";

    if (e.code === "Enter" && e.ctrlKey) {
      const cursorPosition = textareaRef?.current?.selectionStart || 0;

      setTimeout(() => {
        // Устанавливаем курсор в начало новой строки
        textareaRef?.current?.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
      });

      return curValue?.substring(0, cursorPosition) + "\n" + curValue?.substring(cursorPosition);
    }

    return curValue;
  };

  return (
    <div
      className={classNames(
        styles["question-form"],
        {
          [styles["question-form-with-header"]]: withHeader,
        },
        className
      )}
    >
      <div className={styles["header-container"]}>
        {withAvatar && (
          <ProfileAvatar
            isLoading={isLoading}
            srcImage={data?.path || ""}
            className={styles["avatar"]}
          />
        )}

        <div className={styles["right-block"]}>
          {withHeader && (
            <div className={styles["header-block"]}>
              <div className={styles["header-block__title"]}>
                {lastname} {firstname}
              </div>

              <CircleIconButton className={styles["header-block__btn"]} onClick={onCancel}>
                <CloseIcon />
              </CircleIconButton>
            </div>
          )}

          {(!withHeader || !isMobile) && (
            <div className={styles["form-block"]}>
              <Controller
                name={"text"}
                rules={{ required: "Обязательно для заполнения" }}
                control={control}
                render={({ field }) => (
                  <TextAreaField
                    className={styles["question-form__textarea"]}
                    label={label}
                    {...field}
                    ref={textareaRef}
                    onKeyDown={onKeyDown}
                  />
                )}
              />

              {isValid && (
                <Button
                  className={styles["question-form__btn"]}
                  size="small"
                  color="primary"
                  onClick={handleSubmit(handleSubmitButton)}
                >
                  <SendIcon className={styles["send-icon"]} />
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {isMobile && withHeader && (
        <div className={styles["form-block"]}>
          <Controller
            name={"text"}
            rules={{ required: "Обязательно для заполнения" }}
            control={control}
            render={({ field }) => (
              <TextAreaField
                className={styles["question-form__textarea"]}
                label={label}
                {...field}
                ref={textareaRef}
                onKeyDown={onKeyDown}
              />
            )}
          />

          {isValid && (
            <Button
              className={styles["question-form__btn"]}
              size="small"
              color="primary"
              onClick={handleSubmit(handleSubmitButton)}
            >
              <SendIcon className={styles["send-icon"]} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
