import { WebAppRoutes } from "app/routes";
import { mediaQuerys } from "app/utils";
import Container from "components/Container/Container";
import PaperBox from "components/Paper/PaperBox";
import { useMediaQuery } from "hooks";
import { useAppDispatch } from "hooks/redux";
import { WalletWidget } from "pages/Orders/components/WalletWidget/WalletWidget";
import { getProfileTransactions } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styles from "./OperationHistory.module.scss";
import { OperationHistoryHeader } from "./components/OperationHistoryHeader";
import { OperationHistoryList } from "./components/OperationHistoryList";

export const OperationHistory = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const lgMin = useMediaQuery(`(min-width:${mediaQuerys.lgMin}px)`);
  const shopSettings = useSelector(selectShopSettings);

  useEffect(() => {
    if (!shopSettings?.enable) {
      navigate(WebAppRoutes.HOME);
      return;
    }

    dispatch(getProfileTransactions());
  }, [shopSettings?.enable, dispatch, navigate]);

  const getTransactions = (limit: number, offset: number) => {
    dispatch(getProfileTransactions({ offset, limit })).then(
      (res) => res.payload.status !== 200 && navigate(`/${res.payload.status}`)
    );
  };

  return (
    <Container className={styles["history-container"]}>
      <div className={styles["wrapper"]}>
        <PaperBox className={styles["container"]}>
          <OperationHistoryHeader />
          <OperationHistoryList getTransactions={getTransactions} />
        </PaperBox>
        {lgMin && <WalletWidget />}
      </div>
    </Container>
  );
};
