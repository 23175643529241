import { useCallback, useEffect, useRef, useState } from "react";
import { useMutationObservable } from "../../../../../../../../hooks/useMutationObservable";
import { getFileNameFromSource, getYouTubeIframe } from "../../../../../../../../app/utils";
import { Capacitor } from "@capacitor/core";
import styles from "../../../../../Text/CourseText.module.scss";

interface MeetingContentProps {
  data: string;
  onDataLoaded?: (videos: NodeListOf<HTMLVideoElement> | undefined) => void;
}

function wrapElement(el: any, wrapper: any) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

export const MeetingContent = ({ data, onDataLoaded }: MeetingContentProps) => {
  const [content, setContent] = useState("");
  const contentBlockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parser = new DOMParser();
    const content = parser.parseFromString(data, "text/html");
    const videos = content.querySelectorAll("video");
    const links = content.querySelectorAll("a");

    // Удаляем <br>
    const brTags = content.querySelectorAll("br");
    brTags.forEach((br) => br.remove());

    const optimizeVideoElement = (videoElement: HTMLVideoElement): void => {
      const newVideoElement: HTMLVideoElement = document.createElement("video");
      newVideoElement.controls = true;

      Array.from(videoElement.getAttributeNames()).forEach((attr) => {
        newVideoElement.setAttribute(attr, videoElement.getAttribute(attr)!);
      });

      Array.from(videoElement.querySelectorAll("source")).forEach(
        (sourceTag: HTMLSourceElement) => {
          newVideoElement.appendChild(sourceTag.cloneNode(true) as HTMLSourceElement);
        }
      );

      if (videoElement.parentNode) {
        videoElement.parentNode.replaceChild(newVideoElement, videoElement);
      }
    };

    try {
      links.forEach((linkItem) => {
        let isNodeText = false;
        const linkText = linkItem.innerText;
        const linkHref = linkItem.getAttribute("href");
        const fileExp = linkHref!.match(/\.[0-9a-z]{1,5}(?=[^/]*$)/i);
        const fileName = linkText.substring(linkText.lastIndexOf("/") + 1);

        if (!linkItem.querySelectorAll("*").length && fileExp?.length && linkItem.innerText) {
          linkItem.innerText = decodeURI(fileName);
          if (linkItem.previousSibling && linkItem?.previousSibling?.nodeType === 3) {
            isNodeText = true;
          }
          if (!isNodeText) {
            const wrapper = document.createElement("span");
            wrapper.classList.add(styles["content-file-attached"]);
            if (fileExp) {
              wrapper.dataset.exp = fileExp[0].substring(1);
              linkItem.setAttribute("target", "_blank");
              linkItem.classList.add(styles["content-file-attached__link"]);
              wrapElement(linkItem, wrapper);
            }
          }
        }
      });

      videos.forEach((video) => {
        const sources = video.querySelector("source");
        const src = sources?.src;

        // Проверяем, является ли видео из YouTube
        const youTubeIframe = getYouTubeIframe(src);
        if (video.parentNode && youTubeIframe) {
          // Заменяем тег <video> на созданный <iframe>
          video.parentNode.replaceChild(youTubeIframe, video);
        }

        const name = getFileNameFromSource(src);
        if (!name) return;

        video.setAttribute("name", name);
        Capacitor.isNativePlatform() && optimizeVideoElement(video);
      });

      setContent(content.body.innerHTML);
    } catch (e) {
      setContent(data);
    }
  }, [setContent, data]);

  const loaded = useCallback(() => {
    const videos = contentBlockRef.current?.querySelectorAll("video");
    onDataLoaded?.(videos);
  }, [onDataLoaded]);

  useMutationObservable(contentBlockRef.current, loaded, true);

  return (
    <div style={{ marginTop: 20 }}>
      <div
        ref={contentBlockRef}
        className="editable"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
