import { WebAppRoutes } from "app/routes";
import { BalanceRow } from "components/BalanceRow";
import { Button } from "components/Buttons";
import { Link } from "components/Link";
import PaperBox from "components/Paper/PaperBox";
import { H3 } from "components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  getProfileCoinsDetails,
  getShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import {
  selectProfileCoinsDetails,
  selectShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./WalletWidget.module.scss";
import { ClubDetailsItem } from "./components/ClubDetailsItem";

export const WalletWidget = () => {
  const navigate = useNavigate();
  const toShop = () => navigate(WebAppRoutes.REWARDS_SHOP);
  const toCatalog = () => navigate(WebAppRoutes.COURSES);
  const coins = useAppSelector(selectProfileCoinsDetails);

  const shopSettings = useAppSelector(selectShopSettings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getShopSettings()).then((res) => {
      // обработка ошибок
      if (res.payload.status === 200) {
        if (res.payload?.settings?.data?.shop?.enable) {
          dispatch(getProfileCoinsDetails());
        } else {
          navigate(WebAppRoutes.HOME);
        }
      } else {
        navigate(`/${res.payload.status}`);
      }
    });
  }, [dispatch, navigate]);

  return shopSettings ? (
    <PaperBox className={styles["wallet-widget"]}>
      <H3 className={styles["wallet-widget-title"]}>В вашем кошельке</H3>
      <BalanceRow className={styles["wallet-widget-balance"]} coins={coins.shop_coins} />
      {coins.club_points && coins.club_points.length > 0 ? (
        <span>Из них получено в клубах:</span>
      ) : null}
      <div className={styles["clubs-info"]}>
        {coins.club_points.map((club, i) => {
          return (
            <div key={club.club_id} className={styles["club-item"]}>
              <div className={styles["divider"]} />
              <ClubDetailsItem clubId={club.club_id} count={club.total_count} />
              {i === coins.club_points.length - 1 && <div className={styles["divider"]} />}
            </div>
          );
        })}
      </div>

      <p className={styles["wallet-widget-info"]}>
        Записывайтесь на курсы,
        <br></br>
        проходите обучение,
        <br></br>
        зарабатывайте и покупайте
        <br></br>в нашем <Link onClick={toShop}>Магазине наград</Link>
      </p>
      <Button
        className={styles["wallet-widget-button"]}
        size="big"
        color="primary"
        onClick={toCatalog}
      >
        Каталог курсов
      </Button>
    </PaperBox>
  ) : null;
};
