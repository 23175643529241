import { useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { Avatar } from "components/Avatar";
import { Badge } from "components/Badge";
import { CommentsIcon, CornerArrowIcon, DeleteIcon, EditIcon, InfoIcon } from "components/Icons";
import { useState } from "react";
import { numWord } from "../../../../../../app/utils";
import { dateTsToFormat } from "../../../../../../app/utils/date.util";
import { CircleIconButton } from "../../../../../../components/Buttons/CircleIconButton";
import { DeleteMessageModal } from "../../../../../../components/Modals/DeleteMessageModal";
import { useModal } from "../../../../../../components/ModalsContainer";
import { useAppDispatch } from "../../../../../../hooks/redux";
import { IMessage } from "../../redux/interfaces";
import { createMessage, deleteMessage, updateMessage } from "../../redux/thunk-actions";
import { QuestionForm } from "../QuestionForm";
import { MessageList } from "./MessageList";
import styles from "./Message.module.scss";
import { useGetAvatarQuery } from "app/store";

interface MessageProps {
  courseId: string;
  manager: boolean;
  message: IMessage;
  level?: number;
}

export const Message = ({ courseId, message, manager, level = 0 }: MessageProps) => {
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModal();

  const { data } = useGetAvatarQuery();

  const isMobile = useMediaQuery("(max-width: 767px)");

  const [showChildren, setShowChildren] = useState<boolean>(level === 0);
  const [isReply, setIsReply] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const toggleShowChildren = () => {
    setShowChildren((prev) => !prev);
  };

  const onEdit = () => {
    setIsEdit(true);
    setIsReply(false);
  };

  const onReply = () => {
    setIsReply(true);
    setIsEdit(false);
  };

  const onCancelEditOrReply = () => {
    setIsEdit(false);
    setIsReply(false);
  };

  const onSubmitNewMessage = (text: string) => {
    dispatch(createMessage({ course_id: courseId, text, parent: message.id }));
    onCancelEditOrReply();
    setShowChildren(true);
  };

  const onSubmitEditMessage = (text: string) => {
    dispatch(updateMessage({ course_id: courseId, text, message_id: message.id }));
    onCancelEditOrReply();
  };

  const openModalDeleteMessage = () => {
    openModal({
      content: (
        <DeleteMessageModal
          onConfirm={() => {
            dispatch(deleteMessage({ course_id: courseId, message_id: message.id }));
            closeModal();
          }}
        />
      ),
    });
  };

  const paddingForMessageList = (level: number) => {
    switch (level) {
      case 1:
      case 2:
        return 10;
      default:
        return 0;
    }
  };

  return (
    <div className={styles["message"]}>
      {!message.deleted && !isMobile && (
        <div className={styles["avatar-container"]}>
          <Avatar
            src={message.user?.avatar?.path || data?.path || ""}
            type="image"
            className={styles["avatar"]}
          />
        </div>
      )}

      <div className={styles["message-right"]}>
        {!message.deleted && !isEdit && (
          <div
            className={classNames(styles["message-block"], {
              [styles["message-block--current"]]:
                message.user?.current && process.env.REACT_APP_THEME !== "myAcademy",
              [styles["message-block--current-my-academy"]]:
                message.user?.current && process.env.REACT_APP_THEME === "myAcademy",
            })}
          >
            <div
              className={classNames(styles["title-block"], {
                [styles["title-block-desc"]]: !isMobile,
              })}
            >
              {!message.deleted && isMobile && (
                <div className={styles["avatar-container"]}>
                  <Avatar
                    src={message.user?.avatar?.path || data?.path || ""}
                    className={styles["avatar"]}
                  />
                </div>
              )}

              <div className={styles["header-container"]}>
                <div className={styles["fullname-container"]}>
                  <p className={styles["fullname"]}>{message.user?.fullname}</p>

                  {(message.user?.current || manager) && isMobile && (
                    <div className={styles["message-actions"]}>
                      {message.user?.current && (
                        <CircleIconButton onClick={onEdit}>
                          <EditIcon />
                        </CircleIconButton>
                      )}

                      {(message.user?.current || manager) && (
                        <CircleIconButton onClick={openModalDeleteMessage}>
                          <DeleteIcon />
                        </CircleIconButton>
                      )}
                    </div>
                  )}
                </div>

                {message.user?.manager && (
                  <Badge
                    color="dark"
                    className={classNames(styles["title-badge"], {
                      [styles["my-academy-title-badge"]]:
                        process.env.REACT_APP_THEME === "myAcademy",
                    })}
                  >
                    Администратор
                  </Badge>
                )}
              </div>

              {(message.user?.current || manager) && !isMobile && (
                <div className={styles["message-actions"]}>
                  {message.user?.current && (
                    <CircleIconButton onClick={onEdit}>
                      <EditIcon />
                    </CircleIconButton>
                  )}

                  {(message.user?.current || manager) && (
                    <CircleIconButton onClick={openModalDeleteMessage}>
                      <DeleteIcon />
                    </CircleIconButton>
                  )}
                </div>
              )}
            </div>

            <div className={styles["message-text"]}>{message.text}</div>
          </div>
        )}

        {isEdit && (
          <QuestionForm
            label="Изменение"
            text={message.text}
            withAvatar={false}
            onCancel={onCancelEditOrReply}
            onSubmit={onSubmitEditMessage}
          />
        )}
        {message.deleted && (
          <div
            className={classNames(styles["message-block"], styles["message-deleted"], {
              [styles["message-block--current"]]: message.user?.current,
            })}
          >
            <InfoIcon />
            <div className={styles["message-text"]}>Сообщение удалено.</div>
          </div>
        )}

        <div className={styles["info-block"]}>
          <div className={styles["info-block-text"]}>
            <p>
              Создано {dateTsToFormat(message.created_ts, true)}
              {message.updated_ts ? ", " : ""}
            </p>

            {!!message.updated_ts && <p>изменено {dateTsToFormat(message.updated_ts, true)}</p>}
          </div>
          <div className={styles["message-buttons"]}>
            {!message.deleted && (
              <div className={styles["info-block-btn"]} onClick={onReply}>
                Ответить
              </div>
            )}

            {!!message.children?.length && !showChildren && (
              <div className={styles["info-block-btn"]} onClick={toggleShowChildren}>
                <CommentsIcon /> Показать {message.children.length}{" "}
                {numWord(message.children.length, ["ответ", "ответа", "ответов"])}
              </div>
            )}
          </div>
        </div>

        {isReply && (
          <QuestionForm
            label="Ответить"
            className={styles["reply-form"]}
            onCancel={onCancelEditOrReply}
            onSubmit={onSubmitNewMessage}
          />
        )}

        {!!message.children?.length && showChildren && (
          <>
            <div className={styles["info-block"]}>
              <div className={styles["info-block-btn"]} onClick={toggleShowChildren}>
                <CornerArrowIcon /> Свернуть ответы
              </div>
            </div>
            <div style={{ paddingLeft: paddingForMessageList(level + 1) }}>
              <MessageList
                messages={message.children}
                manager={manager}
                level={level + 1}
                courseId={courseId}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
