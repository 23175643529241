import { useMediaQuery } from "hooks";
import { Button } from "../../../../../components/Buttons/Button";
import Container from "../../../../../components/Container/Container";
import { mediaQuerys } from "app/utils";

const TaskFooter = ({ onDone, disabled }: { onDone: () => void; disabled: boolean }) => {
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  return (
    <footer className="course-test-footer-end">
      <Container>
        <div
          className="course-test-footer-block"
          style={{ ...{ justifyContent: isMobile ? "center" : "flex-end" } }}
        >
          <div className="course-test-footer-prev"></div>
          <div className="course-test-footer-next">
            <Button
              size="big"
              color="primary"
              className="course-test-footer__button"
              onClick={onDone}
              disabled={disabled}
            >
              Завершить
            </Button>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default TaskFooter;
