import { CourseCompetence } from "pages/Courses/redux/interface";
import styles from "./CompetenciesCardItem.module.scss";

interface CompetenciesCardItemProps {
  index: number;
  allCompetentces: CourseCompetence[];
  opacity?: number[] | false;
}

const MIN_ITEM_SIZE = 20;

export const CompetenciesCardItem = ({
  index,
  allCompetentces,
  opacity,
}: CompetenciesCardItemProps): JSX.Element => {
  // шаг размера каждого элемента в мапе
  const setSize = MIN_ITEM_SIZE + (30 / (allCompetentces.length + 1)) * (index + 1);

  // шаг z-index каждого элемента в мапе
  const setZIndex = { ...(opacity && { zIndex: opacity[index] * 300 }) };

  // шаг opacity каждого элемента в мапе
  const setOpacity = { ...(opacity && { opacity: opacity[index] }) };

  // цвет фона по по признаку темы
  const setBackgroundColor = {
    backgroundColor: process.env.REACT_APP_THEME === "myAcademy" ? "#00AEFF" : undefined,
  };

  return (
    <>
      <div className={styles["item"]} style={setZIndex}>
        <div className={styles["item-inner"]}>
          <div
            className={styles["point"]}
            style={{
              width: `${setSize}px`,
              height: `${setSize}px`,
            }}
          >
            <div
              className={styles["point-layout"]}
              style={{
                ...setOpacity,
                ...setBackgroundColor,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
