import { avatarsApi } from "../avatars.api";

const getAvatarApi = avatarsApi.injectEndpoints({
  endpoints: (build) => ({
    getAvatar: build.query<{ id: number | null; path: string; type: null | string }, void>({
      query: () => ({
        url: "/avatar",
        method: "GET",
      }),
      providesTags: ["updateAvatar"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAvatarQuery } = getAvatarApi;
