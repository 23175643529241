import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectIsOpenConfirmModal } from "../redux/selectors";
import { closeConfirmModal } from "../redux/modalsSlice";
import { ModalCloseIcon } from "components/Svg/Icons";
import { H2 } from "components/Typography/Titles";
import { TextMain } from "components/Typography/Texts";
import { Button } from "components/Buttons";

interface ConfirmDialogProps {
  onConfirm?: () => void;
  title?: string;
  description?: string;
}

export const ConfirmModal = ({
  onConfirm = () => {},
  title = "",
  description = "",
}: ConfirmDialogProps) => {
  const dispatch = useAppDispatch();

  const isOpenConfirmModal = useAppSelector(selectIsOpenConfirmModal);

  const closeModal = () => dispatch(closeConfirmModal());

  const confirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <div className={isOpenConfirmModal ? "modal-wrapper modal-wrapper--active" : "modal-wrapper"}>
      <div className="modal-body modal-body-profile">
        <ModalCloseIcon className="modal__close" onClick={closeModal} />
        <div>
          <div className="modal-content">
            <div className="modal-text">
              <H2 className="modal-text__title">{title}</H2>
              <TextMain className="modal-text__descr">{description}</TextMain>
            </div>
          </div>
          <div className="modal-buttons">
            <Button size="big" className="modal__button" color="primary" onClick={confirm}>
              Завершить
            </Button>
            <Button size="big" className="modal__button" onClick={closeModal}>
              Отмена
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
