import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import api from "../../../services/api/api";
import { EndPoints } from "../../../app/endpoints";
import {
  IProfileRatingResponse,
  IRatingRequest,
  IRatingResponse,
  IRatingTopRequest,
  IRatingTopResponse,
  PeriodHeaders,
  RatingState,
} from "./interfaces";

const name = "rating";

const initialState: RatingState = {
  isLoading: false,
  paging: {
    hasMore: false,
    total: 0,
    offset: 0,
    limit: 30,
  },
  profileList: [],

  activeRating: {
    isLoading: true,
    title: "",
    profileList: [],
  },
};

const getRating = createAsyncThunk(
  `${name}/getRating`,
  async (params: IRatingRequest, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.RATING, { params });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getRatingTop = createAsyncThunk(
  `${name}/getRatingTop`,
  async (params: IRatingTopRequest, thunkAPI) => {
    try {
      const response = await api.get(EndPoints.RATING_TOP, { params });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const getMyRating = createAsyncThunk(
  `${name}/getMyRating`,
  async (params: IRatingRequest, thunkAPI) => {
    try {
      // Получаем позицию
      const positionResponse: AxiosResponse<IProfileRatingResponse> = await api.get(
        EndPoints.PROFILE_RATING,
        {
          params: {
            my_workplace: params.my_workplace ? 1 : 0,
            period: params.period,
            club_id: params.club_id,
          },
        }
      );

      const myPosition = positionResponse.data.data.position;
      const levelName = positionResponse.data.data.level_name;
      const limit = (params.limit || 30) + myPosition;

      if (params.isFetchAllRatings) {
        const response = await api.get(EndPoints.RATING, {
          params: { ...params, limit },
        });
        return { ...response.data, myPosition, levelName };
      }

      return { myPosition, levelName };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        code: error.response.data.code,
        details: error.response.data.details,
      });
    }
  }
);

const ratingSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearRating(state) {
      state.profileList = [];
    },
    clearActiveRating(state) {
      state.activeRating.isLoading = false;
      state.activeRating.title = "";
      state.activeRating.profileList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRating.fulfilled, (state, action: PayloadAction<IRatingResponse>) => {
        state.isLoading = false;
        const paging = action.payload.paging;

        state.profileList =
          paging.offset > 0 ? [...state.profileList, ...action.payload.data] : action.payload.data;

        state.paging = {
          total: paging.total,
          limit: paging.limit,
          offset: paging.offset + paging.limit,
          hasMore: state.profileList?.length < paging.total,
        };
      })
      .addCase(getRating.rejected, (state) => {
        state.isLoading = false;
        state.profileList = [];
      });
    builder
      .addCase(getRatingTop.pending, (state) => {
        state.activeRating.isLoading = true;
      })
      .addCase(getRatingTop.fulfilled, (state, action: PayloadAction<IRatingTopResponse>) => {
        state.activeRating.isLoading = false;
        state.activeRating.title = "";
        state.activeRating.profileList = [];

        if (action.payload?.data)
          for (const key in PeriodHeaders) {
            // @ts-ignore
            const list = action.payload.data[key];
            if (list?.length > 0) {
              state.activeRating.title = PeriodHeaders[key as keyof typeof PeriodHeaders];
              state.activeRating.profileList = list;
              break;
            }
          }
      })
      .addCase(getRatingTop.rejected, (state) => {
        state.activeRating.isLoading = false;
        state.activeRating.title = "";
        state.activeRating.profileList = [];
      });
    builder
      .addCase(getMyRating.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getMyRating.fulfilled,
        (
          state,
          action: PayloadAction<IRatingResponse & { myPosition: number; levelName: string }>
        ) => {
          state.profileList = [];
          state.isLoading = false;
          const paging = action.payload?.paging;

          state.myPosition = action.payload.myPosition;
          state.levelName = action.payload.levelName;
          state.profileList = action.payload.data;

          state.paging = {
            ...state.paging,
            total: paging?.total || 0,
            offset: paging?.limit > paging?.total ? paging?.total || 0 : paging?.limit || 0,
            hasMore: state.profileList?.length < (paging?.total || 0),
          };
        }
      )
      .addCase(getMyRating.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearActiveRating, clearRating } = ratingSlice.actions;

export { getRating, getRatingTop, getMyRating };

const ratingReducer = ratingSlice.reducer;
export { ratingReducer };
