import { Avatar } from "../../../components/Avatar";
import { Skeleton } from "components/Skeleton";

interface ProfileAvatarProps {
  isLoading: boolean;
  srcImage: string;
  className?: string;
}

const ProfileAvatar = ({ isLoading, srcImage, className = "" }: ProfileAvatarProps) => {
  return isLoading ? (
    <Skeleton variant="circular" className="profile-avatar" />
  ) : (
    <Avatar src={srcImage} type="image" className={className} />
  );
};

export default ProfileAvatar;
