import classNames from "classnames";
import { Button } from "components/Buttons";
import { Tooltip } from "components/Tooltip";
import { useAppSelector } from "hooks/redux";
import { selectUserClubs } from "pages/Profile/redux/profile-slice/selectors/profile.selectors";
import {
  selectProfileCoinsDetails,
  selectShopClub,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import styles from "./BuyButton.module.scss";

interface BuyButtonProps {
  itemPrice?: number;
  clubId?: string;
  buttonClassName?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;

  tooltip?: boolean;
}

export const BuyButton = ({
  itemPrice,
  clubId,
  onClick,
  buttonClassName,
  tooltip,
}: BuyButtonProps) => {
  const coins = useAppSelector(selectProfileCoinsDetails);
  const clubs = useAppSelector(selectUserClubs);
  const club = useAppSelector(selectShopClub(clubId || ""));

  const inClub = clubs.some((x) => x.id === clubId);

  const currentCoins = clubId
    ? inClub
      ? coins.club_points.find((x) => x.club_id === clubId)?.total_count || 0
      : 0
    : coins.shop_coins;

  return clubId && !inClub ? (
    <p className={styles["need"]}>
      {tooltip ? (
        <Tooltip content={`Правила участия в клубе "${club?.name}"`} className={styles["tooltip"]}>
          <a
            href={club?.club_link}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            Вступай
            <br />в клуб
          </a>
        </Tooltip>
      ) : (
        <a
          href={club?.club_link}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          Вступай
          <br />в клуб
        </a>
      )}
    </p>
  ) : currentCoins >= (itemPrice || 0) ? (
    <Button
      color="primary"
      type="button"
      size="small"
      className={classNames(styles["button"], buttonClassName)}
      onClick={onClick}
    >
      Купить
    </Button>
  ) : (
    <p className={styles["need"]}>
      Нужно накопить <br></br>еще {itemPrice! - currentCoins}
    </p>
  );
};
