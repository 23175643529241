import { isAfter, isBefore, parseISO } from "date-fns";
import { selectUserCoursesIsLoaded } from "pages/My/redux/selectors";
import { useNavigate } from "react-router-dom";
import { WebAppRoutes } from "../../../../app/routes";
import { generateUid } from "../../../../app/utils";
import StudyPlug from "../../../../components/Plugs/StudyPlug";
import { StudyPlugPic } from "../../../../components/Svg/Pics";
import { useAppSelector } from "../../../../hooks/redux";
import { userCourse } from "../../redux/interface";
import { CourseCard } from "../CourseCard";
import { MyCoursesSkeleton } from "../MyCoursesSkeleton";
import { Capacitor } from "@capacitor/core";
import styles from "./MyCoursesGrid.module.scss";

export const MyCoursesGrid = ({ courses }: { courses: userCourse[] }) => {
  const navigate = useNavigate();
  const myCoursesIsLoaded = useAppSelector(selectUserCoursesIsLoaded);

  const openLinkInNewTab = (path: string) =>
    window.open(`${origin}${process.env.REACT_APP_BASE_PATH}${path}`, `tab_${Date.now()}`);

  const handlerClickCourseCard =
    ({
      id,
      end_dt,
      start_dt,
      time_limit_actual,
    }: {
      id: number;
      start_dt: string | null;
      end_dt: string | null;
      time_limit_actual?: number | null;
    }) =>
    () => {
      if (start_dt && end_dt) {
        const parsedCourseStart = parseISO(start_dt);
        const parsedCourseEnd = parseISO(end_dt);
        if (
          isAfter(parsedCourseStart, Date.now()) ||
          isBefore(parsedCourseEnd, Date.now()) ||
          (time_limit_actual !== null && time_limit_actual !== undefined && time_limit_actual === 0)
        ) {
          if (Capacitor.isNativePlatform()) {
            navigate(WebAppRoutes.COURSE_PREVIEW + "/" + id);
          }
          openLinkInNewTab(`${WebAppRoutes.COURSE_PREVIEW}/${id}`);
          return;
        }
      }

      if (
        time_limit_actual !== null &&
        time_limit_actual !== undefined &&
        time_limit_actual === 0
      ) {
        if (Capacitor.isNativePlatform()) {
          navigate(WebAppRoutes.COURSE_PREVIEW + "/" + id);
        }
        openLinkInNewTab(`${WebAppRoutes.COURSE_PREVIEW}/${id}`);
        return;
      }

      if (Capacitor.isNativePlatform()) {
        navigate(WebAppRoutes.COURSE + "/" + id);
        return;
      }
      openLinkInNewTab(`${WebAppRoutes.COURSE}/${id}`);
    };

  const handleClickCourses = () => {
    navigate(WebAppRoutes.COURSES);
  };

  return (
    <div className={styles["container"]}>
      {!myCoursesIsLoaded && (
        <div className={styles["courses-container"]}>
          <MyCoursesSkeleton />
        </div>
      )}
      {myCoursesIsLoaded && courses?.length > 0 && (
        <div className={styles["courses-container"]}>
          {courses.map((item) => (
            <CourseCard
              key={generateUid()}
              imageSrc={item.preview?.src}
              title={item.name}
              format={item.format}
              duration={item.duration}
              description={item.description}
              progress={item.progress}
              startDate={item.start_dt ? parseISO(item.start_dt) : null}
              onClick={handlerClickCourseCard({
                id: item.id,
                start_dt: item.start_dt,
                end_dt: item.end_dt,
                time_limit_actual: item.time_limit_actual,
              })}
            />
          ))}
        </div>
      )}
      {myCoursesIsLoaded && courses?.length === 0 && (
        <StudyPlug
          title="Вы пока не записались на курсы, а сегодня - прекрасный день, чтобы начать обучение"
          descr="Выберите курсы из каталога и начните обучение прямо сейчас!"
          pic={<StudyPlugPic />}
          buttonName={"Каталог курсов"}
          onClick={handleClickCourses}
        />
      )}
    </div>
  );
};
