import { useMediaQuery } from "@mui/material";
import { mediaQuerys } from "app/utils/utils";
import { Button } from "components/Buttons";
import { Link } from "components/Link";
import { WithAnimation } from "components/utils/utils";
import { AuthDataForm } from "pages/Authorization/data/data.types";
import { useAppSelector } from "hooks/redux";
import { authRequestIsLoading } from "features/auth/selectors";
import styles from "../../AuthorizationForm.module.scss";

interface FormButtonProps {
  form?: AuthDataForm;
}

export const FormButton = ({ form }: FormButtonProps): JSX.Element => {
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const isLoading = useAppSelector(authRequestIsLoading);

  return (
    <WithAnimation inCondition={!!form?.actions}>
      <div className={styles["controls"]}>
        <WithAnimation inCondition={!!form?.actions?.buttons?.primary}>
          <div>
            <Button
              color="primary"
              type="submit"
              size="big"
              className={styles["button"]}
              isLoading={isLoading}
              onClick={(event) => {
                if (form?.actions?.buttons?.primary?.action) {
                  const action = form.actions.buttons.primary.action;
                  if (typeof action === "function") {
                    form.actions.buttons.primary.action(event);
                  }
                }
              }}
            >
              <span>{form?.actions?.buttons?.primary?.title ?? ""}</span>
            </Button>
          </div>
        </WithAnimation>
        {!isMobile && (
          <WithAnimation inCondition={!!form?.actions?.buttons?.addition}>
            <div>
              <Link
                onClick={() => {
                  if (form?.actions?.buttons?.addition?.action) {
                    const action = form.actions.buttons.addition.action;
                    if (typeof action === "function") {
                      form.actions.buttons.addition.action();
                    }
                  }
                }}
              >
                {form?.actions?.buttons?.addition?.title ?? ""}
              </Link>
            </div>
          </WithAnimation>
        )}
      </div>
    </WithAnimation>
  );
};
