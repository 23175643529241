import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { EndPoints } from "../../../app/endpoints";
import { RootState } from "../../../app/store";
import api from "../../../services/api/api";
import {
  CourseCategory,
  CourseCompetence,
  CourseFormats,
  CourseList,
  CourseStatuses,
  CoursesListState,
  SearchParams,
} from "./interface";

const coursesListAdapter = createEntityAdapter<CourseList>({
  selectId: (course) => course.id,
});

const coursesCategoryAdapter = createEntityAdapter<CourseCategory>({
  selectId: (category) => category.id,
});

const coursesCompetenceAdapter = createEntityAdapter<CourseCompetence>({
  selectId: (competency) => competency.id,
});

const coursesStatusesAdapter = createEntityAdapter<CourseStatuses>({
  selectId: (statuses) => statuses.code,
});

const name = "coursesList";

const initialState: CoursesListState = {
  search: {
    input: "",
    inputPopup: "",
    categories: [],
    competences: [],
    format: [],
    enrolled_status: [],
    duration: [],
    isClerable: false,
    missGetCourses: false,
    keywords: [],
  },
  paging: {
    total: 0,
    offset: 0,
    limit: 10,
  },
  courses: coursesListAdapter.getInitialState({ isLoaded: false }),
  categories: coursesCategoryAdapter.getInitialState(),
  competences: coursesCompetenceAdapter.getInitialState(),
  formats: [],
  statuses: coursesStatusesAdapter.getInitialState(),
  durations: [
    { value: "1", label: "до 2 часов" },
    { value: "2", label: "3-6 часов" },
    { value: "3", label: "7-16 часов" },
    { value: "4", label: "более 17 часов" },
  ],
};

const getSearchCourses = createAsyncThunk<any, SearchParams | undefined>(
  `${name}/getSearchCourses`,
  async (params, thunkAPI) => {
    const response = await api.get(EndPoints.COURSES_LIST, {
      params: {
        course_status: "publish",
        query: params?.query || undefined,
        categories: params?.categories,
        format: params?.format,
        enrolled_status: params?.enrolled_status,
        competences: params?.competences,
        duration: params?.duration,
        keywords: params?.keywords,
        limit: params?.limit || 10,
        offset: params?.offset || 0,
      },
      signal: thunkAPI.signal,
    });
    return response.data;
  }
);

const getCoursesCategories = createAsyncThunk(
  `${name}/getCoursesCategories`,
  async (payload, thunkAPI) => {
    const response = await api.get(EndPoints.COURSES_CATEGORIES);
    return response.data;
  }
);

const getCourseStatuses = createAsyncThunk(
  `${name}/getCourseStatuses`,
  async (payload, thunkAPI) => {
    const response = await api.get(EndPoints.COURSE_STATUSES);
    return response.data;
  }
);

const getCoursesCompetences = createAsyncThunk(
  `${name}/getCoursesCompetences`,
  async (payload, thunkAPI) => {
    const response = await api.get(EndPoints.COMPETENCES);
    return response.data;
  }
);

const getCoursesFormats = createAsyncThunk(
  `${name}/getCoursesFormats`,
  async (payload, thunkAPI) => {
    const response = await api.get(EndPoints.COURSES_FORMATS);
    return response.data;
  }
);

const coursesListSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSearchInput(state, action) {
      state.search.input = action.payload;
      state.search.isClerable = false;
    },
    setSearchInputPopup(state, action) {
      state.search.inputPopup = action.payload;
    },
    setSearchCategory(state, action) {
      state.search.categories = action.payload;
      state.search.isClerable = false;
    },
    setSearchCompetence(state, action) {
      state.search.competences = action.payload;
    },
    setSearchFormats(state, action) {
      state.search.format = action.payload;
    },
    setSearchStatuses(state, action) {
      state.search.enrolled_status = action.payload;
    },
    setSearchKeywords(state, action) {
      state.search.keywords = action.payload;
    },
    setSearchDuration(state, action) {
      state.search.duration = action.payload;
    },
    setMissGetCourses(state, action) {
      state.search.missGetCourses = action.payload;
      state.search.isClerable = false;
    },
    clearSearch(state) {
      state.search.input = "";
      state.search.categories = [];
      state.search.isClerable = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCoursesCategories.fulfilled, (state, action) => {
        if (Array.isArray(action.payload?.data)) {
          coursesCategoryAdapter.setAll(state.categories, action.payload?.data);
        }
      })
      .addCase(getCourseStatuses.fulfilled, (state, action) => {
        if (Array.isArray(action.payload?.data)) {
          coursesStatusesAdapter.setAll(state.statuses, action.payload?.data);
        }
      })
      .addCase(getCoursesCategories.rejected, (state, action) => {})
      .addCase(
        getCoursesCompetences.fulfilled,
        (state, action: PayloadAction<{ data: CourseCompetence[] }>) => {
          if (Array.isArray(action.payload?.data)) {
            coursesCompetenceAdapter.setAll(state.competences, action.payload.data);
          }
        }
      )
      .addCase(getCoursesFormats.fulfilled, (state, action) => {
        state.formats = action.payload.data.map((format: CourseFormats) => {
          return { label: format.name, value: format.code };
        });
      })
      .addCase(getSearchCourses.pending, (state, action) => {
        state.courses.isLoaded = false;
      })
      .addCase(getSearchCourses.fulfilled, (state, action) => {
        if (Array.isArray(action.payload?.data)) {
          if (action.payload?.paging?.offset > 0) {
            coursesListAdapter.addMany(state.courses, action.payload?.data);
          } else {
            coursesListAdapter.setAll(state.courses, action.payload?.data);
          }
        }

        if (action.payload?.paging) {
          const { total_count, limit, offset } = action.payload.paging;
          state.paging.total = total_count ?? 0;
          state.paging.limit = limit ?? 10;
          state.paging.offset = offset ?? 0;
        }

        state.courses.isLoaded = true;
      })
      .addCase(getSearchCourses.rejected, (state, action) => {
        if (!action.meta.aborted) {
          state.courses.entities = {};
          state.courses.ids = [];
        }
        state.courses.isLoaded = false;
      });
  },
});

export const coursesListSelectors = coursesListAdapter.getSelectors(
  (state: RootState) => state.courses.courses
);
export const coursesCategorySelectors = coursesCategoryAdapter.getSelectors(
  (state: RootState) => state.courses.categories
);
export const coursesStatusesSelectors = coursesStatusesAdapter.getSelectors(
  (state: RootState) => state.courses.statuses
);
export const coursesCompetenceSelectors = coursesCompetenceAdapter.getSelectors(
  (state: RootState) => state.courses.competences
);

export const {
  setSearchInput,
  setSearchInputPopup,
  setSearchCategory,
  setSearchFormats,
  setSearchStatuses,
  setSearchKeywords,
  setSearchCompetence,
  setSearchDuration,
  clearSearch,
  setMissGetCourses,
} = coursesListSlice.actions;
export {
  getCourseStatuses,
  getCoursesCategories,
  getCoursesCompetences,
  getCoursesFormats,
  getSearchCourses,
};
export default coursesListSlice.reducer;
