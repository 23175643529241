import { FC } from "react";
import { H3 } from "../../../../../../components/Typography/Titles";
import { generateUid, mediaQuerys, numWord } from "../../../../../../app/utils";
import { Achievement } from "../../../../../../components/Achievement";
import { IAchievement } from "../../../../../Achievements/redux/interfaces";
import { AchievmentsTooltip } from "components/AchievmentsTooltip";
import { Capacitor } from "@capacitor/core";
import { useMediaQuery } from "hooks";
import styles from "./AchievementsList.module.scss";

interface AchievementsListProps {
  achievements: IAchievement[];
}

/**
 * Список наград на странице "BP_02_06 Окончание обучения"
 */
export const AchievementsList: FC<AchievementsListProps> = ({ achievements }) => {
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);
  console.log(achievements);
  return (
    <div className={styles["achievements"]}>
      <H3 className={styles["achievements__title"]}>
        {numWord(achievements?.length, ["Получен", "Получено", "Получено"])} {achievements?.length}{" "}
        {numWord(achievements?.length, ["бейдж", "бейджа", "бейджей"])}
      </H3>

      <div className={styles["achievements__list"]}>
        {achievements?.map((item, index) => (
          <>
            {Capacitor.isNativePlatform() || isMobile ? (
              <Achievement
                title={item.name}
                image={item.image}
                disabled={item.criteria?.every((value) => !value.received_dt)}
                color="white"
                description={item.description}
                showDescription
                index={index}
                criteria={item.criteria}
                descriptionClassName={styles["achievment-description"]}
              />
            ) : (
              <AchievmentsTooltip key={generateUid()} achievment={item}>
                <Achievement
                  title={item.name}
                  image={item.image}
                  disabled={item.criteria?.every((value) => !value.received_dt)}
                  color="white"
                />
              </AchievmentsTooltip>
            )}
          </>
        ))}
      </div>
    </div>
  );
};
