import { IQuizQuestion } from "../../../redux/Quze/interface";
import { QuestionsForRendering } from "../Questions";

interface getQuestionsByPositionProps {
  questions: IQuizQuestion[];
  position: number;
  currentOpenPage: number;
}

export const getQuestionsByPosition = ({
  questions,
  position,
  currentOpenPage,
}: getQuestionsByPositionProps): QuestionsForRendering[] => {
  if (position > 0) {
    const startSlice = (currentOpenPage - 1) * position;
    const endSlice = startSlice + position;
    return questions.slice(startSlice, endSlice).map((question) => {
      return {
        ...question,
        questionIndex: currentOpenPage,
      };
    });
  }

  return questions.map((question) => {
    return {
      ...question,
      questionIndex: currentOpenPage,
    };
  });
};
