import { H3 } from "../../../../../../../../../components/Typography/Titles";
import { TextMain } from "../../../../../../../../../components/Typography/Texts";
import { useSelector } from "react-redux";
import { QuestResultAlert } from "../QuestResultAlert";
import { IQuizeQuestionProp } from "../../../../../redux/Quze/interface/quiz.interface";
import { allAnswersSelector } from "../../../../../redux/Quze/courseQuizeSelectors";
import styles from "./QuestCalculateResult.module.scss";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";

export const QuestCalculateResult = ({
  title,
  description,
  questionsId,
  currentQuizeResult,
}: IQuizeQuestionProp) => {
  const allAnswers = useSelector(allAnswersSelector);

  const findedAnwer: any = allAnswers.find((answer: any) => answer.id === questionsId);
  const rightClass =
    currentQuizeResult?.status === "Correct" ? " course-questions-bubble__item--right" : "";
  const wrongClass = !!findedAnwer && !rightClass ? " course-questions-bubble__item--misstake" : "";

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <TextMain className="course-questions-block__descr"></TextMain>
      <div
        className="course-questions-block-wrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="course-questions-row-item">
          <div
            className="course-questions-row-item__text"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <span className={"course-questions-bubble__item" + rightClass + wrongClass}>
            {findedAnwer ? findedAnwer?.answer : null}
          </span>
        </div>
        <div className={styles["right-answers-wrapper"]}>
          <TextMain className="course-questions-block-wrapper__title">Правильный ответ:</TextMain>
          <div className={styles["right-answer"]}>
            <CheckCircleOutlineOutlined />
            <div>
              {description.trim()}={(currentQuizeResult?.right as any)?.text}
            </div>
          </div>
        </div>
      </div>
      <QuestResultAlert
        right={
          currentQuizeResult?.status &&
          ["Correct", "Partially correct"].includes(currentQuizeResult?.status)
        }
        text={currentQuizeResult?.text || ""}
      />
    </div>
  );
};
