import moment from "moment";
import { TextMessages } from "./messages";
import { validateSnils } from "./utils";

// major.prod.debug
export const mobile_version = "10.4.13";

export const policyLink = "https://www.educationstudio.ru/data_policy";
export const adminEmail =
  process.env.REACT_APP_THEME !== "myAcademy" ? "info@educationstudio.ru" : "edu_support@lanit.ru";
export const emailValidationPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;
export const passwordChars = /^[a-zA-Z0-9!@#$%^&*]+$/;
export const nameChars = /^[a-zA-Zа-яА-ЯёЁ -]+$/;
export const positionChars = /^[a-zA-Zа-яА-ЯёЁ0-9 -]*$/;
export const phoneValidationPattern =
  /^(\+7|7|8)?\(?[0-9]{3}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
export const snilsValidationPattern = /^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{3}[\s]?[0-9]{2}$/;
export const dateValidationPattern = /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/;
export const isoDateValidationPattern =
  /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;
export const FIOValidationPattern = /^[a-zA-Zа-яёА-ЯЁ –\-\s]+$/;
export const FILE_MAX_SIZE = 3145728;
export const FILE_ALLOWED_TYPE =
  "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document," +
  "application/epub+zip,application/vnd.oasis.opendocument.text,image/jpeg,image/png," +
  "application/pdf, application/rtf, image/bmp, image/gif, image/png, image/svg+xml," +
  "image/x-pict, application/vnd.oasis.opendocument.text-web, application/postscript, image/tiff," +
  "application/vnd.oasis.opendocument.text-template";

export const headerMenuData = [
  {
    label: "Мое обучение",
    path: "/",
  },
  {
    label: "Каталог",
    path: "/courses",
  },
];

export const profileDetailFormGroup = [
  ["lastname", "firstname", "middlename"],
  ["workplace_id"],
  ["mobile_phone", "gender", "citizenship_id", "snils", "birthdate"],
  ["residence_address"],
];

export const profileTableInfoFields = ["snils", "citizenship_id", "residence_address", "birthdate"];

export const profileEditFormData = {
  firstname: {
    name: "firstname",
    label: TextMessages.LABEL_FIRSTNAME,
    inputProps: {
      maxLength: 25,
    },
    rules: {
      required: TextMessages.ERROR_FIRSTNAME_REQUIRED,
      minLength: {
        value: 2,
        message: TextMessages.ERROR_FIRSTNAME_SHORT,
      },
      pattern: {
        value: FIOValidationPattern,
        message: TextMessages.ERROR_FIRSTNAME_INCORRECT,
      },
    },
    type: "text",
  },
  middlename: {
    name: "middlename",
    label: TextMessages.LABEL_MIDDLENAME,
    type: "text",
    inputProps: {
      maxLength: 50,
    },
    rules: {
      minLength: {
        value: 2,
        message: TextMessages.ERROR_MIDDLENAME_SHORT,
      },
      pattern: {
        value: FIOValidationPattern,
        message: TextMessages.ERROR_MIDDLENAME_INCORRECT,
      },
    },
  },
  lastname: {
    name: "lastname",
    label: TextMessages.LABEL_LASTNAME,
    type: "text",
    inputProps: {
      maxLength: 50,
    },
    rules: {
      minLength: {
        value: 2,
        message: TextMessages.ERROR_LASTNAME_SHORT,
      },
      pattern: {
        value: FIOValidationPattern,
        message: TextMessages.ERROR_LASTNAME_INCORRECT,
      },
    },
  },
  birthdate: {
    name: "birthdate",
    label: TextMessages.LABEL_BIRTHDATE,
    rules: {
      validate: (data) => {
        const checkDate = moment(data).format("DD.MM.yyyy");
        if (!dateValidationPattern.test(checkDate)) {
          return TextMessages?.ERROR_BIRTHDATE_INCORRECT;
        }
      },
    },
    type: "datepicker",
    inputProps: {
      placeholder: null,
    },
    mask: "__.__.____",
    maxDate: new Date(),
  },
  gender: {
    name: "gender",
    label: TextMessages.LABEL_GENDER,
    rules: "",
    type: "select",
    refbook: "gender",
  },
  email: {
    name: "email",
    label: "Почта",
    type: "text",
  },
  mobile_phone: {
    name: "mobile_phone",
    label: TextMessages.LABEL_PHONE,
    rules: {
      pattern: {
        value: phoneValidationPattern,
        message: TextMessages.ERROR_MOBILE_PHONE_INCORRECT_FORMAT,
      },
    },
    mask: { mask: "+7(000) 000-00-00" },
    maskChar: " ",
    type: "text",
  },
  workplace_id: {
    name: "workplace_id",
    label: TextMessages.LABEL_WORKPLACE,
    type: "select",
    rules: "",
    refbook: "workplace",
  },
  citizenship_id: {
    name: "citizenship_id",
    label: TextMessages.LABEL_CITIZENSHIP,
    rules: "",
    type: "select",
    refbook: "citizenship",
  },
  residence_address: {
    name: "residence_address",
    label: TextMessages.LABEL_RESIDENCE_ADDRESS,
    inputProps: {
      maxLength: 500,
    },
    rules: {
      minLength: {
        value: 10,
        message: TextMessages.ERROR_RESIDENCE_ADDRESS_SHORT,
      },
    },
    type: "textarea",
  },
  snils: {
    name: "snils",
    label: TextMessages.LABEL_SNILS,
    mask: { mask: "000-000-000 00" },
    maskChar: " ",
    type: "text",
    rules: {
      pattern: {
        value: snilsValidationPattern,
        message: TextMessages.ERROR_SNILS_INCORRECT_FORMAT,
      },
      validate: (data) => {
        if (data === "") {
          return true;
        }
        const snils = data.toString().replace(/\D/g, "");
        const isValidSnils = validateSnils(snils);
        if (!isValidSnils) {
          return TextMessages?.ERROR_SNILS_INCORRECT;
        }
        if (!parseInt(snils)) {
          return TextMessages?.ERROR_SNILS_INCORRECT;
        }
        return true;
      },
    },
  },
};
