import { AxiosError, AxiosRequestConfig } from "axios";
import API from "../../services/api/api";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";

interface AxiosBaseQueryArgs {
  url: string;
  method: AxiosRequestConfig["method"];
  body?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  headers?: AxiosRequestConfig["headers"];
}

export const axiosBaseQuery: BaseQueryFn<AxiosBaseQueryArgs, unknown, unknown> = async ({
  url,
  method,
  body,
  params,
  headers,
}) => {
  try {
    const result = await API({
      url,
      method,
      data: body,
      params,
      headers,
    });
    return result.data;
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};
