import { getProfileCoins } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { selectDrawerOpened } from "../../features/mainMenu/selectors";
import { changeDrawerOpened } from "../../features/mainMenu/slice";
import { CircleIconButton } from "../Buttons/CircleIconButton";
import { CloseSvgIcon, HamburgerSvgIcon } from "../Svg/Icons";
import styles from "./Hamburger.module.scss";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { selectLevelNameMyPosition } from "pages/Rating/redux/selectors";
import { selectProfileDetails } from "pages/Profile/redux/profile-slice/selectors/profile.selectors";
import { UserRating } from "../UserRating";
import { UserWallet } from "../UserWallet";
import { getMyRating } from "pages/Rating/redux/rating.slice";
import { useMediaQuery } from "hooks";
import { mediaQuerys } from "app/utils";
import { Capacitor } from "@capacitor/core";

const Hamburger = () => {
  const drawerOpened = useAppSelector(selectDrawerOpened);
  const shopSettings = useAppSelector(selectShopSettings);
  const levelName = useAppSelector(selectLevelNameMyPosition);
  const dispatch = useAppDispatch();
  const { points } = useAppSelector(selectProfileDetails);

  const isMobile =
    useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`) || Capacitor.isNativePlatform();

  const isShowUserWallet =
    shopSettings?.enable && !(process.env.REACT_APP_THEME === "myAcademy" && isMobile);

  const handleCLick = () => {
    dispatch(changeDrawerOpened(!drawerOpened));
  };

  useEffect(() => {
    dispatch(getProfileCoins());
    dispatch(
      getMyRating({
        my_workplace: false,
        period: "all",
        isFetchAllRatings: false,
      })
    );
  }, [dispatch]);

  return (
    <div className={styles["hamburger"]}>
      <>
        <div className="profile-widget__user-rating">
          {levelName && <UserRating levelName={levelName} points={points} />}
        </div>
        {isShowUserWallet && (
          <div className="profile-widget__user-wallet">
            <UserWallet />
          </div>
        )}
      </>

      <CircleIconButton
        className="hamburger"
        size="hamburger"
        color="transparent"
        onClick={handleCLick}
      >
        {drawerOpened ? <CloseSvgIcon /> : <HamburgerSvgIcon />}
      </CircleIconButton>
    </div>
  );
};

export default Hamburger;
