import styles from "./CustomDrawer.module.scss";
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import useUnmount from "ahooks/lib/useUnmount";
import { useClickAway } from "ahooks";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";

interface IDrawerProps {
  open?: boolean;
  className?: string;
  onClose: () => void;
  children?: ReactNode | ReactElement;
}

export const CustomDrawer = ({ open = false, className, onClose, children }: IDrawerProps) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [canCloseOnClickAway, setCanCloseOnClickAway] = useState<boolean>(false);
  useUnmount(() => {
    if (open) {
      onClose();
    }
  });

  useEffect(() => {
    setCanCloseOnClickAway(open);
  }, [open]);

  useClickAway(() => {
    if (canCloseOnClickAway) {
      onClose();
    }
  }, [bodyRef]);

  return (
    <CSSTransition
      classNames={{
        enterDone: styles["wrap_enter-done"],
        exit: styles["wrap_exit-done"],
      }}
      timeout={100}
      in={open}
      mountOnEnter
      unmountOnExit
      onExited={() => onClose()}
    >
      <div className={styles["wrap"]}>
        <div className={styles["overlay"]}></div>
        <div
          className={classNames(styles["body"], {
            [styles["body_mobile"]]: Capacitor.isNativePlatform(),
          })}
          ref={bodyRef}
        >
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};
