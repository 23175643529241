import { СompetenciesTypeData } from "components/CompetenciesCard/competancies.types";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import { CourseFormats, CourseStatuses } from "pages/Courses/redux/interface";
import { IAchievement } from "../../../Achievements/redux/interfaces";

export interface CourseState {
  isLoading: boolean;
  options: {
    isBottomNav: boolean;
    isAsideNav: boolean;
    courseMenuIsOpen: boolean;
  };
  courseResult: {
    isFinish: boolean;
    start_ts: number | null;
    end_ts: number | null;
    grade: number | null;
    days: number | null;
  };
  isLoadingCourseLesson: boolean;
  pageBeforeCourse: string;
  activeLessonId: number;
  activeContentId: number | undefined;
  scores: any;
  achievements: IAchievement[];
  competence: СompetenciesTypeData | [];
  // Траектории обучения, в которые входит курс
  summary?: CourseSummary;
  content: CourseContent[];
  feedbacks: CourseFeedbacksResponse;
  feedbacksIsLoading: boolean;
  multicourseContent?: { courses: MulticourseContent[]; courses_count: number | null };
  // Баллы, которые можно получить за выполнение курса и/или уроков/связанных курсов
  coins?: CourseCoinsData;
  pollUrl?: string;
  keywordsCoursesList: KeywordType[];
  // Доступность сертификата
  certificateAvailability?: boolean;
  certificateDownloadAvailability?: boolean;
}

export enum CourseFormat {
  // Курс состоит из нескольких уроков
  course = "course",
  // Курс состоит из нескольких курсов (Траектория обучения)
  multicourse = "multicourse",
  // Курс из 1 элемента - Задание
  task = "task",
  // Курс из 1 элемента - Интерактивная книга
  atb = "atb",
  // Курс из 1 элемента - Вебинар
  meeting = "meeting",
  // Курс из 1 элемента - Анкета
  questionnaire = "questionnaire",
  // Курс из 1 элемента - Лонгрид
  page = "page",
  // Курс из 1 элемента - Тест
  quiz = "quiz",
  // Курс из 1 элемента - SCORM (Тренажер)
  trainer = "trainer",
}

export interface ICourse {
  id: number;
  author: CourseAuthorProps;
  category_id: number | null;
  category_name: string | null;
  comments_count: number | null;
  course_status: string | null;
  course_type: string | null;
  description: string;
  do_not_match_students: boolean | null;
  // Длительность в секундах
  duration: number;
  end_dt: string | null;
  enrolled_ts: number | null;
  finished_ts: number | null;
  lessons: number[];
  name: string;
  passed_count: number | null;
  personal_data_processing: boolean | null;
  preview: {
    name: string | null;
    contentType: string | null;
    src: string | null;
    size: number | null;
  };
  rating: number | null;
  sections: number[];
  self_enrollment: boolean | null;
  short_name: string | null;

  start_dt: string | null;
  stats: {
    hours: number | null;
    tasks: number | null;
    videos: number | null;
    quizes: number | null;
  };
  units: number[] | null;
  visible: boolean;
  workplace_id: number | null;
  workplace_name: string | null;
  // Пользователь прошел данный курс, но еще нет оценки, которую ранее оставил студент, иначе false
  commenting: boolean;
  format: CourseFormat;
  time_limit_total: number | null;
  time_limit_actual: number | null;
}

export interface CourseAuthorProps {
  name: string | null;
  title: string | null;
  intro: string | null;
  photo: string | null;
  company: string | null;
}

export interface CourseSecton {
  id: number;
  title: string;
  description: string;
  lessons: number[];
}

export interface CourseLesson {
  id: number;
  description: string;
  end_ts: number | null;
  attempt_id?: number | null;
  preconditions: number[];
  start_ts: number | null;
  status: string | null;
  status_ts: number | null;
  time_to_complete: number | null;
  title: string;

  type: string;
  required: boolean;
  // Баллы за урок
  coins?: CourseCoin;
}

export interface CourseLessonTrainer extends CourseLesson {
  attempt_status: string | null;
  frame_url?: string | null;
  total_attempts: number;
  user_attempts: number;
  required_score: number;
}

export interface CourseLessonText extends CourseLesson {
  page_type: string;
}

export interface CourseLessonTask extends CourseLesson {
  attempt_status: string | null;
  description_show: boolean;
  task_type: string | null;
  total_attempts: number;
  user_attempts: number;
  complate_task: string;
  files: {
    name: string | null;
    patch: string | null;
    type: string | null;
    size: number | null;
  };
  character_count: number | null;
  files_count: number;
  file_types: string | null;
  comments: CourseLessonTaskComment[];
  comments_author: CourseLessonTaskMentor;
}

export interface CourseLessonTaskComment {
  content: string;
  date: number;
}

export interface CourseLessonTaskMentor {
  avatar_id: string | null;
  email: string | null;
  user_id: number | null;
  username: string | null;
}

export interface CourseLessonQuiz extends CourseLesson {
  attempt_status: string;
  time_to_complete: number | null;
  order_type: string;
  start_ts: number | null;
  end_ts: number | null;
  total_attempts: number;
  user_attempts: number;
  status_ts: number | null;
  time_spent: number | null;
}

export interface CourseLessonQuestionary extends CourseLesson {
  questions_length: number;
  attempt_status: string;
  passed: boolean;
  questions: CourseLessonQuestionaryQuestion[];
}

export interface CourseLessonBook extends CourseLesson {
  url: string;
}

export interface CoursePagination {
  id: number;
  lessonId: number;
  sectionId: number;
  sectionTitle: string;
  unitTitle: string;
  link: string;
}

export interface CourseLessonQuestionaryQuestion {
  id: string | number;
  title: string;
  type: string;
  order: number;
  required: boolean;
  answer: [];
  valid: boolean;
  multiselect: boolean;
  min: number;
  max: number;
  options: CourseLessonQuestionaryQuestionOption[];
}

export interface CourseLessonQuestionaryQuestionOption {
  label: string;
  value: string;
  explanation: string;
}

// Входящие параметры POST /courses/{id}/feedback
export interface CourseFeedbackRequest {
  // Идентификатор курса
  course_id: string;
  // Оценка курса. Допустимые значения от 1 до 5
  score: number;
  // Отзыв к курсу
  text?: string;
}

export interface MultiCourse {
  // Идентификатор траектории обучения
  id: number;
  // Название траектории обучения
  name: string;
  // Начало траектории обучения
  start_ts?: number;
  // Окончание траектории обучения
  end_ts?: number;
  // Описание траектории обучения
  description?: string;
  // Рейтинг траектории обучения
  rating?: number;
  // Количество прохождений
  passed_count?: number;
  // Количество отзывов
  comments_count?: number;
  // Картинка траектории обучения
  preview?: string;
  // Баллы за выполнение курса. Заполняется, если предусмотрено (траектория, курс, курс с 1 элементом)
  coins?: CourseCoin;
  // Сумма баллов за выполнение связанных курсов. Если предусмотрено (только для траектории)
  linked_courses?: CourseCoin;
}

// Выходные данные GET /courses/{id}/summary
export interface CourseSummaryResponse {
  data?: CourseSummary;
}

export interface CourseSummary {
  multicourses?: MultiCourse[];
  // Ограничение по времени. Заполняется для курса с форматом "Тест"
  time_limit?: number | null;
  // Количество попыток. Заполняется для курса с форматом "Тест"
  attempts?: number | null;
  // Количество вопросов.  Заполняется для курса с форматом "Тест", "Анкета"
  questions?: number | null;
  // Проходной балл. Заполняется для курса с форматом "Тест"
  passing_score?: number | null;
  // Дата и время, до которой необходимо завершить задание в курсе. Заполняется для курса с форматом "Тест", "Задание"
  finish_ts?: number | null;
  // Заполняется true, если предусмотрена обратная связь. Заполняется для курса с форматом "Задание"
  commenting?: boolean | null;
  // Заполняется true, если понадобиться камера. Заполняется для курса с форматом "Вебинар"
  camera?: boolean | null;
  // Заполняется true, если понадобиться микрофон. Заполняется для курса с форматом "Вебинар"
  microphone?: boolean | null;
  // Заполняется true, если предусмотрена запись вебинара. Заполняется для курса с форматом "Вебинар"
  record?: boolean | null;
}

export interface CourseContentResponse {
  data: CourseContent[];
}

export interface CourseContent {
  id: number;
  title: string;
  description?: string;
  lessons: CourseContentLesson[];
}

export interface CourseContentLesson {
  description?: string;
  id: number;
  preconditions: any[];
  status: CourseStatuses;
  title: string;
  type: CourseFormats;
  // Баллы за урок
  coins?: CourseCoin;
}

export interface CourseFeedbacksResponse {
  paging: {
    total: number;
    offset: number;
    limit: number;
  };
  data: FeedbackMessage[];
}

export interface FeedbackMessage {
  id: number;
  enrolled_status: EnrolledStatus;
  rating: number;
  user_name: string;
  text: string;
  created_ts: number;
  user: {
    id: number;
    fullname: string;
    avatar: {
      id: number | null;
      path: string | null;
    };
    current: true;
  };
}

export interface MulticourseContentResponse {
  data?: { courses: MulticourseContent[]; courses_count: number | null };
}

export interface MulticourseContent {
  id: number;
  name: string;
  format: CourseFormat;
  description: string;
  status?: EnrolledStatus.completed;
  grade?: number;
  start_ts: number;
  duration?: string;
  preview?: string;
  enrolled?: boolean;
  // Баллы за прохождение курса
  coins?: CourseCoin;
}

export interface CourseCoinsData {
  // Баллы за выполнение курса. Заполняется, если предусмотрено (траектория, курс, курс с 1 элементом)
  course?: CourseCoin;
  // Сумма баллов за выполнение всех уроков данного курса. Заполняется, если предусмотрено (только для курса, курса с 1 элементом)
  lessons?: CourseCoin;
  // Сумма баллов за выполнение связанных курсов. Если предусмотрено (только для траектории)
  linked_courses?: CourseCoin;
}

export interface CourseCoin {
  // Количество баллов
  amount: number;
  // Если баллы получены, то true, иначе, если не получены, то false
  received: boolean;
}

export interface KeywordType {
  id: number;
  name: string;
  parent_id?: number | null;
  visible?: boolean;
  courses_count?: number | null;
}

export interface ViewedVideoInfo {
  name: string;
  time: number;
}

export interface ViewedInfoRequest {
  position?: number;
  time?: number;
  videos?: string;
}

export interface ViewedInfoData {
  position?: number;
  time?: number;
  videos?: ViewedVideoInfo[];
  required_time?: number;
}

export interface ViewedInfoResponse {
  data: {
    required_time?: number;
    position?: number;
    time?: number;
    videos?: string;
  };
}
