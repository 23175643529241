import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getClub } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { selectShopClub } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect, useState } from "react";

export const useClub = (clubId: string | undefined) => {
  const club = useAppSelector(selectShopClub(clubId || ""));
  const dispatch = useAppDispatch();
  const [badgeSrc, setBadgeSrc] = useState<string>();

  useEffect(() => {
    if (!clubId) return;

    dispatch(getClub(clubId));
  }, [dispatch, clubId]);

  useEffect(() => {
    if (!club?.club_icon) return;

    const img = new Image();
    img.src = club.club_icon;
    img.onload = () => {
      setBadgeSrc(club.club_icon);
    };
  }, [club?.club_icon]);

  return { ...club, club_icon: badgeSrc };
};
