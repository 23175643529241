import courseFinished from "assets/img/course-finished.svg";
import courseNotFinished from "assets/img/course-not-finished.svg";
import myAcademyCourseNotFinished from "assets/img/myacademy-course-not-finished.svg";
import myAcademyCourseFinished from "assets/img/myacademy_course-finished.svg";
import classNames from "classnames";
import { Text } from "components/Text";
import styles from "./CourseWithoutReports.module.scss";

interface CourseWithoutReportsProps {
  isFinished?: boolean;
}

const DescriptionText = () => (
  <>
    Для данного курса не предусмотрено оценочных заданий.
    <br />
    Главное - ознакомиться со всеми материалами, после этого курс будет автоматически считаться
    завершенным.
  </>
);

export const CourseWithoutReports = ({ isFinished = false }: CourseWithoutReportsProps) => (
  <>
    <Text className={styles["no-reports__description"]}>
      <DescriptionText />
    </Text>
    <img
      src={
        process.env.REACT_APP_THEME === "myAcademy"
          ? isFinished
            ? myAcademyCourseFinished
            : myAcademyCourseNotFinished
          : isFinished
          ? courseFinished
          : courseNotFinished
      }
      alt="courseNotFinishedImage"
      className={classNames(styles["no-reports__image"], {
        [styles["no-reports__image_my_academy"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
    />
  </>
);
