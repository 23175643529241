import { useAppSelector } from "hooks/redux";
import { questionsSelectors } from "../../redux/Quze/courseQuizeQuestionsSlice";
import {
  selectCoursePosition,
  selectCurrentOpenPageInQuiz,
} from "../../redux/Quze/courseQuizeSelectors";
import { Question } from "../Question";
import { getQuestionsByPosition } from "./functions";
import { useEffect, useState } from "react";
import { useDebounceFn } from "ahooks";
import { IQuizQuestion } from "../../redux/Quze/interface";

interface QuestionsProps {
  lessonId: string;
  handleChange?: (option: {
    quizeId: string | number;
    questionId: string | number;
    attemptId: number;
    answer: any;
  }) => void;
}

export interface QuestionsForRendering extends IQuizQuestion {
  questionIndex: number;
}

export const Questions = ({ lessonId, handleChange }: QuestionsProps) => {
  const [questionsForRendering, setQuestionsForRendering] = useState<QuestionsForRendering[]>([]);

  const questions = useAppSelector(questionsSelectors.selectAll);
  const position = useAppSelector(selectCoursePosition);
  const currentOpenPage = useAppSelector(selectCurrentOpenPageInQuiz);

  const { run: handleOnChange } = useDebounceFn(
    () => {
      setQuestionsForRendering(
        getQuestionsByPosition({
          questions,
          position,
          currentOpenPage,
        })
      );
    },
    {
      wait: 300,
    }
  );

  useEffect(() => {
    handleOnChange();
  }, [questions, position, currentOpenPage, handleOnChange]);

  return (
    <div style={{ marginBottom: 200 }}>
      {questionsForRendering?.map((question, index) => (
        <Question
          key={question.id}
          quest={question}
          questIndex={question.questionIndex + index}
          lessonId={lessonId}
          handleChange={handleChange}
        />
      ))}
    </div>
  );
};
