import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import Alert from "components/Alert/Alert";
import { ScormMyAcademyIllustration1 } from "components/Illustrations";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect } from "react";
import { mediaQuerys, normalizeTimestampDate, numWord } from "../../../../app/utils";
import { Button } from "../../../../components/Buttons";
import Container from "../../../../components/Container/Container";
import CourseTestAchieve from "../../../../components/CourseTest/CourseTestAchieve";
import { CourseSimulateImg1 } from "../../../../components/Svg/Pics";
import { TextMain } from "../../../../components/Typography/Texts";
import { H1 } from "../../../../components/Typography/Titles";
import { useMediaQuery } from "../../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {
  getCourseTraining,
  getCourseTrainingResults,
  setCourseTrainingStart,
} from "../../redux/course-trainer.slice";
import { showAsideNav, showBottomNav } from "../../redux/courseSlice";
import { CourseLessonTrainer } from "../../redux/interfaces";
import { CourseCoin } from "../../redux/interfaces/course.interface";
import { AchievementsListLesson } from "../AchievementsListLesson";
import CourseTestContentHeader from "../CourseTestContentHeader";
import { LessonCompetenciesList } from "../LessonCompetenciesList";
import { LessonDescription } from "../LessonDescription";
import { TrainingIllustration, TrainingMyAcademyIllustration } from "./components";

const CourseTrainingStart = ({
  lesson,
  results = [],
  coins,
}: {
  lesson: CourseLessonTrainer;
  results: any[];
  coins?: CourseCoin;
}) => {
  const dispatch = useAppDispatch();

  const smMin = useMediaQuery(`(min-width:${mediaQuerys.smMin}px)`);
  const isMobile = useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`);

  const countAttempts = lesson.total_attempts - lesson.user_attempts;
  const selectedLmsSettings = useAppSelector(lmsSettings);

  useEffect(() => {
    (async () => {
      await Promise.all([
        dispatch(getCourseTraining(lesson.id)),
        dispatch(getCourseTrainingResults(lesson.id)),
      ]);
    })();
    dispatch(showBottomNav());
    dispatch(showAsideNav());
  }, [dispatch, lesson.id]);

  const alertText =
    lesson.start_ts && new Date(+lesson.start_ts * 1000) > new Date()
      ? `Дата начала урока: ${normalizeTimestampDate(lesson.start_ts)}`
      : lesson.end_ts && new Date(+lesson.end_ts * 1000) < new Date()
      ? `Прохождение урока не доступно с ${normalizeTimestampDate(lesson.end_ts)}`
      : lesson.total_attempts > 0 && !(countAttempts > 0)
      ? "У вас закончились попытки"
      : "";

  const isEnableCompetenciesInEsMobile =
    selectedLmsSettings.competences &&
    Capacitor.isNativePlatform() &&
    isMobile &&
    process.env.REACT_APP_THEME === "base";

  return (
    <>
      <Container className="course-test-block">
        <div className="course-test-content">
          <CourseTestContentHeader status={lesson.status} coins={coins}>
            <H1 className="course-test-top__title">{lesson.title}</H1>
          </CourseTestContentHeader>

          <AchievementsListLesson />

          <LessonDescription stringHTML={lesson?.description} />

          {lesson?.required_score > 0 ? (
            <TextMain className="course-test__descr course-simulate__descr">
              <p>
                Для успешного завершения тренажера нужно набрать{" "}
                {lesson.required_score < 100 ? "больше " : ""}
                {lesson.required_score}% правильных ответов.
              </p>
              {lesson.end_ts ? <p>Поздравляем, вы справились!</p> : null}
            </TextMain>
          ) : null}

          {(selectedLmsSettings.competences || isEnableCompetenciesInEsMobile) && (
            <LessonCompetenciesList id={lesson.id} />
          )}

          {results?.length > 0 ? (
            <>
              <CourseTestAchieve results={results} />
              {lesson.total_attempts > 0 && (
                <>
                  {smMin && countAttempts > 0 && (
                    <TextMain className="course-test-achieve__impetus">
                      Вы можете улучшить свой результат, пройдя тренажер еще раз.
                    </TextMain>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {!smMin && !Capacitor.isNativePlatform() && (
                <>
                  {process.env.REACT_APP_THEME !== "myAcademy" && (
                    <TrainingIllustration className="course-test__training-illustration" />
                  )}
                  {process.env.REACT_APP_THEME === "myAcademy" && (
                    <TrainingMyAcademyIllustration className="course-test__training-illustration_my-academy" />
                  )}
                </>
              )}
            </>
          )}

          <div className="course-test-buttons">
            <>
              {alertText ? (
                <Alert>{alertText}</Alert>
              ) : (
                <>
                  {(lesson.total_attempts === 0 || countAttempts > 0) && (
                    <Button
                      size="big"
                      color="primary"
                      className="course-test__button"
                      onClick={() => {
                        dispatch(setCourseTrainingStart(lesson.id));
                      }}
                    >
                      {lesson.attempt_status === "started" ? "Продолжить попытку" : "Начать"}
                    </Button>
                  )}
                </>
              )}
              {lesson.total_attempts > 0 && countAttempts > 0 && (
                <TextMain className="course-test__attempt">
                  {numWord(countAttempts, ["Осталась", "Осталось", "Осталось"])} {countAttempts}{" "}
                  {numWord(countAttempts, ["попытка", "попытки", "попыток"])}
                </TextMain>
              )}
            </>
          </div>
        </div>

        <div
          className={classNames("course-test-img course-simulate-img", {
            "course-test-img_other-translateX": process.env.REACT_APP_THEME !== "myAcademy",
            "course-simulate-img_my-academy": process.env.REACT_APP_THEME === "myAcademy",
          })}
          style={{
            left: process.env.REACT_APP_THEME !== "myAcademy" ? "700px" : undefined,
          }}
        >
          {process.env.REACT_APP_THEME === "myAcademy" && <ScormMyAcademyIllustration1 />}
          {process.env.REACT_APP_THEME !== "myAcademy" && <CourseSimulateImg1 />}
        </div>
      </Container>
    </>
  );
};

export default CourseTrainingStart;
