import { Control, Controller, FieldValues } from "react-hook-form";
import { IQuizQuestionOption } from "../../../../../../redux/Quze/interface";
import { useEffect, useRef } from "react";
import { Select } from "components/Select";
import { Option } from "../../../../../../../../../../components/Select/types";
import classNames from "classnames";
import styles from "./QuestCorrelateRow.module.scss";

interface QuestCorrelateRowPops {
  item: IQuizQuestionOption;
  control: Control<FieldValues, any>;
  maxWidth: number;
  handleOnChange: () => void;
  selectOptions: Option[];
  defaultAnswers: any;
  setMaxWidth: (width: number) => void;
}

export const QuestCorrelateRow = ({
  item,
  control,
  maxWidth,
  handleOnChange,
  selectOptions,
  defaultAnswers,
  setMaxWidth,
}: QuestCorrelateRowPops): JSX.Element => {
  const leftColRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (leftColRef.current && rowRef.current) {
      const width = leftColRef.current.offsetWidth;
      const rowWidth = rowRef.current.offsetWidth;
      maxWidth < width && setMaxWidth(rowWidth / 2 < width ? rowWidth / 2 : width);
    }
  }, [maxWidth, setMaxWidth]);

  return (
    <div
      ref={rowRef}
      key={item.code}
      className="course-questions-select-item"
      style={{ minWidth: "100%" }}
    >
      <span
        className="course-questions-block-list__item "
        style={{ minWidth: `${maxWidth}px`, maxWidth: "50%" }}
      >
        <span
          ref={leftColRef}
          style={{ whiteSpace: "pre-wrap" }}
          className={
            /*@ts-ignore*/
            defaultAnswers?.answer?.[item.code]
              ? "course-questions-bubble__item course-questions-bubble__item_fluide course-questions-bubble__item--active"
              : "course-questions-bubble__item course-questions-bubble__item_fluide"
          }
        >
          {item.text}
        </span>
      </span>
      <Controller
        name={item.code}
        control={control}
        /*@ts-ignore*/
        defaultValue={defaultAnswers?.answer[item.code] || ""}
        render={({ field: { onChange, ...otherFields } }) => (
          <Select
            {...otherFields}
            className={classNames("course-questions__select", styles["correlate-selecet"])}
            onChange={(event) => {
              onChange.call(this, event);
              handleOnChange();
            }}
            options={selectOptions}
            variant="outlined"
            dropdownClassName={styles["correlate-dropdown"]}
          />
        )}
      />
    </div>
  );
};
