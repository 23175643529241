import { TextMessages } from "app/messages";
import { AuthTypes } from "../authRoutes.congig";
import { AuthData } from "./data.types";
import { WebAppRoutes } from "app/routes";
import { PointerEvent } from "react";
import { adminEmail } from "app/constants";

export const passwordRecovery = (dep: any): AuthData => {
  const { nav } = dep;

  return {
    type: AuthTypes.PASSWORD_RECOVERY,
    toggles: {
      top: {
        title: TextMessages.ACCOUNT_ENTER,
        action: () => {
          nav(WebAppRoutes.AUTH);
        },
      },
    },
    titles: {
      main: TextMessages.PASSWORD_RECOVERY,
    },
    form: {
      actions: {
        buttons: {
          primary: {
            title: TextMessages.SEND_TO_ADMIN,
            action: (event: PointerEvent<HTMLButtonElement>) => {
              event.preventDefault();
              // @ts-ignore
              window.location = `mailto:${adminEmail}` as string;
            },
          },
        },
      },
    },
  };
};
