export const CapybaraAvatarSvg = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="200" height="200" rx="100" fill="#CEEFFF" />
      <path
        d="M119.658 39.8535C125.852 52.0926 148.604 100.916 148.604 100.916C148.604 100.916 165.356 155.785 151.922 164.176C136.527 173.79 69.0652 179.482 48.3178 163.365C36.5064 154.193 47.1086 103.334 47.1086 103.334C47.1086 103.334 69.0652 56.2951 81.0831 42.4488"
        fill="white"
      />
      <path
        d="M119.658 39.8535C125.852 52.0926 148.604 100.916 148.604 100.916C148.604 100.916 165.356 155.785 151.922 164.176C136.527 173.79 69.0652 179.482 48.3178 163.365C36.5064 154.193 47.1086 103.334 47.1086 103.334C47.1086 103.334 69.0652 56.2951 81.0831 42.4488"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
      <path
        d="M79.4747 43.8061C79.4747 43.8061 69.8161 28.7506 73.591 26.7304C77.366 24.7102 83.1021 40.3556 83.1021 40.3556L116.487 37.6718C116.487 37.6718 119.156 25.5655 121.574 26.5534C123.992 27.5414 120.719 41.491 120.719 41.491"
        fill="white"
      />
      <path
        d="M79.4747 43.8061C79.4747 43.8061 69.8161 28.7506 73.591 26.7304C77.366 24.7102 83.1021 40.3556 83.1021 40.3556L116.487 37.6718C116.487 37.6718 119.156 25.5655 121.574 26.5534C123.992 27.5414 120.719 41.491 120.719 41.491"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
      <path
        d="M119.658 39.8535C125.852 52.0926 148.604 100.916 148.604 100.916C148.604 100.916 165.356 155.785 151.922 164.176C136.527 173.79 69.0652 179.482 48.3178 163.365C36.5064 154.193 47.1086 103.334 47.1086 103.334C47.1086 103.334 69.0652 56.2951 81.0831 42.4488"
        fill="white"
      />
      <path
        d="M119.658 39.8535C125.852 52.0926 148.604 100.916 148.604 100.916C148.604 100.916 165.356 155.785 151.922 164.176C136.527 173.79 69.0652 179.482 48.3178 163.365C36.5064 154.193 47.1086 103.334 47.1086 103.334C47.1086 103.334 69.0652 56.2951 81.0831 42.4488"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
      <path
        d="M79.4747 43.8061C79.4747 43.8061 69.8161 28.7506 73.591 26.7304C77.366 24.7102 83.1021 40.3556 83.1021 40.3556L116.487 37.6718C116.487 37.6718 119.156 25.5655 121.574 26.5534C123.992 27.5414 120.719 41.491 120.719 41.491"
        fill="white"
      />
      <path
        d="M79.4747 43.8061C79.4747 43.8061 69.8161 28.7506 73.591 26.7304C77.366 24.7102 83.1021 40.3556 83.1021 40.3556L116.487 37.6718C116.487 37.6718 119.156 25.5655 121.574 26.5534C123.992 27.5414 120.719 41.491 120.719 41.491"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
      <path
        d="M47.1074 103.777C47.1074 103.777 107.964 80.5078 148.588 100.901L47.1074 103.777Z"
        fill="white"
      />
      <path
        d="M47.1074 103.777C47.1074 103.777 107.964 80.5078 148.588 100.901"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
      <path
        d="M98.8511 97.8926L100.267 150.978C100.267 150.978 86.0664 160.843 74.3877 147.955"
        fill="white"
      />
      <path
        d="M98.8511 97.8926L100.267 150.978C100.267 150.978 86.0664 160.843 74.3877 147.955"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
      <path
        d="M100.267 150.981C100.267 150.981 113.11 164.68 125.128 146.955L100.267 150.981Z"
        fill="white"
      />
      <path
        d="M100.267 150.981C100.267 150.981 113.11 164.68 125.128 146.955"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
      <path
        d="M80.8499 106.395C81.4807 103.116 80.4427 100.16 78.5313 99.792C76.62 99.4243 74.5591 101.784 73.9283 105.063C73.2975 108.342 74.3356 111.298 76.2469 111.666C78.1583 112.034 80.2191 109.674 80.8499 106.395Z"
        fill="#191229"
      />
      <path
        d="M121.355 110.735C123.228 110.205 124.01 107.171 123.102 103.958C122.193 100.745 119.938 98.57 118.065 99.0996C116.192 99.6292 115.41 102.663 116.319 105.876C117.228 109.089 119.482 111.265 121.355 110.735Z"
        fill="#191229"
      />
      <path
        d="M69.0498 62.0332C69.0498 62.0332 70.2589 66.5602 73.68 65.6607C77.101 64.7612 77.8973 62.0332 77.8973 62.0332"
        fill="white"
      />
      <path
        d="M69.0498 62.0332C69.0498 62.0332 70.2589 66.5602 73.68 65.6607C77.101 64.7612 77.8973 62.0332 77.8973 62.0332"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
      <path
        d="M119.702 59.0234C119.702 59.0234 120.911 63.5504 124.332 62.6509C127.753 61.7514 128.55 59.0234 128.55 59.0234"
        fill="white"
      />
      <path
        d="M119.702 59.0234C119.702 59.0234 120.911 63.5504 124.332 62.6509C127.753 61.7514 128.55 59.0234 128.55 59.0234"
        stroke="#191229"
        stroke-width="2.29"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
