import { Capacitor } from "@capacitor/core";
import { ModalsContainer } from "components/ModalsContainer";
import { NotificationProvider } from "components/Notification";
import { StrictMode } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./app/store";
import "./assets/styles/index_es.sass";
import { AppTimeTracker } from "./components/AppTimeTracker";
import { AppUrlListener } from "./components/AppUrlListener";
import "./assets/styles/themes/theme-variables-es-studio.scss";
import { InternetAccessTracker } from "components/InternetAccessTracker";
import { UpdateMobileModal } from "components/UpdateMobileModal";

const root = document.getElementById("root");
const rootContainer = createRoot(root);

if (process.env.REACT_APP_THEME === "myAcademy") {
  require("./assets/styles/themes/theme-variables-my-academy.scss");
  require("./assets/styles/index_my-academy.sass");
} else {
  require("./assets/styles/themes/theme-variables-es-studio.scss");
  require("./assets/styles/index_es.sass");
}

rootContainer.render(
  <StrictMode>
    <Provider store={store}>
      <NotificationProvider>
        <BrowserRouter
          basename={Capacitor.isNativePlatform() ? "" : process.env.REACT_APP_BASE_PATH}
        >
          <ModalsContainer>
            {Capacitor.isNativePlatform() && (
              <>
                <AppUrlListener />
                <AppTimeTracker />
              </>
            )}
            <App />
            {Capacitor.isNativePlatform() && <UpdateMobileModal />}
          </ModalsContainer>
        </BrowserRouter>
      </NotificationProvider>
      <InternetAccessTracker />
    </Provider>
  </StrictMode>
);
