import Container from "../../../../../../components/Container/Container";
import { H1 } from "../../../../../../components/Typography/Titles";

import CourseTestContentHeader from "../../../CourseTestContentHeader";
import { Questions, QuestionsProgress } from "..";
import QuizeCounter from "../QuizeCounter";
import QuizeLoadSkeleton from "../QuizeLoadSkeleton";
import CourseQFooter from "../../CourseQFooter";
import { useCourseTestQuestions } from "./useCourseTestQuestions";

const CourseTestQuestions = () => {
  const {
    quizeDetail,
    lesson,
    handleDoneQuest,
    questions,
    countPages,
    lesson_id,
    currentOpenPageInQuiz,
    handleNextQuest,
    handlePrevQuest,
    handleChange,
  } = useCourseTestQuestions();

  return (
    <>
      <Container>
        <CourseTestContentHeader status={""}>
          <H1 className="course-test-top__title">
            {quizeDetail.title}
            <span className="course-test-top__title-addition">
              {quizeDetail.time_to_complete ? (
                <QuizeCounter
                  timeSpent={quizeDetail.time_spent}
                  totalTime={quizeDetail.time_to_complete}
                  lesson={lesson}
                  onCounterEnd={handleDoneQuest}
                />
              ) : null}
            </span>
          </H1>
        </CourseTestContentHeader>
        {!questions.length ? (
          <QuizeLoadSkeleton />
        ) : (
          <>
            {countPages > 1 && <QuestionsProgress />}
            <Questions lessonId={lesson_id ?? ""} handleChange={handleChange} />
          </>
        )}
      </Container>
      <CourseQFooter
        start={0}
        max={countPages}
        current={currentOpenPageInQuiz}
        onNext={handleNextQuest}
        onPrev={handlePrevQuest}
        onDone={handleDoneQuest}
      />
    </>
  );
};

export default CourseTestQuestions;
