import { SVGProps } from "react";
import styles from "./LandingAchievements.module.scss";

export const SquiggleIcon2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={styles["squiggle-2"]}
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="49"
      viewBox="0 0 41 49"
      fill="none"
      {...props}
    >
      <path
        d="M38.4462 46.4992C15 33.9238 30.5831 -0.958442 37.3252 12.2811C44.0674 25.5206 -3 38.9238 2.47944 2.94416"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};
