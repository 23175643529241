import { ClubBadge } from "components/Svg/Icons";
import { useClub } from "pages/OperationHistory/hooks/useClub";
import styles from "./ClubDetailsItem.module.scss";

type ClubDetailsItemProps = {
  clubId: string;
  count: number;
};

export const ClubDetailsItem = ({ clubId, count }: ClubDetailsItemProps) => {
  const { club_icon, name } = useClub(clubId);

  return (
    <div className={styles.item}>
      <div className={styles.item_club}>
        {club_icon ? (
          <img src={club_icon} className={styles["icon"]} alt="badge" loading="lazy" />
        ) : (
          <ClubBadge className={styles["icon"]} />
        )}
        <p>{name}</p>
      </div>
      <strong>{count}</strong>
    </div>
  );
};
