import styles from "./AvatarLoadingOverlay.module.scss";
import animatedLoader from "../../../../assets/img/animated-loader.svg";
import { CloseIcon } from "components/Icons";
import classNames from "classnames";
import { useAppDispatch } from "hooks/redux";
import { setLoadingNewAvatar } from "pages/Profile/redux/profile-slice/profile.slice";
import { useMediaQuery } from "hooks";
import { mediaQuerys } from "app/utils";
import { Capacitor } from "@capacitor/core";

export const AvatarLoadingOverlay = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const isMobile =
    useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`) || Capacitor.isNativePlatform();

  return (
    <div className={styles["loading-overlay"]}>
      <div className={styles["header"]}>
        {isMobile && (
          <>
            <h2 className={styles["title"]}>Выбор аватара</h2>
            <div className={styles["header-controls"]}>
              <button
                className={classNames(styles["close-button"])}
                onClick={() => {
                  dispatch(setLoadingNewAvatar(false));
                }}
              >
                <CloseIcon className={styles["close-icon"]} />
              </button>
            </div>
          </>
        )}
        <div />
      </div>
      <div className={styles["loading-overlay-loader-conteiner"]}>
        <img src={animatedLoader} alt="" />
      </div>
      <div className={styles["loading-overlay-loader-text"]}>Загружаем файл...</div>
    </div>
  );
};
