import { CircleChart } from "components/Charts";
import { formatNumber, normalizeTimestampDate } from "../../../../../app/utils";

const QuizeAttemptsResultInfo = ({
  currentPoints,
  totalPoints,
  rightQuestions,
  totalQuestions,
  percent = 0,
  endTs = 0,
  first = false,
  best = false,
}: {
  currentPoints: number;
  totalPoints: number;
  rightQuestions: number;
  totalQuestions: number;
  percent: number;
  endTs: number | null;
  first: boolean;
  best: boolean;
}) => {
  return (
    <div
      className={
        first
          ? "course-result-info-statistic course-result-info-statistic__current"
          : "course-result-info-statistic course-test-achieve__item"
      }
    >
      <div className="course-result-info__chart">
        {first ? (
          <CircleChart progress={percent} color="rgb(var(--success-color))" />
        ) : (
          <CircleChart progress={percent} color="rgb(var(--base-dark-color))" />
        )}
        <span>{percent?.toFixed()}%</span>
      </div>
      <div className="course-result-info__descr">
        {currentPoints >= 0 ? (
          <p>
            {/* Полученные баллы за попытку / Всего балов за тест */}
            {formatNumber(currentPoints)}/{totalPoints}
          </p>
        ) : (
          rightQuestions >= 0 && (
            <p>
              {/* Количество верных ответов на вопросы из Общее количество вопросов теста */}
              {rightQuestions} из {totalQuestions}
            </p>
          )
        )}

        <div>
          <span>{normalizeTimestampDate(endTs)}</span>
          {percent > 0 && first && best ? (
            <small style={{ color: "#787E81" }}>Лучший результат</small>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default QuizeAttemptsResultInfo;
