import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { useAppSelector } from "hooks/redux";
import { selectTaskMentor } from "pages/Course/components/Task/redux/taskSelectors";
import { CourseLessonTaskMentor } from "pages/Course/redux/interfaces/course.interface";
import styles from "./Mentor.module.scss";

export const Mentor = ({ date }: { date: number }): JSX.Element => {
  const mentor: CourseLessonTaskMentor | undefined = useAppSelector(selectTaskMentor);

  return (
    <>
      <div className={styles["wrapper"]}>
        {mentor && (
          <img
            src={
              mentor && mentor.avatar_id
                ? mentor.avatar_id
                : process.env.REACT_APP_THEME === "myAcademy"
                ? "https://myacademy.lanit.ru/api/public/avatar/lanit.png"
                : "https://uat-lan.front.fss.local/api/public/avatar/es.png"
            }
            alt=""
            className={styles["avatar"]}
          />
        )}
        <div className={styles["info"]}>
          {/* @ts-ignore */}
          {mentor && <div className={styles["info-name"]}>{mentor.username}</div>}
          {date && (
            <div className={styles["info-date"]}>
              {format(date * 1000, "dd MMMM", { locale: ru })} в {new Date(date * 1000).getHours()}:
              {new Date(date * 1000).getMinutes()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
