import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { TextMain } from "../../../../../../components/Typography/Texts";
import { H3 } from "../../../../../../components/Typography/Titles";
import { IQuizeQuestionProp } from "../../redux/Quze/interface/quiz.interface";
import { QuestResultAlert } from "../Question/components/Results/QuestResultAlert";
import classNames from "classnames";
import styles from "./QuestCorrelateResult.module.scss";

export const QuestCorrelateResult = ({
  title,
  right,
  wrong,
  questions = [],
  description,
  currentQuizeResult,
}: IQuizeQuestionProp) => {
  const column1 = questions.filter((item) => item.column === 1);

  const rightClass = (index: number) =>
    currentQuizeResult?.selected?.[index]?.text === currentQuizeResult?.right?.[index]?.text
      ? " course-questions-bubble__item--right"
      : "";
  const wrongClass = (index: number) =>
    currentQuizeResult?.selected?.[index]?.text !== currentQuizeResult?.right?.[index]?.text
      ? " course-questions-bubble__item--misstake"
      : "";

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <div
        className="course-questions-block__descr"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      <div className="course-questions-block-wrapper">
        <div className="course-questions-block-list course-questions-block-list__correlate">
          {column1.map((item, index) => (
            <div key={index} className="test-choose-row" style={{ display: "flex" }}>
              <div
                className="course-questions-block-list__item "
                style={{ minWidth: 270, marginRight: 20 }}
              >
                <div
                  className={
                    "course-questions-bubble__item  course-questions-bubble__item_fluide course-questions-bubble__item--active" +
                    rightClass(index) +
                    wrongClass(index)
                  }
                >
                  {item.text}
                </div>
              </div>
              <TextMain className="test-choose-row-text">
                {currentQuizeResult?.selected?.[index]?.text}
              </TextMain>
            </div>
          ))}
        </div>
      </div>
      {!wrong && (
        <div className="course-questions-block-wrapper">
          <div className="course-questions-block-list course-questions-block-list__correlate">
            <div
              className="test-choose-row"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextMain
                className={classNames(
                  "course-questions-block-wrapper__title",
                  styles["right-answers-wrapper-title"]
                )}
              >
                Правильный ответ:
              </TextMain>
              <div className={styles["right-answers-wrapper"]}>
                <div className={styles["correlate-right-column"]}>
                  {column1.map((item, index) => {
                    return (
                      <div className={styles["right-answer"]}>
                        <CheckCircleOutlineOutlined />
                        <div style={{ marginRight: 10 }}>{item.text}</div>
                        <div className={styles["correlate-dash"]}></div>{" "}
                      </div>
                    );
                  })}
                </div>
                <div className={styles["correlate-right-column"]}>
                  {column1.map((_, index) => {
                    return (
                      <div className={styles["right-answer"]}>
                        {currentQuizeResult?.right?.[index]?.text}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <QuestResultAlert
        right={
          currentQuizeResult?.status &&
          ["Correct", "Partially correct"].includes(currentQuizeResult?.status)
        }
        text={currentQuizeResult?.text || ""}
        isSelect={true}
      />
    </div>
  );
};
