import { avatarsApi } from "../avatars.api";

const resetAvatarApi = avatarsApi.injectEndpoints({
  endpoints: (build) => ({
    resetAvatar: build.mutation<void, void>({
      query: () => {
        return {
          url: "/profile/avatar/reset",
          method: "PUT",
        };
      },
      invalidatesTags: ["updateAvatar"],
    }),
  }),
  overrideExisting: false,
});

export const { useResetAvatarMutation } = resetAvatarApi;
