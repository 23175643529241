import { Menu } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  LogoutSvgIcon,
  ScheduleHistoryIcon,
  SettingsSvgIcon,
  ShopSvgIcon,
  UserSvgIcon,
} from "../../../../components/Svg/Icons";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";

import { mediaQuerys } from "../../../../app/utils";
import { clear } from "../../../../features/auth/authSlice";

import { CircleIconButton } from "components/Buttons/CircleIconButton";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getProfileCoins } from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { WebAppRoutes } from "../../../../app/routes";
import { Skeleton } from "../../../../components/Skeleton";
import { UserRating } from "../../../../components/UserRating";
import { UserWallet } from "../../../../components/UserWallet";
import { KeyboardArrowDown, KeyboardArrowUp } from "../../../../components/Svg/Icons";
import { selectProfileDetails } from "../../../../pages/Profile/redux/profile-slice/selectors/profile.selectors";
import UserAvatar from "../../../components/UserAvatar";
import { getMyRating } from "pages/Rating/redux/rating.slice";
import { selectLevelNameMyPosition } from "pages/Rating/redux/selectors";
import { Period } from "pages/Rating/redux/interfaces";

const UserWidget = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { firstname, lastname, points } = useSelector(selectProfileDetails);
  const shopSettings = useAppSelector(selectShopSettings);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [active, setActive] = useState(false);
  const $widgetRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorEl);
  const lgMin = useMediaQuery(`(min-width:${mediaQuerys.lgMin}px)`);
  const levelName = useAppSelector(selectLevelNameMyPosition);

  const handleClick = () => {
    setAnchorEl($widgetRef.current);
    setActive(!active);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(clear());
    setAnchorEl(null);
    navigate(WebAppRoutes.HOME, { replace: true });
  };

  useEffect(() => {
    dispatch(getProfileCoins());
    dispatch(
      getMyRating({
        my_workplace: false,
        period: Period.all,
        isFetchAllRatings: false,
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className="profile-widget" ref={$widgetRef}>
        <div className="profile-widget__rating-wallet-container">
          <div className="profile-widget__user-rating">
            {levelName && <UserRating levelName={levelName} points={points || 0} />}
          </div>
          {shopSettings?.enable && (
            <div className="profile-widget__user-wallet">
              <UserWallet />
            </div>
          )}
        </div>
        {lgMin && (
          <div className="profile-widget__name">
            <>
              {lastname || firstname ? (
                <>
                  <p>{lastname}</p>
                  <p>{firstname}</p>
                </>
              ) : (
                <>
                  <Skeleton variant="text" width={73} height={50} />
                </>
              )}
            </>
          </div>
        )}
        <div className="profile-widget__avatar">
          <UserAvatar />
        </div>

        <CircleIconButton
          className="profile-widget__toggler"
          color="bordered"
          size="medium"
          onClick={handleClick}
          data-e2e={"user-menu-toogle"}
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </CircleIconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className={"profile-widget-menu"}
      >
        <Link
          to="/profile"
          className={"profile-widget-menu-item"}
          onClick={handleClose}
          data-e2e={"user-menu-profile"}
        >
          <span className={"profile-widget-menu-item__icon"}>
            <UserSvgIcon fontSize="small" />
          </span>
          <span className={"profile-widget-menu-item__text"}>Профиль</span>
        </Link>
        {!!shopSettings?.enable && (
          <Link
            to="/orders"
            className={"profile-widget-menu-item"}
            onClick={handleClose}
            data-e2e={"user-menu-orders"}
          >
            <span className={"profile-widget-menu-item__icon"}>
              <ShopSvgIcon fontSize="small" />
            </span>
            <span className={"profile-widget-menu-item__text"}>Мои заказы</span>
          </Link>
        )}
        <Link
          to="/history"
          className={"profile-widget-menu-item"}
          onClick={handleClose}
          data-e2e={"user-menu-history"}
        >
          <span className={"profile-widget-menu-item__icon"}>
            <ScheduleHistoryIcon fontSize="small" />
          </span>
          <span className={"profile-widget-menu-item__text"}>История</span>
        </Link>
        <Link
          to={WebAppRoutes.SETTINGS}
          className={"profile-widget-menu-item"}
          onClick={handleClose}
          data-e2e={"user-menu-setting"}
        >
          <span className={"profile-widget-menu-item__icon"}>
            <SettingsSvgIcon fontSize="small" />
          </span>
          <span className={"profile-widget-menu-item__text"}>Настройки</span>
        </Link>
        <Link
          to={""}
          className={"profile-widget-menu-item"}
          onClick={handleLogout}
          data-e2e={"user-menu-exit"}
        >
          <span className={"profile-widget-menu-item__icon"}>
            <LogoutSvgIcon fontSize="small" />
          </span>
          <span className={"profile-widget-menu-item__text"}>Выход</span>
        </Link>
      </Menu>
    </>
  );
};

UserWidget.propTypes = {};

export default UserWidget;
