import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useParams } from "react-router-dom";
import Container from "../../../../components/Container/Container";
import {
  getBadges,
  getCourseResults,
  showAsideNav,
  getResultCompetence,
  showBottomNav,
  updateVisite,
} from "../../redux/courseSlice";
import { ICourse } from "../../redux/interfaces";
import {
  selectAchievements,
  selectCompetence,
  selectCourseIsFinish,
} from "../../redux/courseSelectors";
import { H1 } from "../../../../components/Typography/Titles";
import { AchievementsList, ResultBlock } from "./components";
import { CourseResultCompetenciesList } from "./components/CourseResultCompetenciesList";
import { getCoursesCompetences } from "pages/Courses/redux/coursesListSlice";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { Capacitor } from "@capacitor/core";

export const CourseResult = ({ course }: { course: ICourse }) => {
  const dispatch = useAppDispatch();
  const { course_id } = useParams();

  const achievements = useAppSelector(selectAchievements);
  const resultCompetences = useAppSelector(selectCompetence);
  const selectedLmsSettings = useAppSelector(lmsSettings);
  const courseResultIsFinish = useAppSelector(selectCourseIsFinish);

  useEffect(() => {
    dispatch(getCourseResults(course_id));
    dispatch(getBadges({ course_id, only_received: true }));
    course_id !== undefined && dispatch(getResultCompetence({ id: course_id }));
    dispatch(getCoursesCompetences());
    course_id && dispatch(updateVisite(course_id));
  }, [dispatch, course_id]);

  useEffect(() => {
    dispatch(showBottomNav());
    dispatch(showAsideNav());
  }, [dispatch]);

  return (
    <main className="test-result">
      <Container>
        <div className="test-result-block">
          <H1 className="test-result-block__title">Мой результат</H1>

          <ResultBlock course={course} />

          {achievements && achievements.length > 0 && (
            <AchievementsList achievements={achievements} />
          )}

          {resultCompetences &&
            resultCompetences.length > 0 &&
            Capacitor.isNativePlatform() &&
            process.env.REACT_APP_THEME === "base" &&
            courseResultIsFinish &&
            selectedLmsSettings.competences && (
              <CourseResultCompetenciesList competences={resultCompetences} />
            )}
        </div>
      </Container>
    </main>
  );
};
