import { H2 } from "components/Typography/Titles";
import { CourseFormat, MultiCourse } from "../../../Course/redux/interfaces/course.interface";
import { MultiCoursesCard } from "./MultiCoursesCard";
import styles from "./MultiCoursesList.module.scss";

interface MultiCoursesListProps {
  multiCourses: MultiCourse[];
  currentFormat?: CourseFormat;
}

const formatNames = {
  [CourseFormat.course]: "курс",
  [CourseFormat.multicourse]: "траекторию обучения",
  [CourseFormat.task]: "задание",
  [CourseFormat.atb]: "интерактивную книгу",
  [CourseFormat.meeting]: "вебинар",
  [CourseFormat.questionnaire]: "анкету",
  [CourseFormat.page]: "лонгрид",
  [CourseFormat.quiz]: "тест",
  [CourseFormat.trainer]: "SCORM",
};

export const MultiCoursesList = ({ multiCourses = [], currentFormat }: MultiCoursesListProps) => (
  <div className={styles["multi-courses-list"]}>
    <H2 className={styles["title"]}>Входит в траектории обучения</H2>

    <p className={styles["description"]}>
      Вы можете записаться на {currentFormat ? formatNames[currentFormat] : "курс"} отдельно или на
      всю траекторию обучения. Записываясь на траекторию обучения, вы регистрируетесь сразу на все
      курсы, которые в нее входят.
    </p>

    <div className={styles["list"]}>
      {multiCourses.map((course) => (
        <MultiCoursesCard key={course.id} course={course} />
      ))}
    </div>
  </div>
);
