import { generateUid } from "app/utils";
import { SVGProps } from "react";

export const CourseResultBackgroundSvgImage = (props: SVGProps<SVGSVGElement>) => {
  const id = generateUid();

  if (process.env.REACT_APP_THEME === "myAcademy") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={220}
        height={211}
        viewBox="0 0 220 211"
        fill="none"
        {...props}
      >
        <path
          stroke="#0094D9"
          strokeWidth={3}
          d="M112 246c76.5-90.5 118.424-73.5 80.924-55.5-9.086 4.361-174.578 55.281-208.424-66-12-43 13.424-97.703 78.924-112.203 39-8.634 0 11.5-4.5 20.5-12.316 24.633 91-35.5 134-30.5s51 46.5 53 55"
          opacity={0.5}
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 541 642" {...props}>
      <path fill={`url(#${id})`} d="M0 0h541v642H0V0Z" />
      <path
        fill="#F0EEFF"
        d="M204.262-165.131c52.393 145.539 40.706 226.175 68.863 349.156 6.94 30.153 2.194 41.546-26.238 18.569-33.699-27.53-51.676-68.112-98.023-130.18C127.191 43.33 83.672 5.916 68.323 38.502c-17.366 37.895 43.714 98.894 24.238 189.56-29.077 135.694-28.557 62.842 48.787 130.766 19.682 17.228 51.03 49.186 59.587 71.658 17.787 46.709-11.877 86.764-59.04 104.723-67.606 25.24-204.917-13.289-185.553 17.682 12.798 20.354 169.929 114.908 309.048 50.832 83.328-38.291 74.337-92.385 128.762-115.128 60.428-25.534 131.712 111.297 179.362-19.361 16.066-43.958 35.262-13.427 41.401 33.176 5.615 41.252 3.201 44.19 69.942 33.911 52.953-8.056 70.131 38.38 131.732 115.326-1.595 1.112-63.916 45.53-68.577 51.846-33.712 25.45-18.936 21.842-45.773-2.247-72.916-65.574-85.611-76.381-114.151-87.712-23.839-9.59-65.663-27.973-48.701-47.046 2.415-2.937 31.445-26.101 17.363-27.298-26.674-2.456-23.949 12.652-98.928-23.882-94.622-45.704-60.559 43.747-171.218 103.04-120.125 64.917-265.513 11.804-388.148-50.477-31.268-15.844-56.658-37.457-25.824-53.235 39.314-20.016 138.138 4.915 199.127 3.386 73.96-1.928 118.045-50.501 65.945-103.82-47.714-48.43-76.447-43.039-102.237-56.427-39.241-20.879 20.225-62.192 31.742-132.673C84.632 121.091-6.443 78.073-9.614-4.473c66.268-49.958 51.47-39.782 57.976-41.25 42.858-6.734 74.982 25.937 97.018 28.141 25.625 2.351 7.593-71.509-1.867-97.675-2.118-4.238-13.769 6.253 60.749-49.874Z"
        opacity={0.1}
      />
      <defs>
        <linearGradient
          id={id}
          x1={27.948}
          x2={523.399}
          y1={140.437}
          y2={162.428}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#43375D" />
          <stop offset={1} stopColor="#592761" />
        </linearGradient>
      </defs>
    </svg>
  );
};
