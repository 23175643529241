import { useAppSelector } from "../../hooks/redux";
import {
  selectIsLoadingProfileCoins,
  selectProfileCoins,
  selectShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import styles from "./UserWallet.module.scss";
import { Capacitor } from "@capacitor/core";
import { Skeleton } from "components/Skeleton";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { maskitoTransform } from "@maskito/core";
import { maskitoNumberOptionsGenerator } from "@maskito/kit";

const maskitoOptions = maskitoNumberOptionsGenerator({
  thousandSeparator: " ",
});

export const UserWallet = () => {
  const navigate = useNavigate();
  const coins = useAppSelector(selectProfileCoins);
  const isLoadingProfileCoins = useAppSelector(selectIsLoadingProfileCoins);
  const shopSettings = useAppSelector(selectShopSettings);
  const [isRendered, setIsRendered] = useState<boolean>(false);

  const handleClick = () => {
    navigate("/operation-history");
  };

  useEffect(() => {
    if (!isLoadingProfileCoins) {
      setIsRendered(true);
    }
  }, [isLoadingProfileCoins]);

  const transformedValue = maskitoTransform(
    (coins.shop_coins || 0).toLocaleString(),
    maskitoOptions
  );

  return (
    <button className={styles["container"]} onClick={handleClick}>
      <p className={styles["title"]}>Кошелёк</p>

      {!isRendered && (
        <div>
          <Skeleton height={22} width={100} />
        </div>
      )}

      {isRendered && (
        <div className={styles["coins-container"]}>
          <p className={styles["coins"]}>{transformedValue}</p>
          <img
            src={
              `${
                Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE : ""
              }/api` + shopSettings?.coin?.icon?.path
            }
            className={styles["icon"]}
            alt="coin-logo"
          />
        </div>
      )}
    </button>
  );
};
