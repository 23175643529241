import { generateUid } from "app/utils";
import { CourseLessonTaskComment } from "pages/Course/redux/interfaces/course.interface";
import styles from "./MentorTaskComment.module.scss";

interface MentorTaskCommentPops {
  comment: CourseLessonTaskComment;
}

const formatComment = (comment: string) => {
  return comment?.replace(/\n/g, "<br />");
};

export const MentorTaskComment = ({ comment }: MentorTaskCommentPops): JSX.Element => (
  <div
    className={styles["comment"]}
    dangerouslySetInnerHTML={{ __html: formatComment(comment.content) }}
    key={generateUid()}
  ></div>
);
