import TestAlertRight from "../../../../../../../../components/Test/TestAlertRight";
import TestAlertReject from "../../../../../../../../components/Test/TestAlertReject";

export const QuestResultAlert = ({
  right,
  isSelect = false,
  text,
}: {
  right?: boolean;
  isSelect?: boolean;
  text: string;
}) => {
  if (right) {
    return <TestAlertRight text={text} />;
  }
  return <TestAlertReject text={text} />;
};
