import { FC } from "react";

interface AvatarProps {
  src: string;
  type?: "preset" | "image" | string;
  className?: string;

  onError?: () => void;

  width?: number;

  height?: number;
}

export const ImagesAvatar = ({
  src,
  className,
}: {
  src: string;
  className?: string;
  width?: number;
  height?: number;
}) => {
  return <img src={src} alt="" className={className} style={{ objectFit: "cover" }} />;
};

export const Avatar: FC<AvatarProps> = ({ src, type = "preset", className }) => {
  return <ImagesAvatar src={src} className={className} />;
};
