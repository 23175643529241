import { useInViewport } from "ahooks";
import { generateUid } from "app/utils";
import { useAppSelector } from "hooks/redux";
import { selectProfileTransactions } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect, useRef } from "react";
import { OperationHistoryCard } from "../OperationHistoryCard";
import styles from "./OperationHistoryList.module.scss";

interface OperationHistoryListProps {
  getTransactions: (limit: number, offset: number) => void;
}

export const OperationHistoryList = ({
  getTransactions,
}: OperationHistoryListProps): JSX.Element => {
  const { transactions, offset, limit, total_count, has_more_after, isLoading } =
    useAppSelector(selectProfileTransactions);
  const observerTarget = useRef(null);
  const [inViewport] = useInViewport(observerTarget);

  useEffect(() => {
    if (
      !inViewport ||
      !transactions.length ||
      transactions.length >= total_count ||
      !has_more_after
    )
      return;

    getTransactions(limit, offset + limit);
    // eslint-disable-next-line
  }, [inViewport]);

  return (
    <div className={styles["container"]}>
      {transactions.map((transaction) => (
        <OperationHistoryCard key={generateUid()} transaction={transaction} />
      ))}

      {!isLoading && <div ref={observerTarget} style={{ height: 20 }} />}
    </div>
  );
};
