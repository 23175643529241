import { generateUid, mediaQuerys } from "app/utils";
import { FeedbackCard } from "components/FeedbackCard";
import { NextArrow, PrevArrow } from "components/Svg/Icons";
import { H2 } from "components/Typography/Titles";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { selectFeedbacks } from "pages/Course/redux/courseSelectors";
import { getCourseFeedbacks } from "pages/Course/redux/courseSlice";
import { FeedbackMessage } from "pages/Course/redux/interfaces/course.interface";
import { useEffect } from "react";
import Slider from "react-slick";
import { useMediaQuery } from "hooks";
import { Capacitor } from "@capacitor/core";
import classNames from "classnames";
import styles from "./FeedbackList.module.scss";

// Константа количества добавляемых отзывов к отображению
const ADD_TO_SHOW = 3;

export const FeedbackList = ({ id }: { id: string | number }): JSX.Element => {
  const isMobile =
    useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`) || Capacitor.isNativePlatform();

  const dispatch = useAppDispatch();

  const {
    paging: { total },
    data: feedbacks,
  } = useAppSelector(selectFeedbacks);

  useEffect(() => {
    dispatch(getCourseFeedbacks({ id, limit: ADD_TO_SHOW * 2 }));
  }, [dispatch, id]);

  function SampleNextArrow(props: any) {
    const { style, currentSlide, slideCount, ...otherProps } = props;

    return (
      <NextArrow
        {...otherProps}
        style={{
          ...style,
          display: currentSlide + ADD_TO_SHOW < slideCount ? "block" : "none",
          right: "-70",
          width: "50",
          height: "50",
        }}
      />
    );
  }

  function SamplePrevArrow(props: any) {
    const { style, currentSlide, slideCount, ...otherProps } = props;
    return (
      <PrevArrow
        {...otherProps}
        style={{
          ...style,
          display: currentSlide > 0 ? "block" : "none",
          left: "-70",
          width: "50",
          height: "50",
        }}
      />
    );
  }

  const sliderSettings = {
    arrows: true,
    slidesToShow: ADD_TO_SHOW,
    slidesToScroll: ADD_TO_SHOW,
    infinite: false,
    dots: true,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const handleLoad = (currentSlide: number, nextSlide: number) => {
    if (nextSlide % ADD_TO_SHOW === 1 && feedbacks.length < total) {
      dispatch(getCourseFeedbacks({ id, offset: feedbacks.length, limit: ADD_TO_SHOW, add: true }));
    }
  };

  return (
    <>
      {feedbacks.length > 0 && (
        <div className={classNames(styles["container"], { [styles["container-mob"]]: isMobile })}>
          <H2 className={styles["title"]}>Отзывы участников</H2>
          <Slider {...sliderSettings} className="feedback-slider" beforeChange={handleLoad}>
            {feedbacks.map((message: FeedbackMessage) => (
              <FeedbackCard
                feedbackMessage={message}
                className={styles["card"]}
                key={generateUid()}
              />
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};
