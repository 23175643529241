import { SVGProps } from "react";

export const MessageIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={92}
      height={96}
      viewBox="0 0 92 96"
      fill="none"
      {...props}
    >
      <path
        fill="#FFC422"
        d="M30 77.439C17.19 69.623 9 47.356 15.5 32.855c8.645-19.286 29.392-28.343 45-15 15.608 13.344 10.047 37.571 0 50.5-10.047 12.93-17.69 16.9-30.5 9.084Z"
      />
      <path
        stroke="#191229"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M74 40.522c.01 3.52-.813 6.992-2.4 10.134a22.666 22.666 0 0 1-20.267 12.533c-3.52.01-6.991-.813-10.133-2.4L26 65.856l5.067-15.2a22.345 22.345 0 0 1-2.4-10.134A22.666 22.666 0 0 1 41.2 20.256a22.348 22.348 0 0 1 10.133-2.4h1.334A22.613 22.613 0 0 1 74 39.188v1.333Z"
      />
    </svg>
  );
};
