import { InputAdornment } from "@mui/material";
import { CloseIcon } from "components/Icons";
import { WithAnimation } from "components/utils/utils";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import TextField from "../../../../../../../components/Forms/TextField";
import { H3 } from "../../../../../../../components/Typography/Titles";
import { setQuestAnswer } from "../../../redux/Quze/courseQuizeQuestionsSlice";
import {
  answersByPageSelector,
  selectCourseAnswersOfAttempt,
  selectCourseQuizeDetail,
  selectCurrentOpenPageInQuiz,
} from "../../../redux/Quze/courseQuizeSelectors";

interface QuestCalculateProps {
  title: string;
  description: string;
  questionsId: number | string;
  handleChange?: (option: {
    quizeId: string | number;
    questionId: string | number;
    attemptId: number;
    answer: any;
  }) => void;
}

export const QuestCalculate = ({
  title,
  description,
  questionsId,
  handleChange,
}: QuestCalculateProps) => {
  const dispatch = useAppDispatch();
  const answers: any = useSelector(answersByPageSelector);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);
  const answersOfAttempt = useAppSelector(selectCourseAnswersOfAttempt);
  const quizeDetail = useAppSelector(selectCourseQuizeDetail);

  const defaultAnswers = answers[`${currentOpenPageInQuiz}`]?.find(
    (answer: any) => answer.id === questionsId
  );

  const { control, getValues, setValue } = useForm({
    mode: "onChange",
  });

  const handleFormOnChange = () => {
    const values = getValues();
    if (values?.["quest_" + questionsId]) {
      dispatch(
        setQuestAnswer({
          page: currentOpenPageInQuiz,
          answer: { id: questionsId, answer: values?.["quest_" + questionsId] },
        })
      );
      const option = {
        quizeId: quizeDetail.id as string | number,
        questionId: questionsId as string | number,
        attemptId: quizeDetail?.attempt_id ?? 1,
        answer: values?.["quest_" + questionsId] as any,
      };

      handleChange?.(option);
    }
  };

  useEffect(() => {
    const answersForCurrentOpenPage = answers[`${currentOpenPageInQuiz}`];
    const answerForCurrentOpenPage = answersForCurrentOpenPage?.find(
      (answer: any) => answer.id === questionsId
    );

    if (!answerForCurrentOpenPage) {
      const answer: any = answersOfAttempt.find(
        (answer: any) => answer.question_id === questionsId
      );
      setValue("quest_" + questionsId, answer?.answer ?? "");
    }
  }, [answers, answersOfAttempt, currentOpenPageInQuiz, questionsId, setValue]);

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <div
        className="course-questions-block-wrapper"
        style={{ padding: 0, background: "transparent" }}
      >
        <form onChange={handleFormOnChange}>
          <div
            className="course-questions-row-item"
            style={{ flexDirection: "column", alignItems: "unset" }}
          >
            <span
              className="course-questions-row-item__text"
              dangerouslySetInnerHTML={{ __html: description }}
              style={{ marginBottom: "20px" }}
            ></span>{" "}
            <span className="course-questions-row-item__text" style={{ marginBottom: "20px" }}>
              Введите число:
            </span>
            <Controller
              name={"quest_" + questionsId}
              control={control}
              defaultValue={defaultAnswers?.answer ?? ""}
              render={({ field: { onChange, ...otherFields } }) => (
                <TextField
                  mask="9"
                  className="course-questions-shortfield"
                  {...otherFields}
                  /* @ts-ignore */
                  InputProps={{
                    /* @ts-ignore */
                    endAdornment: (
                      <WithAnimation inCondition={!!otherFields.value}>
                        <InputAdornment position="end">
                          <CloseIcon
                            className="course-questions-shortfield__clear"
                            onClick={() => onChange("")}
                          />
                        </InputAdornment>
                      </WithAnimation>
                    ),
                  }}
                  /* @ts-ignore */
                  onChange={(event) => {
                    const val = event.target.value as string;
                    if (val === ".") {
                      onChange("");
                      return;
                    }
                    if (!/^-?[0-9]*[,.]?[0-9]*$/.test(val)) return;
                    onChange(event);
                  }}
                />
              )}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
