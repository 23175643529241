import { SVGProps } from "react";

export const CertificateLineSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="54"
    height="43"
    viewBox="0 0 54 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="svg"
    {...props}
  >
    <path
      d="M2.00014 40.3532C14.6079 16.9244 35.5 37.6735 30.2195 40.3533C16.9706 47.0771 9.49992 -7.50199 52.0001 3.99778"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
);
