import { SVGProps } from "react";

export const TrainingMyAcademyIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={228}
    height={201}
    viewBox="0 0 228 201"
    fill="none"
    {...props}
  >
    <rect
      width={160.008}
      height={160.118}
      fill="#E7F7FF"
      rx={20}
      transform="rotate(15.011 27.153 206.496) skewX(.023)"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M139.99 99.34c1.736-3.432 3.699-4.682 5.18-5.185 4.3-1.462 9.853 1.556 19.731 6.937 1.057.575 3.46 1.944 6.905 2.488 1.673.264 3.077.171 5.081.213 12.899.264 26.31-10.348 25.263-16.895-.513-3.23-4.563-5.869-8.288-6.221-4.89-.462-8.324 3.11-8.806 3.629"
    />
    <path
      fill="#fff"
      d="M166.848 72.212c.725 3.613 1.036 8.113-1.482 10.379-1.481 1.332-3.014 1.052-7.407 1.855-2.802.514-5.895 1.587-8.101 2.447-.684.265-.498 1.276.238 1.28 3.206.022 7.977.037 10.458-.025 5.517-.13 9.935.3 12.966 0l22.046-.83c0-1.804-5.056-3.603-15.147-5.407l-.626-9.7"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M166.848 72.212c.725 3.613 1.036 8.113-1.482 10.379-1.481 1.332-3.014 1.052-7.407 1.855-2.802.514-5.895 1.587-8.101 2.447-.684.265-.498 1.276.238 1.28 3.206.022 7.977.037 10.458-.025 5.517-.13 9.935.3 12.966 0l22.046-.83c0-1.804-5.056-3.603-15.147-5.407l-.626-9.7M81.809 86.126a26.208 26.208 0 0 1 9.246-8.253c.186-.099.383-.197.596-.192.73.015.839 1.063.678 1.773a29.306 29.306 0 0 1-3.237 8.32M71.37 83.357a13.502 13.502 0 0 0-8.982-3.302c-.14 0-.285.01-.404.078-.306.181 0 .922.098 1.177.555 1.43 4.792 5.142 5.9 6.101M91.64 84.633a63.742 63.742 0 0 1 22.678-7.973c1.725-.26 3.75-.348 4.931.938.663.72.907 1.721 1.103 2.68a64.67 64.67 0 0 1 1.264 15.542c-.171 4.313-.968 9-4.19 11.867-3.999 3.561-10.07 2.986-15.364 2.203"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M71.096 83.27a11.4 11.4 0 0 1 4.662-1.556c3.89-.4 6.806 1.379 7.728 1.996M78.462 156.603a49.5 49.5 0 0 0 1.792 7.73c.746 2.343 1.704 4.696 3.403 6.475 1.953 2.047 4.73 3.146 7.522 3.608 4.916.814 10.116-.239 14.333-2.898 2.595-1.638 4.838-3.862 7.635-5.127 4.041-1.825 8.91-1.483 12.681-3.821 3.16-1.96 5.03-5.552 5.739-9.207.71-3.655.404-7.418.099-11.13-.052-.607-.104-1.234-.373-1.778-.725-1.478-2.87-1.815-4.274-.97s-2.129 2.525-2.3 4.163c-.171 1.633.119 3.276.27 4.915.14 1.498.046 3.26-1.161 4.157-1.103.819-2.641.545-3.983.234l-4.154-.965"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M115.981 151.233a35.31 35.31 0 0 1-12.085-9.617c-.71-.886-1.389-1.814-1.834-2.862-.679-1.591-.793-3.364-.891-5.09-.155-2.603-.311-5.205-.461-7.808"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M112.386 148.377c1.17-.669 2.341-1.338 3.506-2.007 1.026-.585 2.109-1.228 2.642-2.286.409-.814.435-1.757.446-2.664.052-3.785.046-7.569.036-11.359 0-.321-.005-.663-.171-.943-.176-.306-.513-.482-.849-.591-1.653-.529-3.523.331-4.58 1.71-1.056 1.379-1.445 3.158-1.585 4.894-.14 1.737-.051 3.484-.31 5.205-.125.835-.353 1.7-.943 2.302-1.14 1.161-3.119.897-4.471 1.799M102.077 125.364c4.968.845 10.008 1.555 15.038 1.213 5.03-.347 10.096-1.83 14.038-4.977 3.936-3.147 6.594-8.118 6.205-13.147-.077-1.036-.331-2.151-1.129-2.82-.912-.762-2.3-.7-3.367-.166-1.067.534-1.88 1.441-2.678 2.328-3.476 3.841-7.361 7.652-12.323 9.119-6.263 1.856-12.93-.358-19.089-2.551M114.566 101.445c1.187 1.384 2.181 1.975 2.974 1.778 2.02-.503 2.771-6.117 2.253-16.843"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M102.264 109.854a9.633 9.633 0 0 0-.751 4.826M124.968 112.819a13.883 13.883 0 0 1 .467-4.987c.134-.482.72-2.157 1.704-3.759 2.75-4.473 8.961-6.428 13.773-4.328.948.414 1.85.974 2.544 1.742 1.181 1.311 1.657 3.11 1.865 4.857.228 1.897.601 4.992-1.249 7.719-2.947 4.355-8.982 3.961-9.267 3.94"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M129.112 101.931a22.525 22.525 0 0 1 8.806 1.037 22.552 22.552 0 0 1 7.289 4.002M138.167 102.948a8.88 8.88 0 0 1 1.974-3.193M22.331 196.619c1.187 1.193 3.051 1.493 4.704 1.172 1.652-.316 3.15-1.161 4.61-1.991 5.91-3.359 11.82-6.713 17.731-10.072-.595-1.53-2.035-2.541-3.398-3.453l-21.316-14.324c-1.025-.689-2.134-1.405-3.367-1.394-1.02.005-1.963.513-2.859 1.005l-15.442 8.502c-2.263 1.25-2.476 4.422-.398 5.957l19.73 14.593.005.005Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M5.139 177.132c6.93 4.666 12.038 10.182 18.404 15.495.327.27.668.555 1.098.633.56.109 1.114-.156 1.622-.405 5.511-2.742 12.147-6.744 17.902-9.015-1.249-.938-19.767-14.308-22.792-14.515-.529-.037-1.036 0-1.036 0-1.984.145-3.409 1.078-4.144 1.555-1.865 1.208-4.937 3.136-8.806 5.702M55.888 185.07c1.186 4.069.813 8.652 2 12.721.175.612.383 1.26.88 1.654.818.643 1.989.384 2.362.311 1.979-.394 13.302-2.509 26.894-4.743a1.475 1.475 0 0 0 1.166-1.918 96.734 96.734 0 0 1-1.85-6.216 100.174 100.174 0 0 1-1.46-6.392 1.468 1.468 0 0 0-1.715-1.161c-9.013 1.679-18.031 3.354-27.045 5.033"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M63.99 185.992c-.027.119-.063.29-.1.503-.113.653-.424 2.727.472 6.159.689 2.638 1.285 2.991 1.554 3.11.642.285 1.3.073 1.554 0 3.383-.995 14.634-1.628 17.778-2.623.305-.098.844-.28.974-.726.077-.259-.01-.534-.093-.788-.98-3.012-1.964-6.018-2.943-9.03-.036-.12-.083-.244-.181-.322-.14-.109-.342-.083-.513-.052-1.533.291-15.628 2.712-18.218 3.5"
    />
    <path
      stroke="#FF8B00"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M71.096 191.616c1.916-2.058 3.833-4.111 5.75-6.169"
    />
    <path
      stroke="#FF8B00"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M70.578 186.433c.932.233 2.243.674 3.626 1.555a12.252 12.252 0 0 1 3.673 3.66M24.217 174.374c-.642 2.913-1.725 7.517-1.813 10.503"
    />
    <path
      stroke="#FF8B00"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M17.814 177.946c3.57 1.737 9.003 1.172 12.36 3.303"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M60.135 190.71c-1.051.026-1.481 1.68-.657 2.328.823.653 2.211.083 2.507-.923.051-.182.077-.384.03-.565-.087-.332-.383-.56-.662-.762-.57-.415-.446-.068-1.135-.202M36.209 190.766c-.213.275-.239.726.041.939a.715.715 0 0 0 .368.134c.953.125 1.854-.902 1.616-1.83-.238-.927-1.528-1.389-2.3-.819-.477.348-.653 1.162-.176 1.509"
    />
    <path
      stroke="#00AEFF"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M147.951 37.048c2.994.078 5.481 2.405 7.045 4.966a19.592 19.592 0 0 1 2.854 10.887M150.179 33.062c4.408.549 8.547 2.98 11.183 6.557 2.637 3.577 3.73 8.254 2.953 12.629M153.758 27.546c4.506.415 8.101 3.857 11.044 7.3 1.875 2.192 3.667 4.499 4.889 7.117 1.223 2.618 2.321 7.512 1.762 10.342M161.927 23.201c9.453 5.003 16.405 17.15 16.887 27.839"
    />
    <path
      stroke="#00AEFF"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M158.006 52.123c.948-8.642 4.268-17.009 9.51-23.94 2.01-2.66 4.403-5.189 7.506-6.413M164.827 51.17c.518-6.44 3.367-12.458 6.688-17.995 2.134-3.566 4.77-7.231 8.723-8.512M171.38 51.403c.932-7.735 5.356-16.807 12.235-20.457M178.782 50.148c.922-3.867 3.041-9.222 6.511-11.156"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M119.674 71.31c.145.772.311 1.58.798 2.197.979 1.24 2.818 1.26 4.398 1.172 32.245-1.84 64.486-3.686 96.731-5.526 1.492-21.737 2.989-43.469 4.481-65.206.031-.435.036-.933-.275-1.239-.321-.311-.901.01-1.274-.238-4.367-2.96-109.443-.39-113.634 4.022 0 0 4.859 44.278 8.77 64.822l.005-.005Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M127.486 65.068c.062.534.134 1.094.461 1.519.689.907 2.072.746 3.185.513 28.465-1.783 53.65-3.266 79.969-4.914.788-17.139 1.58-34.278 2.368-51.416-11.35-3.93-92.992.041-92.018 2.032 0 0 4.154 35.983 6.04 52.266h-.005ZM94.924 87.935c.212-.285 2.844-3.722 5.698-3.11.865.186 2.067.819 2.072 1.555 0 .793-1.399 1.555-2.59 1.555-.42 0-.798-.093-1.14-.238"
    />
    <path
      fill="#191229"
      d="M101.14 87.416c.858 0 1.554-.58 1.554-1.296 0-.716-.696-1.296-1.554-1.296-.858 0-1.554.58-1.554 1.296 0 .716.696 1.296 1.554 1.296ZM115.472 83.659c.562-.975.516-2.055-.104-2.413-.619-.358-1.577.142-2.139 1.117-.562.974-.516 2.054.103 2.412.62.358 1.578-.142 2.14-1.116Z"
    />
    <path
      stroke="#191229"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M110.982 79.122c-.943 3.691-2.02 10.047-.518 17.626a39.406 39.406 0 0 0 3.626 10.368M68.138 87.37a82.108 82.108 0 0 0-.803 14.484l-1.42 7.331 3.627-1.037-.518 7.776 2.346-2.571-.274 6.884-.565 3.059c.021 1.4-.362 2.768-.735 4.116l-3.233 11.602c-1.29 4.629-2.59 9.316-2.724 14.116-.135 4.806 1.005 9.814 4.107 13.484 3.103 3.671 8.413 5.661 12.925 4.013"
    />
  </svg>
);
