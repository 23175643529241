import { WithSwitchAnimation } from "components/utils/utils";
import { useMediaQuery } from "hooks";
import { AuthTypes } from "pages/Authorization/authRoutes.congig";
import { useContext } from "react";
import {
  CapybaraBook,
  CapybaraLaptop,
  CapybaraLike,
  CapybaraPcPassword,
  CatWomanSvg,
  DoorLock,
  HappyCat2Pic,
  HappyCatAuthPick,
  LockPick2,
  SadCatPic2,
  SadCatWomanSvg,
  SleepCatPic,
} from "../../../../components/Svg/Pics";
import UrlContext from "../../../../contexts/urlcontext";

export const AuthorizationIllustration = () => {
  const authContext = useContext(UrlContext);

  const isMiddle = useMediaQuery(`(max-width:1025px)`);

  const isMyAcademyTheme = process.env.REACT_APP_THEME === "myAcademy";

  const config = {
    catWomanContextArr: [AuthTypes.AUTH, AuthTypes.ACCOUNT_EXISTS, AuthTypes.AUTH_AGREEMENT],
    woman1ContextArr: [
      AuthTypes.ACCESS_RECOVERY,
      AuthTypes.ACCESS_RECOVERY_CODE_CHECK,
      AuthTypes.REGISTRATION_CODE_CHECK_ERROR,
      AuthTypes.PASSWORD_RECOVERY,
    ],
    accIsRegistered: [AuthTypes.REGISTERED],
    lock: !isMyAcademyTheme
      ? [AuthTypes.REGISTRATION_EMAIL_BLOCKED]
      : [AuthTypes.REGISTRATION_EMAIL_BLOCKED],
    sadCat: [AuthTypes.BANNED_DOMEN],
    registration: [
      AuthTypes.AUTH_EXIST,
      AuthTypes.REGISTRATION,
      AuthTypes.REGISTRATION_CODE_CHECK,
      AuthTypes.REGISTRATION_SECOND_STEP,
    ],
  };

  return (
    <WithSwitchAnimation animationName="slide-right-to-left" keyword={authContext}>
      {config.catWomanContextArr.includes(authContext) ? (
        !isMyAcademyTheme ? (
          <CatWomanSvg />
        ) : !isMiddle ? (
          <CapybaraBook />
        ) : (
          <CapybaraLaptop width={354} height={229} />
        )
      ) : config.woman1ContextArr.includes(authContext) ? (
        !isMyAcademyTheme ? (
          <SadCatWomanSvg />
        ) : (
          <CapybaraPcPassword />
        )
      ) : config.accIsRegistered.includes(authContext) ? (
        !isMyAcademyTheme ? (
          <HappyCatAuthPick />
        ) : (
          <CapybaraLike />
        )
      ) : config.lock.includes(authContext) ? (
        !isMyAcademyTheme ? (
          <SleepCatPic />
        ) : (
          <DoorLock />
        )
      ) : config.sadCat.includes(authContext) ? (
        isMyAcademyTheme ? (
          <LockPick2 />
        ) : (
          <SadCatPic2 />
        )
      ) : config.registration.includes(authContext) ? (
        !isMyAcademyTheme ? (
          <HappyCat2Pic />
        ) : (
          <CapybaraLaptop />
        )
      ) : null}
    </WithSwitchAnimation>
  );
};
