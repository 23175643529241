import { H3 } from "components/Typography/Titles";
import { CourseFormat } from "pages/Course/redux/interfaces/course.interface";
import { Statuses } from "./components/Statuses";
import { ResultBlock } from "./components/ResultBlock";
import classNames from "classnames";
import { EnrolledStatus } from "pages/Courses/redux/enums";
import styles from "./TrajectoryResultCard.module.scss";

interface TrajectoryResultCardProps {
  name: string;
  result?: number;
  status?: EnrolledStatus;
  format: CourseFormat;
  className?: string;
}

export const TrajectoryResultCard = ({
  name,
  result,
  status,
  format,
  className,
}: TrajectoryResultCardProps): JSX.Element => {
  const courseIsFinished = status === EnrolledStatus.completed;

  return (
    <div className={classNames(styles["container"], className)}>
      <div className={styles["info"]}>
        <Statuses isFinished={courseIsFinished} format={format} />
        <H3 className={styles["title"]}>{name}</H3>
      </div>
      <div>
        <ResultBlock result={result} isFinished={courseIsFinished} />
      </div>
    </div>
  );
};
