import { Badge } from "components/Badge";
import { StarFilledIcon } from "components/Icons";
import styles from "./UserRating.module.scss";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { maskitoTransform } from "@maskito/core";
import { maskitoNumberOptionsGenerator } from "@maskito/kit";

const maskitoOptions = maskitoNumberOptionsGenerator({
  thousandSeparator: " ",
});

interface UserRatingProps {
  levelName: string;
  points: number;
}

export const UserRating = ({ levelName, points }: UserRatingProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/rating");
  };

  const slicedLevelName = levelName.length > 8 ? `${levelName.slice(0, 8)}...` : levelName;

  const transformedValue = maskitoTransform((points || 0).toLocaleString(), maskitoOptions);

  return (
    <button
      className={classNames(styles["container"], {
        [styles["my-academy"]]: process.env.REACT_APP_THEME === "myAcademy",
      })}
      onClick={handleClick}
    >
      <p className={styles["title"]}>{slicedLevelName}</p>
      <div>
        <Badge color="primary">
          <div className={styles["points-container"]}>
            <p className={styles["points"]}>{transformedValue}</p>
            <div className={styles["icon-container"]}>
              <StarFilledIcon className={styles["icon"]} />
            </div>
          </div>
        </Badge>
      </div>
    </button>
  );
};
