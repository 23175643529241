import { useEffect, useState } from "react";
import { CloseSvgIcon } from "../../../../../components/Svg/Icons";
import { BackupIcon } from "components/Icons";
import { TextHelper, TextMain } from "../../../../../components/Typography/Texts";
import { DynamicMessages, FileErrorMessages } from "../../../../../app/messages";
import { useSelector } from "react-redux";

import { useDropzone } from "react-dropzone";
import { selectTaskFileTypes } from "../redux/taskSelectors";

import { IconButton } from "@mui/material";
import { normalizeFileSize } from "../../../../../app/utils";

const TaskFileUpload = ({ fileCount, fileType, fileSize, onChangeFiles }) => {
  const [taskFile, setTaskFiles] = useState([]);
  const TASK_FILE_ALLOWED_TYPE = useSelector(selectTaskFileTypes);

  const renderFileError = (errorCode) => {
    switch (errorCode) {
      case "file-invalid-type":
        return FileErrorMessages.ERROR_BAD_TYPE_FILE;
      case "file-too-large":
        return DynamicMessages.ERROR_BAD_SIZE_FILE(fileSize);
      default:
        return "Неизвестная ошибка";
    }
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    setTaskFiles([...taskFile, ...acceptedFiles]);
    if (acceptedFiles.length) {
      onChangeFiles(taskFile);
    }
  };
  useEffect(() => {
    onChangeFiles(taskFile);
  }, [taskFile, onChangeFiles]);

  const handleRemoveTaskFile = (file) => {
    const newFiles = [...taskFile];
    newFiles.splice(taskFile.indexOf(file), 1);
    setTaskFiles(newFiles);
  };
  const { getRootProps, isDragActive, getInputProps, fileRejections, open } = useDropzone({
    noClick: true,
    onDrop,
    accept: TASK_FILE_ALLOWED_TYPE === "text/x-java-source" ? "" : TASK_FILE_ALLOWED_TYPE,
    maxFiles: fileCount ? fileCount : 0,
    maxSize: fileSize,
  });

  console.log(fileRejections);

  const userFileItems = taskFile.map((file, index) => {
    return (
      <div className="doc-list__item" key={index}>
        <div className="doc-list-item">
          <div className="doc-list-item__pic">
            <strong className="doc-list-item__ext">
              {" "}
              {file.name.match(/\.[0-9a-z]{1,5}$/i)[0].substring(1)}
            </strong>
            <div className="doc-list-item__remove" onClick={() => handleRemoveTaskFile(file)}>
              <IconButton size="small">
                <CloseSvgIcon />
              </IconButton>
            </div>
          </div>
          <div className="doc-list-item__info">
            <p className="doc-list-item__title">{file.name}</p>
            <p className="doc-list-item__size">{normalizeFileSize(file.size)}</p>
          </div>
        </div>
      </div>
    );
  });
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <TextHelper className="task-downloadblock__error Mui-error" key={file.path}>
      {file.path}
      {errors.map((e) => " - " + renderFileError(e.code))}
    </TextHelper>
  ));

  return (
    <div className="wrap-task-downloadblock">
      {taskFile.length < fileCount && (
        <div
          {...getRootProps({
            className: isDragActive
              ? "task-downloadblock task-downloadblock_drag"
              : "task-downloadblock",
          })}
          onClick={open}
        >
          <BackupIcon color="rgb(var(--base-dark-color))" />
          <TextMain className="task-downloadblock__descr">
            <span className="task-downloadblock__descr_underline">Загрузите файл</span>{" "}
            <span className="task-downloadblock__descr_hidden-for-mobile">
              или перетащите его прямо сюда
            </span>{" "}
          </TextMain>
          <label>
            <input {...getInputProps()} />
          </label>
        </div>
      )}
      <div className="task-doc-list__items">{userFileItems}</div>
      {fileRejectionItems}
    </div>
  );
};

export default TaskFileUpload;
