import { parseISO } from "date-fns";
import { ICourse } from "../../../../../Course/redux/interfaces";
import { Button } from "../../../../../../components/Buttons";
import { CourseTypeTile } from "../../../../../../components/CourseTypeTile";
import Alert from "../../../../../../components/Alert/Alert";
import classNames from "classnames";
import styles from "./EnrollButton.module.scss";

interface EnrollButtonPops {
  course?: ICourse;
  handleCourseEnter: (id: number) => void;
  handleCourseEnroll: (id: number) => void;
}

export const EnrollButton = ({
  course,
  handleCourseEnter,
  handleCourseEnroll,
}: EnrollButtonPops): JSX.Element => {
  const isEnrolledButton =
    course &&
    ((course?.start_dt && parseISO(course.start_dt) < new Date()) || !course.start_dt) &&
    ((course?.end_dt && parseISO(course.end_dt) > new Date()) || !course?.end_dt) &&
    !!course?.enrolled_ts &&
    ((!course.time_limit_actual && course.time_limit_actual !== 0) ||
      (!!course.time_limit_actual && course.time_limit_actual > 0));

  const isEnrollButton =
    ((course?.end_dt && parseISO(course.end_dt) > new Date()) || !course?.end_dt) &&
    !course?.enrolled_ts &&
    course?.self_enrollment;

  return (
    <>
      {isEnrollButton || isEnrolledButton ? (
        <div className={styles["enroll-btn-container"]}>
          <Button
            className={styles["btn"]}
            size="big"
            color="primary"
            onClick={() => {
              isEnrollButton ? handleCourseEnroll(course?.id) : handleCourseEnter(course?.id);
            }}
          >
            {isEnrollButton ? "Записаться" : "Перейти"}
          </Button>
          {course?.format && <CourseTypeTile type={course.format} className={styles["tile"]} />}
        </div>
      ) : (
        <>
          {course?.format && (
            <CourseTypeTile
              type={course.format}
              className={classNames(styles["tile"], styles["tile-without-btn"])}
            />
          )}
          {!course?.self_enrollment && !course?.enrolled_ts && (
            <div className="lan-main-content-info">
              <Alert>Чтобы записаться на курс обратитесь к менеджеру организации</Alert>
            </div>
          )}
        </>
      )}
    </>
  );
};
