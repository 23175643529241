import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { selectCourseCoins } from "../../../Course/redux/courseSelectors";
import { selectShopSettings } from "../../../Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { setCourseEnrolled } from "../../../Course/redux/courseSlice";
import { useNavigate } from "react-router-dom";
import { WebAppRoutes } from "../../../../app/routes";
import cardHeadMock from "../../../../assets/img/course-card-head.svg";
import cardHeadMockMyAcademy from "../../../../assets/img/myacademy_course-card-head.svg";

import { ICourse } from "../../../Course/redux/interfaces";
import { isBefore } from "date-fns";

interface UseLandingMainProps {
  course?: ICourse;
}

export const useLandingMain = ({ course }: UseLandingMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shopSettings = useAppSelector(selectShopSettings);
  const coins = useAppSelector(selectCourseCoins);

  const handleCourseEnroll = (id: number) => {
    if (id) {
      // @ts-ignore
      dispatch(setCourseEnrolled(id)).then(() => {
        navigate(WebAppRoutes.COURSE + "/" + id, { replace: true });
      });
    }
  };
  const handleCourseEnter = (id: number) => {
    if (id) {
      navigate(WebAppRoutes.COURSE + "/" + id);
    }
  };

  const courseDurationHours = course && Math.floor(course.duration / 3600);
  const courseDurationMinutes = course && Math.floor((course.duration % 3600) / 60);

  const description = course?.description;

  const startDateIsShow = !(
    !course?.start_dt ||
    !!course.finished_ts ||
    (course?.end_dt && isBefore(new Date(course?.end_dt), new Date()))
  );

  const coinsIsShow =
    shopSettings?.enable &&
    (!!coins?.course?.amount || !!coins?.lessons?.amount || !!coins?.linked_courses?.amount);

  const durationIsShow = course?.duration && course.duration > 0;

  const coursePreviewSrc = course?.preview?.src
    ? `${course.preview.src}`
    : process.env.REACT_APP_THEME !== "myAcademy"
    ? cardHeadMock
    : cardHeadMockMyAcademy;

  return {
    coins,
    shopSettings,
    handleCourseEnter,
    handleCourseEnroll,
    courseDurationHours,
    courseDurationMinutes,
    description,
    startDateIsShow,
    coinsIsShow,
    durationIsShow,
    coursePreviewSrc,
  };
};
