import { Capacitor } from "@capacitor/core";
import { WebAppRoutes } from "app/routes";
import { generateUid } from "app/utils";
import { Link } from "components/Link";
import { DefaultModal } from "components/Modals/DefaultModal";
import { useModal } from "components/ModalsContainer";
import { useNotification } from "components/Notification";
import { Skeleton } from "components/Skeleton";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Guid } from "js-guid";
import { selectProfileDetails } from "pages/Profile/redux/profile-slice/selectors/profile.selectors";
import { ClubData } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import {
  getClub,
  getProfileCoins,
  postOrder,
} from "pages/Profile/redux/rewards-shop-slice/reward-shop.slice";
import {
  selectProfileCoinsDetails,
  selectShopClubs,
  selectShopSettings,
} from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styles from "../../RewardsShop.module.scss";
import { BuyErrorModal } from "../BuyErrorModal/BuyErrorModal";

interface BuyAgreementModalProps {
  id: string;

  price?: number;

  clubId?: string;

  name?: string;

  buttonClassName?: string;

  onBuy?: () => void;

  onClick?: () => void;
}

export const BuyAgreementModal = ({ id, name, price, clubId, onBuy }: BuyAgreementModalProps) => {
  const shopSettings = useAppSelector(selectShopSettings);
  const { openModal, closeModal } = useModal();
  const dispatch = useAppDispatch();
  const { showNotification, closeNotification } = useNotification();
  const navigate = useNavigate();
  const profileDetails = useAppSelector(selectProfileDetails);
  const coins = useAppSelector(selectProfileCoinsDetails);
  const clubs = useAppSelector(selectShopClubs);

  const [clubName, setClubName] = useState<string>();
  const [clubNameLoading, setClubNameLoading] = useState<boolean>();

  const realCoins =
    coins.shop_coins - coins.club_points.reduce((prev, next) => prev + next.total_count, 0);

  const difference = realCoins - (price || 0);

  useEffect(() => {
    if (!price) return;

    if (coins.shop_coins > price && difference < 0) {
      const id = coins.club_points.find((x) => x.total_count >= Math.abs(difference))?.club_id;
      if (!id) return;

      const club = clubs?.find((x) => x.id === id);
      if (club) {
        setClubName(club.name);
        return;
      }

      setClubNameLoading(true);
      dispatch(getClub(id))
        .unwrap()
        .then((result: ClubData) => {
          setClubName(result.name);
        })
        .finally(() => setClubNameLoading(false));
    }
  }, [dispatch, difference, price, coins.shop_coins, coins.club_points, clubs]);

  const onConfirm = () => {
    closeModal();

    const notificationId = generateUid();
    const message = (
      <>
        <p style={{ marginBottom: "5px" }}>
          <b>Ура! Товар куплен </b>
        </p>
        <div style={{ marginBottom: "10px" }}>
          <p>{name}</p>
          <div style={{ display: "flex", gap: "10px" }}>
            <p>Цена заказа: {price || "Бесплатно"}</p>
            {price && (
              <img
                src={
                  `${
                    Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE : ""
                  }/api` + shopSettings?.coin?.icon?.path
                }
                alt={""}
                className={styles["price-icon"]}
              />
            )}
          </div>
        </div>
        <Link
          onClick={() => {
            closeNotification(notificationId);
            navigate?.(WebAppRoutes.ORDERS);
          }}
        >
          Мои заказы
        </Link>
      </>
    );

    const request = {
      request_id: Guid.newGuid().toString(),
      workplace_id: profileDetails.workplace_id?.toString(),
      product_id: id,
      clubId,
    };

    dispatch(postOrder(request))
      .unwrap()
      .then(() => {
        showNotification({
          id: notificationId,
          message,
          type: "success",
          contentPosition: "start",
          closeButtonType: "clear",
        });
        dispatch(getProfileCoins());
        onBuy?.();
      })
      .catch((e: any) => {
        if (e?.status === 400) {
          openModal({
            content: <BuyErrorModal />,
            clickOutsideClose: false,
          });
        }
      });
  };

  return (
    <DefaultModal
      buttonsClassName={styles["modal-buttons"]}
      title="Спасибо за покупку"
      description={
        <>
          <p style={{ display: "inline-flex", gap: 5, flexWrap: "wrap", alignItems: "center" }}>
            С вашего счета будет списано
            <strong>{price || 0}</strong>
            <img
              src={
                `${
                  Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE : ""
                }/api` + shopSettings?.coin?.icon?.path
              }
              alt={""}
              className={styles["price-icon"]}
            />
            {!clubId && !!price && realCoins < price && (
              <>
                <span style={{ display: "inline-flex", gap: 5 }}>
                  , из них
                  <strong>{price - realCoins}</strong>
                  <img
                    src={
                      `${
                        Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE : ""
                      }/api` + shopSettings?.coin?.icon?.path
                    }
                    alt={""}
                    className={styles["price-icon"]}
                  />
                  ,
                </span>
                полученных в клубе
                {!clubNameLoading ? (
                  <span>«{clubName}»</span>
                ) : (
                  <Skeleton width={200} height={25} />
                )}
              </>
            )}
          </p>

          <p>{name}</p>
        </>
      }
      onCancel={closeModal}
      confirmBtnText="Оплатить"
      onConfirm={onConfirm}
    />
  );
};
