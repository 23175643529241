import { getCoursesFormats } from "pages/Courses/redux/coursesListSlice";
import { useEffect } from "react";
import StudyCalendar from "../../components/Study/StudyCalendar";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import styles from "./MyCourses.module.scss";
import { MyCoursesGrid } from "./components";
import { getMyCorsesList, userCourseSelectors } from "./redux/myCoursesSlice";
import { useMediaQuery } from "hooks";

const MyCourses = () => {
  const dispatch = useAppDispatch();
  const myCoursesList = useAppSelector(userCourseSelectors.selectAll);
  const isMobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    dispatch(getMyCorsesList());
    dispatch(getCoursesFormats());
  }, [dispatch]);

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["container"]}>
        {myCoursesList.length > 0 && !isMobile && <StudyCalendar />}
        <div className={styles["courses-container"]}>
          <div className={styles["title-container"]}>
            <h1 className={styles["title"]}>Мое обучение</h1>
          </div>

          <MyCoursesGrid courses={myCoursesList} />
        </div>
      </div>
    </div>
  );
};

export default MyCourses;
