import classNames from "classnames";
import { CourseCardBadge } from "components/CourseCardBadge";
import { format } from "date-fns";
import { FeedbackMessage } from "pages/Course/redux/interfaces/course.interface";
import { mediaQuerys } from "app/utils";
import { useMediaQuery } from "hooks";
import { Capacitor } from "@capacitor/core";
import styles from "./FeedbackCard.module.scss";
import avatarsEs from "../../assets/avatars/education-studio-avatars.json";

interface FeedbackCardProps {
  feedbackMessage: FeedbackMessage;
  className?: string;
}

export const FeedbackCard = ({
  feedbackMessage: { user_name, text, created_ts, rating, user },
  className,
}: FeedbackCardProps): JSX.Element => {
  const isMobile =
    useMediaQuery(`(max-width:${mediaQuerys.xxsMax}px)`) || Capacitor.isNativePlatform();

  const formatedDate = format(new Date(created_ts * 1000), "dd.MM.yyy");

  const formatedText = text?.slice(0, 300);

  return (
    <div
      className={classNames(styles["container"], className, {
        [styles["container-mob"]]: isMobile,
      })}
    >
      <div className={styles["header"]}>
        <img
          src={user?.avatar?.path || avatarsEs.defaultAvatar}
          alt="avatar"
          className={styles["avatar"]}
        />
        <div className={styles["info"]}>
          <span className={styles["name"]} title={user.fullname}>
            {user.fullname}
          </span>
          <div className={styles["rating-date"]}>
            {!!rating && (
              <CourseCardBadge
                type="rating"
                text={rating}
                className={classNames(styles["rating"], {
                  [styles["rating-myacademy"]]: process.env.REACT_APP_THEME === "myAcademy",
                })}
              />
            )}
            <span className={styles["date"]}>{formatedDate}</span>
          </div>
        </div>
      </div>
      <span className={styles["text"]}>{formatedText}</span>
    </div>
  );
};
