import classNames from "classnames";
import { BalanceRow } from "components/BalanceRow";
import { RightArrowShortIcon } from "components/Icons";
import { ClubBadge } from "components/Svg/Icons";
import { format } from "date-fns";
import { useClub } from "pages/OperationHistory/hooks/useClub";
import { ProfileTransaction } from "pages/Profile/redux/rewards-shop-slice/interface/rewards-shop-slice.interface";
import styles from "./OperationHistoryCard.module.scss";

interface OperationHistoryCardProps {
  transaction: ProfileTransaction;
}

export const OperationHistoryCard = ({ transaction }: OperationHistoryCardProps): JSX.Element => {
  const { club_icon, name } = useClub(transaction.club_id);

  return (
    <div className={styles["container"]}>
      <div className={styles["left-content"]}>
        <RightArrowShortIcon
          className={classNames(styles["arrow"], { [styles["arrow-left"]]: transaction.coins < 0 })}
        />
        <div className={styles["date"]}>
          {format(new Date(transaction.operation_date), "dd.MM.yyyy")}
        </div>
        <div className={styles["time"]}>
          {format(new Date(transaction.operation_date), "HH:mm")}
        </div>
      </div>
      <div className={styles["right-content"]}>
        <div className={styles["icon-wrapper"]}>
          {transaction.club_id ? (
            club_icon ? (
              <img className={styles["icon"]} src={club_icon} alt={name} />
            ) : (
              <ClubBadge className={styles["icon"]} />
            )
          ) : null}
        </div>
        <div className={styles["reason"]}>{transaction.event_text}</div>
      </div>
      <BalanceRow
        coins={transaction.coins}
        className={classNames(styles["balance"], { [styles["negative"]]: transaction.coins < 0 })}
      />
    </div>
  );
};
