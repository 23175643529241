import { useClickAway } from "ahooks";
import { Dropdown } from "components/Dropdown";
import { ArrowDownIcon } from "components/Icons";
import { TextFieldWrap } from "components/TextFieldWrap";
import { useEffect, useRef, useState } from "react";
import { getUpperLevelOptions } from "app/utils";
import classNames from "classnames";
import { Option } from "./types";
import { MultiValueContainer, Options } from "./components";
import { FieldError } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import styles from "./Select.module.scss";

interface SelectProps {
  label?: string;
  options?: Option[];
  value?: Option | Option[];
  placeholder?: string;
  onChange?: (value: Option | Option[] | null) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  isMulti?: boolean;
  showCleaner?: boolean;
  isSorting?: boolean;
  errorMessage?: FieldError;
  isSearchable?: boolean;
  dropdownClassName?: string;
  showUpButton?: boolean;
  className?: string;
  variant?: "outlined";
  selectWithParent?: boolean;
}

export const Select = ({
  label = "",
  value,
  options = [],
  placeholder = "",
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  isMulti = false,
  showCleaner = false,
  isSorting = false,
  errorMessage,
  isSearchable = false,
  dropdownClassName,
  showUpButton = true,
  className,
  variant,
  selectWithParent = true,
}: SelectProps) => {
  const textFieldWrapRef = useRef<HTMLDivElement>(null);

  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const searing = isSearchable && isSearching && isOpenDropdown;

  const [selectedOptionOrGroup, setSelectedOptionOrGroup] = useState<Option | Option[] | undefined>(
    value
  );
  const [searchInputValue, setSearchInputValue] = useState<string>("");

  const sortedOptionBySearcing =
    isSearchable && searchInputValue?.length >= 3
      ? options.filter((option) =>
          option.label?.toLowerCase().includes(searchInputValue?.toLowerCase())
        )
      : options;

  const addChildren = (option: Option, result: Option[]) => {
    if (!options.some((obj) => obj.parent_id === option.value)) return;

    const children = options?.filter((obj) => obj.parent_id === option.value);
    for (const el of children) {
      if (!(selectedOptionOrGroup as Option[])?.find((obj) => obj.value === el.value)) {
        result.push(el);
      }
      addChildren(el, result);
    }
  };

  const addParents = (option: Option, result: Option[]) => {
    const parent = options?.find((obj) => obj.value === option.parent_id);
    if (!parent) return;

    // Ищем братьев
    const optionsLength = options?.filter((obj) => obj.parent_id === option.parent_id).length;

    const selectedLength = selectedOptionOrGroup
      ? (selectedOptionOrGroup as Option[])
          ?.filter((obj) => obj.parent_id === option.parent_id)
          .push(option)
      : 1;

    if (selectedLength >= optionsLength) {
      if (!(selectedOptionOrGroup as Option[])?.find((obj) => obj.value === parent.value)) {
        result.push(parent);
      }
      addParents(parent, result);
    }
  };

  const addNodes = (option: Option, result = [option]): Option[] => {
    // Добавляем всех детей конкретного эелемента
    selectWithParent && addChildren(option, result);

    // Проверяем, нужно ли добавлять родителей
    selectWithParent && addParents(option, result);

    return result;
  };

  const removeChildren = (option: Option, result: Option[]) => {
    const children = result?.filter((obj) => obj.parent_id === option.value);

    for (const el of children) {
      const index = result.findIndex((obj) => obj.value === el.value);
      if (index !== -1) {
        result.splice(index, 1);
      }
      removeChildren(el, result);
    }
  };

  const removeParents = (option: Option, result: Option[]) => {
    const parent = result?.find((obj) => obj.value === option.parent_id);
    if (!parent) return;

    // Ищем братьев
    const optionsLength = options?.filter((obj) => obj.parent_id === option.parent_id).length;
    const selectedLength = result.filter((obj) => obj.parent_id === option.parent_id).length;

    if (selectedLength < optionsLength) {
      const index = result.findIndex((obj) => obj.value === parent.value);
      if (index !== -1) {
        result.splice(index, 1);
      }
      removeParents(parent, result);
    }
  };

  const removeNodes = (option: Option, result: Option[]) => {
    const index = result.findIndex((obj) => obj.value === option.value);
    if (index !== -1) result.splice(index, 1);

    // Удаляем всех детей конкретного эелемента
    selectWithParent && removeChildren(option, result);

    // Проверяем, нужно ли удалять родителей
    selectWithParent && removeParents(option, result);
  };

  const handleClickOption = (option: Option) => {
    setIsSearching(false);
    setSearchInputValue("");
    if (isMulti) {
      const copySelectedOptionOrGroup = [...((selectedOptionOrGroup ?? []) as Option[])];
      const indexEqualityOption = copySelectedOptionOrGroup.findIndex(
        ({ value }) => value === option.value
      );

      if (indexEqualityOption !== -1) {
        removeNodes(option, copySelectedOptionOrGroup);
        onChange(copySelectedOptionOrGroup.length > 0 ? copySelectedOptionOrGroup : null);
        return;
      }

      const selectedNodes = addNodes(option);
      const newSelectedOptionOrGroup = [
        ...((copySelectedOptionOrGroup ?? []) as Option[]),
        ...selectedNodes,
      ];
      onChange(newSelectedOptionOrGroup.length > 0 ? newSelectedOptionOrGroup : null);
      return;
    }
    onChange(option);
    setIsOpenDropdown(false);
  };

  const selectAll = (isSelectAll: boolean) => {
    onChange(isSelectAll ? options : null);
  };

  useClickAway(() => {}, [textFieldWrapRef]);

  useEffect(() => {
    setSelectedOptionOrGroup(value);
  }, [value]);

  useEffect(() => {
    setSearchInputValue("");
  }, [isOpenDropdown]);

  return (
    <>
      <TextFieldWrap
        label={label}
        isFocused={isOpenDropdown}
        isFilled={!!selectedOptionOrGroup || variant === "outlined"}
        icon={
          <ArrowDownIcon
            className={classNames(styles["icon"], {
              [styles["icon_rotate"]]: isOpenDropdown,
            })}
          />
        }
        showCleaner={showCleaner || searing}
        onClear={() => {
          onChange(null);
          onBlur();
        }}
        textFieldWrapRef={textFieldWrapRef}
        className={classNames(className, { [styles["field-error"]]: !!errorMessage })}
      >
        <>
          {isMulti && (
            <MultiValueContainer
              isSelectedAll={(selectedOptionOrGroup as Option[])?.length === options.length}
              selectedUpperLevelOptions={
                selectWithParent
                  ? getUpperLevelOptions(selectedOptionOrGroup as Option[])
                  : (selectedOptionOrGroup as Option[])
              }
              onClick={() => {
                setIsSearching((prev) => !prev);
                setIsOpenDropdown((prev) => {
                  if (!prev) {
                    onFocus();
                    return !prev;
                  }
                  !isSearchable && onBlur();

                  return isSearchable || !prev;
                });
              }}
              label={label}
              placeholder={isOpenDropdown || selectedOptionOrGroup ? placeholder : ""}
              isSearching={searing}
              setIsSearching={setIsSearching}
              setSearchInputValue={setSearchInputValue}
            />
          )}
          {!isMulti && (
            <>
              <div
                className={styles["value-container"]}
                onClick={() => {
                  setIsOpenDropdown((prev) => {
                    if (!prev) {
                      onFocus();
                      return !prev;
                    }

                    onBlur();

                    return !prev;
                  });
                }}
              >
                {(selectedOptionOrGroup as Option)?.label}
              </div>
              {!selectedOptionOrGroup && (
                <input
                  type="text"
                  onClick={() => setIsOpenDropdown((prev) => !prev)}
                  readOnly
                  onFocus={onFocus}
                  onBlur={onBlur}
                  className={styles["input"]}
                  placeholder={isOpenDropdown ? placeholder : ""}
                />
              )}
            </>
          )}
        </>
      </TextFieldWrap>
      {isOpenDropdown && (
        <Dropdown
          elementRef={textFieldWrapRef}
          className={classNames(styles["dropdown"], dropdownClassName)}
          onCloseDropDown={() => setIsOpenDropdown(false)}
        >
          <Options
            options={sortedOptionBySearcing}
            selectedOptions={selectedOptionOrGroup}
            selectedUpperLevelOptions={
              isMulti
                ? getUpperLevelOptions(selectedOptionOrGroup as Option[])
                : (selectedOptionOrGroup as Option)
            }
            onClickOption={handleClickOption}
            onSelectAll={selectAll}
            isMulti={isMulti}
            isSorting={isSorting}
            hideSelectAllBySerching={searchInputValue?.length >= 3}
            showUpButton={showUpButton}
            selectWithParent={selectWithParent}
          />
        </Dropdown>
      )}
      {errorMessage && (
        <FormHelperText className={styles["error-text"]}>{errorMessage.message}</FormHelperText>
      )}
    </>
  );
};
