import { H3 } from "../../../../../../../../components/Typography/Titles";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { setQuestAnswer } from "../../../../redux/Quze/courseQuizeQuestionsSlice";
import {
  answersByPageSelector,
  selectCourseAnswersOfAttempt,
  selectCourseQuizeDetail,
  selectCurrentOpenPageInQuiz,
} from "../../../../redux/Quze/courseQuizeSelectors";
import { IQuizeQuestionProp } from "../../../../redux/Quze/interface/quiz.interface";
import { QuestDescription } from "../QuestDescription/QuestDescription";
import { useEffect, useMemo, useState } from "react";
import { QuestCorrelateRow } from "./components/QuestCorrelateRow/QuestCorrelateRow";
import { Option } from "../../../../../../../../components/Select/types";

export const QuestCorrelate = ({
  title,
  questions = [],
  questionsId,
  description,
  handleChange,
}: IQuizeQuestionProp) => {
  const dispatch = useAppDispatch();
  const answersOfAttempt = useAppSelector(selectCourseAnswersOfAttempt);
  const answers: any = useSelector(answersByPageSelector);
  const currentOpenPageInQuiz = useAppSelector(selectCurrentOpenPageInQuiz);
  const quizeDetail = useAppSelector(selectCourseQuizeDetail);

  const [maxWidth, setMaxWidth] = useState<number>(0);

  const defaultAnswers = answers[`${currentOpenPageInQuiz}`]?.find(
    (answer: any) => answer.id === questionsId
  );

  const column1 = questions.filter((item) => item.column === 1);

  const selectOptions = useMemo(
    () =>
      questions
        .filter((item) => item.column === 2)
        .map((item) => {
          return { label: item.text, value: item.code };
        }),
    [questions]
  );

  const { control, getValues, setValue } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    const answer: any = answersOfAttempt.find((answer: any) => answer.question_id === questionsId);
    if (answer && selectOptions) {
      const answers = { ...answer.answer };
      for (const answer in answers) {
        const option = selectOptions.find((option) => +option.value === +answers[answer]);
        setValue(answer, option || "");
      }
    }
  }, [answersOfAttempt, questionsId, selectOptions, setValue]);

  const handleOnChange = () => {
    const values = getValues();
    if (Object.keys(values).length > 0) {
      dispatch(
        setQuestAnswer({
          page: currentOpenPageInQuiz,
          answer: { id: questionsId, answer: values },
        })
      );

      const mappedValue: [string, string][] = [];

      for (const value in values) {
        mappedValue.push([value, values[value].value || ""]);
      }

      const option = {
        quizeId: quizeDetail.id as string | number,
        questionId: questionsId as string | number,
        attemptId: quizeDetail?.attempt_id ?? 1,
        answer: mappedValue,
      };

      handleChange?.(option);
    }
  };

  return (
    <div className="course-questions-block">
      <H3 className="course-questions-block__title">{title}</H3>
      <QuestDescription data={description} />
      <form>
        <div className="course-questions-block-wrapper">
          {column1.map((item) => {
            return (
              <QuestCorrelateRow
                item={item}
                control={control}
                maxWidth={maxWidth}
                handleOnChange={handleOnChange}
                selectOptions={selectOptions as Option[]}
                defaultAnswers={defaultAnswers}
                setMaxWidth={setMaxWidth}
              />
            );
          })}
        </div>
      </form>
    </div>
  );
};
