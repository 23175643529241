import { useEffect } from "react";
import Container from "../../../../components/Container/Container";
import { TaskPic } from "../../../../components/Svg/Pics";
import { Button } from "../../../../components/Buttons/Button";
import { TextMain } from "../../../../components/Typography/Texts";
import { H1 } from "../../../../components/Typography/Titles";
import { generateUid, normalizeTimestampDate, numWord } from "../../../../app/utils";
import CourseTestContentHeader from "../CourseTestContentHeader";
import {
  selectTaskDetail,
  selectTaskMaxmark,
  selectTaskResults,
  selectTaskAttemptsLeft,
  selectTaskAttemptStatus,
  selectTaskComments,
} from "./redux/taskSelectors";
import { setCourseLessonStart, showAsideNav, showBottomNav } from "../../redux/courseSlice";
import { setTaskStarted } from "./redux/taskSlice";
import { LessonDescription } from "../LessonDescription";
import TaskAttemptsInfo from "./components/TaskAttemptsInfo";
import Alert from "components/Alert/Alert";
import { format } from "date-fns";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { CourseLessonTask } from "../../redux/interfaces/course.interface";
import { AchievementsListLesson } from "../AchievementsListLesson";
import { LessonCompetenciesList } from "../LessonCompetenciesList";
import { TaskMyAcademyIllustration } from "components/Illustrations";
import classNames from "classnames";
import { lmsSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { Capacitor } from "@capacitor/core";
import { MentorTaskComments } from "components/MentorTaskComments";

const TaskStart = ({ lesson }: { lesson: CourseLessonTask }) => {
  const dispatch = useAppDispatch();
  const taskAttemptsLeft = useAppSelector(selectTaskAttemptsLeft);
  const taskAttemptsStatus = useAppSelector(selectTaskAttemptStatus);
  const taskResults = useAppSelector(selectTaskResults);
  const taskMaxmark = useAppSelector(selectTaskMaxmark);
  const taskDetail = useAppSelector(selectTaskDetail);
  const selectedLmsSettings = useAppSelector(lmsSettings);
  const taskComments = useAppSelector(selectTaskComments);

  const handleStartTask = () => {
    dispatch(setCourseLessonStart(lesson.id)).then(() => {
      dispatch(setTaskStarted());
    });
  };
  useEffect(() => {
    dispatch(showBottomNav());
    dispatch(showAsideNav());
  }, [dispatch]);

  const alertText =
    taskDetail.start_ts && new Date(+taskDetail.start_ts * 1000) > new Date()
      ? `Дата начала урока: ${normalizeTimestampDate(taskDetail.start_ts)}`
      : taskDetail.end_ts && new Date(+taskDetail.end_ts * 1000) < new Date()
      ? `Прохождение урока не доступно с ${normalizeTimestampDate(taskDetail.end_ts)}`
      : !taskAttemptsStatus
      ? "У вас закончились попытки"
      : "";

  const showTextSendingAnswerToTeacher = taskResults.filter(({ mark }) => !mark).length > 0;

  const isEnableCompetenciesInEsMobile =
    selectedLmsSettings.competences &&
    Capacitor.isNativePlatform() &&
    process.env.REACT_APP_THEME === "base";

  const { mentor_mail, mentor_name } = taskDetail;

  return (
    <>
      <Container className="course-test-block">
        <div className="course-test-content">
          <CourseTestContentHeader fixed={true} status={lesson.status} coins={lesson.coins}>
            <H1 className="course-test-top__title">{lesson.title}</H1>
          </CourseTestContentHeader>

          <AchievementsListLesson />

          <div className="course-test__descr">
            {lesson?.description_show && lesson?.description ? (
              <LessonDescription stringHTML={lesson?.description} />
            ) : null}
            <TextMain className={"course-test__descr"}>
              {lesson?.end_ts ? (
                <>
                  Текст задания доступен до {format(+`${lesson?.end_ts}000`, "dd.MM.yyyy")}
                  <br />
                </>
              ) : null}
              {taskAttemptsStatus ? "Ваш ответ будет проверен преподавателем." : null}
            </TextMain>
          </div>
          {(selectedLmsSettings.competences || isEnableCompetenciesInEsMobile) && (
            <LessonCompetenciesList id={lesson.id} />
          )}
          {taskResults.length ? (
            <>
              <div className="course-test-achieve task-achieves">
                {taskResults
                  // @ts-ignore
                  .map((item) => item)
                  // @ts-ignore
                  .sort((a, b) => (a.mark < b.mark ? 1 : b.mark < a.mark ? -1 : 0))
                  // @ts-ignore
                  .map((result, index) => (
                    <TaskAttemptsInfo
                      key={generateUid()}
                      first={!index}
                      // @ts-ignore
                      date={result.date}
                      // @ts-ignore
                      mark={result.mark}
                      // @ts-ignore
                      maxmark={taskMaxmark}
                    />
                  ))}
              </div>
              {showTextSendingAnswerToTeacher && (
                <TextMain className="course-test-achieve__impetus">
                  Ваш ответ отправлен на проверку преподавателю. После проверки вам будет доступна
                  новая оценка.
                </TextMain>
              )}
              {taskAttemptsStatus && (
                <TextMain className="course-test-achieve__impetus">
                  Вы можете улучшить свой результат, выполнив задание еще раз.
                </TextMain>
              )}
            </>
          ) : null}

          {alertText ? (
            <Alert>{alertText}</Alert>
          ) : (
            <div className="course-test-buttons">
              <Button
                size="big"
                color="primary"
                className="course-test__button"
                onClick={handleStartTask}
              >
                {lesson.attempt_status === "started" ? "Продолжить попытку" : "Начать"}
              </Button>
            </div>
          )}
          {mentor_name && mentor_mail && (
            <p className="course-test__mentor">
              Ваш ответ будет проверен преподавателем: {mentor_name} / {mentor_mail}
            </p>
          )}
          {taskAttemptsLeft > 0 ? (
            <span className="course-test__attempt">
              {numWord(taskAttemptsLeft, ["Осталась", "Осталось", "Осталось"])} {taskAttemptsLeft}{" "}
              {numWord(taskAttemptsLeft, ["попытка", "попытки", "попыток"])}
            </span>
          ) : null}
          {!!taskComments?.length && <MentorTaskComments comments={taskComments} />}
        </div>

        <div
          className={classNames("course-test-img course-simulate-img", {
            "course-test-img_other-translateX": process.env.REACT_APP_THEME !== "myAcademy",
            "course-simulate-img_my-academy": process.env.REACT_APP_THEME === "myAcademy",
          })}
          style={{
            left: process.env.REACT_APP_THEME !== "myAcademy" ? "700px" : undefined,
            width: process.env.REACT_APP_THEME !== "myAcademy" ? "600px" : undefined,
          }}
        >
          {process.env.REACT_APP_THEME === "myAcademy" && <TaskMyAcademyIllustration />}
          {process.env.REACT_APP_THEME !== "myAcademy" && <TaskPic />}
        </div>
      </Container>
    </>
  );
};

export default TaskStart;
