import { CalendarIcon } from "./CalendarIcon";
import { ClockIcon } from "./ClockIcon";
import { HelpIcon } from "./HelpIcon";
import { MediaIcon } from "./MediaIcon";
import { MessageIcon } from "./MessageIcon";
import { MicroIcon } from "./MicroIcon";

export const RulesIcon = ({ type }: { type: string }): JSX.Element => {
  switch (type) {
    case "time_limit":
      return <HelpIcon />;
    case "record":
      return <MediaIcon />;
    case "finish_ts":
      return <ClockIcon />;
    case "zoom":
      return <MicroIcon />;
    case "questions":
      return <CalendarIcon />;
    case "commenting":
      return <MessageIcon />;
    default:
      return <CalendarIcon />;
  }
};
