import classNames from "classnames";
import { useAppSelector } from "hooks/redux";
import { selectShopSettings } from "pages/Profile/redux/rewards-shop-slice/selectors/reward-shop.selectors";
import { WebAppRoutes } from "app/routes";
import { useNavigate } from "react-router";
import styles from "./BalanceRow.module.scss";
import { Capacitor } from "@capacitor/core";

interface BalanceRowProps {
  coins: number;
  icon?: File;
  type?: "profile" | "filled" | "standart";
  className?: string;
  isGray?: boolean;
}

export const BalanceRow = ({
  coins,
  type = "standart",
  className,
  isGray = false,
}: BalanceRowProps): JSX.Element => {
  const navigate = useNavigate();

  const shopSettings = useAppSelector(selectShopSettings);

  const toOperationsHistory = () => {
    if (type === "profile" && !Capacitor.isNativePlatform()) {
      navigate(WebAppRoutes.OPERATION_HISTORY);
    }
  };
  return (
    <div
      onClick={toOperationsHistory}
      className={classNames(
        styles["balance"],
        {
          [styles["balance-filled"]]: type === "filled",
          [styles["balance-profile"]]: type === "profile",
        },
        className
      )}
    >
      <span>{coins?.toLocaleString()}</span>
      {!!shopSettings?.coin?.icon?.path && (
        <img
          src={
            `${Capacitor.isNativePlatform() ? process.env.REACT_APP_BACKEND_API_MOBILE : ""}/api` +
            shopSettings?.coin?.icon?.path
          }
          alt={""}
          className={classNames(styles["coin-icon"], { [styles["gray"]]: isGray })}
        />
      )}
    </div>
  );
};
